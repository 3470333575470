import moment from 'moment';

function checkArray(prop) {
  return Array.isArray(prop) ? prop : [prop];
}

function convertTime(time) {
  const day = moment().format('YYYY-MM-DD');
  // eslint-disable-next-line default-case
  switch (time) {
    case 'day':
      return day;
    case 'week':
      return moment().subtract(7, 'd').format('YYYY-MM-DD');
    case 'month':
      return moment().subtract(1, 'months').format('YYYY-MM-DD');
    case '6months':
      return moment().subtract(6, 'months').format('YYYY-MM-DD');
    case 'year':
      return moment().subtract(1, 'years').format('YYYY-MM-DD');
  }
}

function castFnLondon(array) {
  if (!Array.isArray(array) || !array.length) return [];
  const index = array.indexOf('fnlondon');
  if (index > -1) {
    array[index] = 'financialnews';
  }
  return array;
}

function convertQueryObj(query) {
  const obj = {
    headline: query.headline,
    slug: query.title,
    creator: query.creator,
    modifier: query.modifier,
    create_date: query.created,
    modify_date: query.modified,
    product: castFnLondon(query.targets),
    source: query.source,
    chart_type: query.type,
    status: query.status,
  };
  if (obj.create_date === 'anytime') {
    delete obj.create_date;
  }
  if (obj.modify_date === 'anytime') {
    delete obj.modify_date;
  }
  if (Array.isArray(obj.status)) {
    delete obj.status;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (obj[key] === '' || obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}

function convertSortObj(prop, order) {
  const obj = {};
  switch (prop) {
    case 'DATE_CREATED':
      obj.prop = 'create_date';
      break;
    case 'DATE_MODIFIED':
      obj.prop = 'modify_date';
      break;
    case 'CREATED_BY':
      obj.prop = 'creator';
      break;
    case 'STATUS':
      obj.prop = 'status';
      break;
    case 'MODIFIED_BY':
      obj.prop = 'modifier';
      break;
    case 'TITLE':
      obj.prop = 'slug';
      break;
    default:
      obj.prop = 'modify_date';
      break;
  }
  // eslint-disable-next-line default-case
  switch (order) {
    case 'DESCENDING':
      obj.order = 'desc';
      break;
    case 'ASCENDING':
      obj.order = 'asc';
      break;
  }
  return obj;
}

function pullOutHeadlines(chart) {
  // if new way is used, function always returns an object, for the old way if no sizes were selected the function return false
  const textObj = findUniqueHeadlines(chart.chart, chart.id);
  // converting source into new way, to be able to search by source
  if (!chart.chart.source && chart.chart.data.source) {
    chart.source = chart.chart.data.source.text;
  }
  const textItemInsert = [];
  const defaultHeadline = [];
  const hasDefaultHeadline = !!chart.default_headline;

  if (textObj) {
    for (const key in textObj) {
      textObj[key].map((item) => {
        item.text_type = key;
        if (item.content) {
          textItemInsert.push(item);
          if (item.size === 'default') {
            const obj = {
              content: item.content,
              type: item.text_type,
            };
            defaultHeadline.push(obj);
          }
        }
      });
    }
    if (defaultHeadline.length === 1) {
      chart.default_headline = defaultHeadline[0].content;
    }
    if (defaultHeadline.length > 1) {
      for (let x = 0; x < defaultHeadline.length; x++) {
        if (defaultHeadline[x].type === 'headline') {
          chart.default_headline = defaultHeadline[x].content;
        }
      }
    }
    if (!defaultHeadline.length) {
      chart.default_headline = 'Untitled';
    }
  }

  return { textObj, textItemInsert, hasDefaultHeadline, chart };
}

function findUniqueHeadlines(chart, id) {
  const { sizes } = chart;
  const { title, dek, subdek } = chart.data;

  const titleArr = [];
  const dekArr = [];
  const subdekArr = [];
  const helper = ['title', 'dek', 'subdek'];

  function check(heading, obj) {
    const titleCheck = heading === 'title';
    const dekCheck = heading === 'dek';
    const subdekCheck = heading === 'subdek';
    if (titleCheck) titleArr.push(obj);
    if (dekCheck) dekArr.push(obj);
    if (subdekCheck) subdekArr.push(obj);
  }

  // new way - no sizes
  if (title && dek && subdek) {
    helper.forEach((heading) => {
      const obj = {
        content: chart.data[heading].text || '',
        chart_id: id,
      };
      check(heading, obj);
    });
  } else {
    // old way iterating over the sizes
    for (const key in sizes) {
      helper.forEach((heading) => {
        if (sizes[key].headings[heading]) {
          const obj = {
            content: sizes[key].headings[heading].text || '',
            size: key,
            chart_id: id,
          };
          check(heading, obj);
        }
      });
    }

    function filterHeadings(arr) {
      const contentSet = new Set();
      arr = arr.filter((elem) => {
        if (contentSet.has(elem.content)) return false;
        contentSet.add(elem.content);
      });
    }

    filterHeadings(titleArr);
    filterHeadings(dekArr);
    filterHeadings(subdekArr);
  }
  // adding(new way)/redefining(old way) size to default
  function addSize(arr) {
    if (arr.length) arr[0].size = 'default';
  }

  addSize(titleArr);
  addSize(dekArr);
  addSize(subdekArr);

  const obj = {
    headline: titleArr,
    subheadline: dekArr,
    title: subdekArr,
  };

  // this check required only for old (size) way, because the new way will always have length 1 for each property.
  if (obj.headline.length || obj.subheadline.length || obj.title.length) {
    return obj;
  }
  return false;
}

function updateChartData(chart, data, action) {
  const now = new Date();
  chart.status = data.status || chart.status || 'draft';
  const prevImage = chart.urls ? chart.urls.imageManagerId || chart.urls.gams : '';
  if (data.urls) {
    const urls = {};
    if (chart.urls) {
      Object.keys(chart.urls).forEach((key) => {
        urls[key] = chart.urls[key];
      });
    }
    Object.keys(data.urls).forEach((key) => {
      // chart['url_' + key] = data.urls[key];
      urls[key] = data.urls[key];
    });
    // if (chart.url_gams && chart.url_gamsError) {
    //   chart.url_gamsError = null;
    // }
    if (urls.gams && urls.gamsError) {
      urls.gamsError = null;
    }
    chart.urls = urls;
  }

  chart.chart_type = data.chart.type || chart.type;
  chart.chart = data.chart || chart.chart || {};
  chart.default_headline = data.default_headline || chart.default_headline;
  chart.slug = data.chart && data.chart.title ? data.chart.title : chart.slug || '';
  chart.modifier = data.modifier || chart.modifier;
  chart.product = data.chart.product || chart.product;
  chart.version = data.version || chart.version;
  let source = '';
  if (data.chart.data.source.text.length > 0) {
    source = data.chart.data.source.text;
    const writtenSource = 'Source: ';
    source = source.indexOf(writtenSource) === 0 ? source.replace(writtenSource, '') : source;
  }
  chart.source = source;
  let gamsOrImId = 'draft';
  let imageUrl = '';
  if (data.urls) {
    if (data.urls.imageManagerId) {
      gamsOrImId = data.urls.imageManagerId;
      // if new gamsId was not generated that means imageUrl wasn't produced
      if (gamsOrImId === prevImage) imageUrl = '';
      else imageUrl = data.urls.imFallbackImage;
    } else if (data.urls.gams) {
      gamsOrImId = data.urls.gams;
      // if new gamsId was not generated that means imageUrl wasn't produced
      if (gamsOrImId === prevImage) imageUrl = '';
      else if (data.product === 'marketwatch') imageUrl = data.urls.gamsUrls[5];
      else {
        imageUrl = data.urls.gamsUrls.find((url) => url.includes('4U')) || data.urls.gamsUrls[0];
      }
    }
  }
  chart.gams = gamsOrImId || chart.gams;

  chart.modify_date = now;
  if (data.chart && data.chart.approved) {
    chart.approved_graphics = data.chart.approved.graphics;
    chart.approved_editor = data.chart.approved.editor;
  }
  // if (data.chart && data.chart.sections) {
  //   chart.sections = data.chart.sections;
  // }

  chart.history = data.history || chart.history || [];

  let svgForHistory = '';
  if (data.urls && data.urls.svgForHistory && imageUrl !== '') {
    svgForHistory = `data:image/svg+xml;base64,${data.urls.svgForHistory}`;
  }

  chart.history.push({
    action,
    user: data.modifier,
    date: now,
    imageUrl,
    svgForHistory,
  });

  if (action === 'created') {
    chart.creator = data.modifier;
    chart.id = data.id;
    chart.create_date = now;
    chart.modify_date = now;
  }
  return chart;
}

// When returning multiple charts, returns only necessary data to display a
// table of charts and not all charts data.
function simplifyCharts(charts) {
  const filtered = charts;
  const simplified = filtered.map((chart) => {
    const targets = chart.targets || chart.chart.targets;
    const { createDate, creator, status } = chart;
    return {
      id: chart._id,
      title: chart.chart.title,
      createDate,
      creator,
      modifyDate: chart.modifyDate || createDate,
      modifier: chart.modifier || creator,
      status,
      sections: chart.chart.admin && chart.chart.admin.sections,
      targets: Array.isArray(targets)
        ? targets.sort((a, b) => {
            a = Array.isArray(a) ? a : [a];
            b = Array.isArray(b) ? b : [b];
            return sortStrs(a.join(''), b.join(''), 'asc');
          })
        : [],
      approvedEditor: chart.chart.admin && chart.chart.admin.approved.editor,
      approvedGraphics: chart.chart.admin && chart.chart.admin.approved.graphics,
    };
  });
  return simplified;
}

function sortStrs(a, b, order) {
  if (a < b) {
    return order === 'desc' ? -1 : 1;
  }
  if (a > b) {
    return order === 'desc' ? 1 : -1;
  }
  return 0;
}

function success(res, data) {
  data.status = 'success';
  res.json(data);
}

function fail(res, data) {
  data.status = 'fail';
  res.status(500).json(data);
}

export {
  updateChartData,
  success,
  fail,
  simplifyCharts,
  findUniqueHeadlines,
  pullOutHeadlines,
  convertTime,
  convertQueryObj,
  convertSortObj,
  checkArray,
  castFnLondon,
};
