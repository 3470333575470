import * as format from '../../data/format';
import { hasVideoGroup, hasWSJVideoGroup } from '../utils';
/**
 * Adds options for BAR and COLUMN charts.
 */
export function preRender(data, options) {
  const isHorizontalBar = options.type === 'bar';
  const isColumn = options.type === 'column';
  const bar = data.plotOptions[options.type];
  bar.stacking = options.stacking;

  // solves tight column gaps, but ticks are not reflected, keeping mannual code for print
  // if(!options.print) data.plotOptions[options.type].crisp = false;
  data.plotOptions[options.type].crisp = false;
  // group/point padding for above bar has been moved into index postrender file
  if (options.horizontalBarType !== 'aboveBar' || isColumn) {
    bar.groupPadding = options.series.length > 1 && options.stacking === null ? 0.2 : 0;
    bar.pointPadding = 0.2;
  }

  // this allows bar to start from the beginning of the plotline, tested for print not for digital,
  if (isHorizontalBar && options.horizontalBarType !== 'aboveBar') {
    data.series.forEach((s) => {
      s.pointPlacement = 'on';
      s.borderWidth = 1;
    });
  }
  if (
    isHorizontalBar &&
    (options.horizontalBarType === 'aboveBar' || options.horizontalBarType === 'noScaleAndLeft')
  ) {
    data.plotOptions[options.type].dataLabels = {
      enabled: true,
      align: 'left',
      allowOverlap: true,
      padding: 0,
      x: 2,
      // commented out for print
      // y: options.print ? -2:0,
      formatter() {
        return formatter(options, this);
      },
    };
    data.series[0].data[0].dataLabels = data.series[0].data[0].dataLabels || {};
    data.series[0].data[0].dataLabels.align = options.horizontalBarLabel ? 'right' : 'left';
    data.series[0].data[0].dataLabels.x = options.horizontalBarLabel ? -2 : 2;
  }

  data.plotOptions.series = {
    dataGrouping: {
      enabled: false,
    },
  };

  return data;
}

function formatter(options, context) {
  let value = format.applyFull(context.point.y, false, true, true, true);
  const { prefix, suffix } = options.axes.units;

  const formattedPrefix =
    hasWSJVideoGroup(options) && typeof prefix === 'string' ? prefix.toUpperCase() : prefix;
  const formattedSuffix =
    hasWSJVideoGroup(options) && typeof suffix === 'string' ? suffix.toUpperCase() : suffix;

  if (context.point.index === 0) {
    if (formattedPrefix) {
      value = formattedPrefix + value;
    }
    if (formattedSuffix) {
      value += formattedSuffix;
    }
  }
  return value;
}

export function renderInvalidated(rendered, options, invalidated) {
  if (__ALL__) {
    renderInvalidatedColors(rendered, invalidated);
  }
}
