const WSJ_PROMO_CROPS_REGEX = /appfeed|homepage|soc|ipadFeed2305/i;
const BARRONS_PROMO_CROPS_REGEX =
  /barrons-4col-image|barrons-8col-image|barrons-10col-image|barrons-fullscreen-image/i;
const MARKETWATCH_PROMO_CROPS_REGEX =
  /marketwatch-MG|marketwatch-ZT|marketwatch-ZH|marketwatch-ZG|marketwatch-ZR|marketwatch-ZQ/i;
const MANSION_GLOBAL_PROMO_CROPS_REGEX =
  /mansionglobal-818|mansionglobal-720|mansionglobal-308|mansionglobal-480/i;
const FNLONDON_PROMO_CROPS_REGEX = /fn-small-image|fn-medium-image|fn-large-image/i;

export {
  WSJ_PROMO_CROPS_REGEX,
  BARRONS_PROMO_CROPS_REGEX,
  MARKETWATCH_PROMO_CROPS_REGEX,
  MANSION_GLOBAL_PROMO_CROPS_REGEX,
  FNLONDON_PROMO_CROPS_REGEX,
};
