// @ts-check

/**
 * Generate styles for headlines
 * @type {Object}
 * @property {HeaderType} hedType
 * @property {boolean} boldSubdek
 * @property {number} width
 * @property {ChartlosOptions} options
 * @return {outerTextStyle}
 */
const getHeadlinesStyles = ({ hedType, boldSubdek, width, options, headline }) => {
  const {
    hedLineHeight,
    dekLineHeight,
    hedFontSize,
    dekFontSize,
    hedLetterSpacing,
    dekLetterSpacing,
  } = options.styles;
  const isHed = hedType === 'headline';

  const newlineHeight = isHed ? hedLineHeight : dekLineHeight;
  const fontType = options.headings.chartFont;

  const [fontFamily, fontWeight] = getFont({
    fontType,
    styles: options.styles,
    section: 'hed',
    isHed,
    boldSubdek,
  });

  const hedColor = getHedColor(options, isHed, headline);
  return {
    color: hedColor,
    fontFamily,
    lineHeight: newlineHeight,
    width,
    fontSize: isHed ? `${hedFontSize}px` : `${dekFontSize}px`,
    fontWeight,
    letterSpacing: isHed ? hedLetterSpacing : dekLetterSpacing,
  };
};

/**
 * Generate styles for notes
 * @param {FootnoteType} section
 * @param {ChartlosOptions} options
 * @return {outerTextStyle}
 */
const getNotesStyles = (section, options, width) => {
  const { styles, headings, product } = options;
  const {
    color,
    lightColor,

    noteLineHeight: lineHeight,
    noteFontSize: fontSize,
  } = styles;

  const isDate = section === 'date';
  const fontType = headings.chartFont;

  const [fontFamily, fontWeight] = getFont({ fontType, styles, section: 'note', isDate });

  return {
    color: isDate && product === 'wsj' ? color : lightColor,
    fontFamily,
    lineHeight,
    fontSize: `${fontSize}px`,
    fontWeight,
    width,
  };
};

function getFont({ fontType, styles, section, isDate, isHed, boldSubdek }) {
  const isHedSection = section === 'hed';
  switch (fontType) {
    case 'ja':
      return [
        styles.jaFontFamily,
        isHedSection && (isHed || boldSubdek) ? styles.cjkBoldFontWeight : styles.normalFontWeight,
      ];
    case 'ch':
      return [
        styles.chFontFamily,
        isHedSection && (isHed || boldSubdek) ? styles.cjkBoldFontWeight : styles.normalFontWeight,
      ];
    default:
      return isHedSection
        ? [
            styles.hedFontFamily,
            isHed || boldSubdek ? styles.boldFontWeight : styles.normalFontWeight,
          ]
        : [isDate ? styles.labelFontFamily : styles.noteFontFamily, styles.noteFontWeight];
  }
}

/**
 * Get color for headlines
 * @param {ChartlosOptions} options
 * @param {boolean} isHed
 * @param {string} headline
 * @returns
 */
function getHedColor(options, isHed, headline) {
  const isBarronsTitle = headline === 'subdek' && options.product === 'barrons';
  if (isBarronsTitle) return options.styles.lightColor;
  return isHed ? options.styles.specialHedColor : options.styles.color;
}

export { getHeadlinesStyles, getNotesStyles };
