import * as modules from './constants';
import * as wrappers from './utils';

/**
 * Extends Highcharts with custom functionality.
 * core Highcharts expected
 * Extension list can be found in {@link modules.EXTENSIONS}
 * @see https://www.highcharts.com/docs/extending-highcharts/extending-highcharts
 * @param {Highcharts} - Highcharts
 * @return {Void}
 */
const addExtensions = (H) => {
  Object.keys(modules.EXTENSIONS).forEach((extension) => {
    const e = modules.EXTENSIONS[extension];
    wrappers.wrapHighcharts(H, (H) => wrappers.cbWrapper(H, e.method, e.funcToWrap, e.cb));
  });
};

/**
 * Wraps into highcharts modules.
 * Module list can be found in {@link modules.MODULES}
 * @param {Highcharts} - Highcharts
 * @return {Void}
 */
const applyModules = (H) => {
  wrappers.wrapHighcharts(H, (H) => modules.MODULES.forEach((m) => m(H)));
};

/**
 * Replaces current highcharts methods with new ones
 * methods list can be found in {@link modules.REPLACE_METHODS}
 * @param {Highcharts} - Highcharts
 * @return {Void}
 */
const replaceMethods = (H1) => {
  Object.keys(modules.REPLACE_METHODS).forEach((method) => {
    const m = modules.REPLACE_METHODS[method];
    wrappers.wrapHighcharts(H1, m);
  });
};

export { addExtensions, applyModules, replaceMethods };
