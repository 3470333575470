import { CATEGORY, SERIES, VALUE, POINT, SYMBOL } from '../../data/constants';
import * as format from '../../data/format';
import { findEls, setDy } from '../utils';

const compiledCategory = (options) => {
  if (!options.longCategories) return 'n/a';
  return options.categoriesAreDates && options.dates[0].value > lastItem(options.dates).value
    ? options.longCategories[0]
    : lastItem(options.longCategories);
};

const compiledPoint = (data, options) =>
  lastItem(data) ? format.applyFull(lastItem(data), options.tooltips, true, true) : 'null';

const lastItem = (arr) => (typeof arr === 'string' ? arr : arr[arr.length - 1]);

// TODO: find out why it's broken
export const compileText = (series, item, options) => {
  const val = options.type === 'scatter' ? series.name : lastItem(item.yData);
  return (series.markdown || '')
    .replace(
      CATEGORY,
      options.liveMarketData || options.type === 'scatter'
        ? 'n/a'
        : compiledCategory(options) || 'n/a'
    )
    .replace(SERIES, series.name || 'n/a')
    .replace(SYMBOL, item.userOptions.symbol || 'n/a')
    .replace(VALUE, options.liveMarketData ? 'n/a' : val)
    .replace(POINT, options.liveMarketData ? 'n/a' : compiledPoint(item.yData, options));
};

/**
 * Legend is always disabled for twitter video charts
 * In all other cases determined by options.legend.enabled property
 * @param {ChartlosOptions} options - chartlos options
 * @returns {boolean} whether legend is enabled
 */
export const isLegendEnabled = (options) => {
  if (options.id === 'twitterVideo') return false;
  return options.legend.enabled;
};

/**
 * Chartlos support top and right legend positions
 * however user may specify 'default' position which translates to:
 * for column and bar to 'top'
 * for other types -
 * based on chart width.
 * if width < 359px (4u) 'top' position is set,
 * otherwise - right.
 * For video crop 'top' position is only the option.
 * Twitter video crop does not support legend at all.
 * @param {ChartlosOptions} options - chartlos options
 * @returns {string} top | right
 */
export const getLegendLayout = (options) => {
  if (options.legend.layout !== 'default') return options.legend.layout;
  return options.forceLegendTop ? 'top' : 'right';
};

// print utils
function getPrintLegendMargin(options) {
  if (options.type === 'bar' && options.horizontalBarType !== 'aboveBar') return 12;
  return 18;
}

function setPrintLegendLineHeight(chart) {
  const PRINT_LEGEND_DY = 9;
  const tspans = findEls(chart.legend?.group, 'tspan');
  setDy(tspans, PRINT_LEGEND_DY);
}

function alignLegendWithColorChip(options, chart) {
  const labels = findEls(chart.legend?.contentGroup, 'text');

  const colorChipHidden = isHiddenColorChip(options);

  if (colorChipHidden) {
    [...chart.series].forEach((series) => {
      series?.legendSymbol.hide();
      series?.legendLine.hide();
    });
  } else {
    [...labels].forEach((label) => {
      const legendLabelY = +label.getAttribute('y');
      label.setAttribute('y', legendLabelY + 1);
    });
  }

  // if (options.type === 'line' && options.legend.layout === 'right') {
  //   const groupTransform = group.element.getAttribute('transform');
  //   const transformX = groupTransform.split(',')[0].slice(10);
  //   const transformY = +groupTransform.split(',')[1].slice(0, -1);
  //   group.element.setAttribute('transform', `translate(${transformX},${transformY - 4})`);
  // }
}

function removeWhiteStackingBorder(options, chart) {
  if (options.stacking && (options.type === 'bar' || options.type === 'column')) {
    const allLegendRects = findEls(chart.legend.group, 'rect');
    const legendChips = [...allLegendRects].filter(
      (rect) => !rect.getAttribute('class').includes('highcharts-legend-box')
    );
    [...legendChips].forEach((chip) => {
      const { width, height } = chip.getBoundingClientRect();
      const dashArray = `0, ${2 * (height + width)}`;
      chip.setAttribute('stroke-dasharray', dashArray);
    });
  }
}

function isHiddenColorChip(options) {
  const { type, legend } = options;
  const { layout, showColorChips } = legend;
  return layout === 'right' && (type === 'line' || type === 'area') && !showColorChips;
}
/**
 * Removes old labels prior update,
 * as update function creates new labels
 * @param {string} selector
 * @param {Highcharts.Chart} chart
 */
function removeLabels(selector, chart) {
  chart.renderer.box.querySelectorAll(selector).forEach((el) => el.remove());
}

export {
  getPrintLegendMargin,
  setPrintLegendLineHeight,
  alignLegendWithColorChip,
  removeWhiteStackingBorder,
  removeLabels,
};
