// Utility to transform '002' to '2'.
export function stripZeroes(value) {
  value += '';
  while (value[0] === '0') {
    value = value.substr(1);
  }
  return value;
}

// Utility to transform '2' to '002'.
export function addZeroes(value, len) {
  value += '';
  while (value.length > len) {
    if (value.charAt(0) === '0') {
      value = value.substr(1);
    } else {
      break;
    }
  }
  while (value.length < len) {
    value = `0${value}`;
  }
  return value;
}