import { PRINT_MULTIPLIER } from '../constants/targets-wsj';
import WSJ_PRINT_GRID from '../constants/wsj-print-grid';
import {
  WSJ_PROMO_CROPS_REGEX,
  BARRONS_PROMO_CROPS_REGEX,
  MARKETWATCH_PROMO_CROPS_REGEX,
  MANSION_GLOBAL_PROMO_CROPS_REGEX,
  FNLONDON_PROMO_CROPS_REGEX,
} from '../constants/promoCrops';

const hasCrops = (crops, id) => crops.includes(id);
const regexTest = (regex, id) => regex.test(id);

// TODO: copy over from src/chart-print/inner
/**
 * checks whether crop is promo
 * @param {string} id - crop size
 * @returns {boolean}
 */
const hasPromo = (id) =>
  regexTest(WSJ_PROMO_CROPS_REGEX, id) ||
  regexTest(BARRONS_PROMO_CROPS_REGEX, id) ||
  regexTest(MARKETWATCH_PROMO_CROPS_REGEX, id) ||
  regexTest(MANSION_GLOBAL_PROMO_CROPS_REGEX, id) ||
  regexTest(FNLONDON_PROMO_CROPS_REGEX, id);

const hasVideo = (id) => hasCrops(['video', 'twitterVideo', 'verticalVideo'], id);

const hasPrint = (id) => /print/.test(id);
const hasWsjPrint = (id) => id === 'print-wsj';


const getViewMultiplier = (id) => {
  switch (id) {
    case 'print-wsj':
      return PRINT_MULTIPLIER;
    default:
      return 1;
  }
};

const getPrintDimensions = (printSize, manualHeight, printMultiplier) => {
  const { width, height, lines } = printSize;
  const multipliedWidth = width * printMultiplier;
  const multipliedHeight = getMulipliedDimension(
    manualHeight ? height : lines * WSJ_PRINT_GRID.line,
    printMultiplier
  );
  return [multipliedWidth, multipliedHeight];
};

const getMulipliedDimension = (dimension, multiplier) => dimension * multiplier;

export {
  hasPromo,
  hasVideo,
  hasWsjPrint,
  hasPrint,
  getViewMultiplier,
  getPrintDimensions,
  getMulipliedDimension,
};
