export const videoWidth = 1920;
export const videoHeight = 1080;
export const videoMultiplier = 2 / 3;

export const twitterVideoWidth = 1080;
export const twitterVideoHeight = 540;

export const PRINT_MULTIPLIER = 1.5;

const WSJ_TARGETS = {
  title: 'The Wall Street Journal',
  value: 'wsj',
  gams: true,
  defaultGroups: ['Article', 'Article (wide)', 'Preview', 'Promo'],
  sizes: [
    {
      id: 'print-wsj',
      label: 'Print',
      columns: 1,
      width: 114,
      lines: 20,
      height: 192,
      minColumns: 1,
      maxColumns: 6,
      minWidth: 114,
      maxWidth: 732,
      minLines: 10,
      maxLines: 312,
      minHeight: 96,
      maxHeight: 2995.2,
      interactive: false,
      scale: 1,
      target: 'wsj',
      publish: 'print',
      group: 'Print',
    },
    {
      id: '4u',
      label: '4U',
      width: 300,
      height: 400,
      minWidth: 0,
      minHeight: 200,
      maxHeight: 3000,
      interactive: true,
      scale: 2,
      target: 'wsj',
      publish: 'inset',
      group: 'Article',
    },
    {
      id: '8u',
      label: '8U', // header/inline/offset',
      width: 620,
      height: 500,
      minWidth: 421,
      minHeight: 200,
      maxHeight: 3000,
      interactive: true,
      scale: 1,
      target: 'wsj',
      publish: 'inset',
      group: 'Article',
    },
    {
      id: '12u',
      label: '12U', // bleed',
      width: 940,
      height: 500,
      minWidth: 621,
      minHeight: 200,
      maxHeight: 3000,
      interactive: true,
      scale: 1,
      target: 'wsj',
      publish: 'inset',
      group: 'Article (wide)',
    },
    {
      id: '16u',
      label: '16U', // bleed',
      width: 1260,
      height: 500,
      minWidth: 941,
      minHeight: 200,
      maxHeight: 3000,
      interactive: true,
      scale: 1,
      target: 'wsj',
      publish: 'inset',
      group: 'Article (wide)',
    },
    {
      id: 'appfeed',
      label: 'Appfeed',
      width: 300,
      height: 300,
      interactive: true,
      scale: [480 / 300, 1280 / 300],
      // scale: 4.266,
      // scale: 4,
      target: 'wsj',
      publish: ['image', 'html'],
      group: 'Promo',
    },
    {
      id: 'homepage',
      label: 'Homepage',
      width: 450,
      height: 300,
      minWidth: 380,
      minHeight: 253.33,
      maxWidth: 1331,
      maxHeight: 887.33,
      interactive: true,
      scale: [380 / 450, 540 / 450, 1152 / 450, 1331 / 450],
      // scale: [0.844, 1.2, 2.56, 2.958],
      target: 'wsj',
      publish: ['image', 'html'],
      group: 'Promo',
    },
    {
      id: 'baby_homepage',
      label: 'Baby homepage',
      width: 287,
      height: 191,
      interactive: true,
      scale: [1, 220 / 287],
      // scale: 0.733,
      target: 'wsj',
      publish: ['image', 'html'],
      group: 'Promo',
    },
    {
      id: 'soc',
      label: 'Social',
      width: 720,
      height: 360,
      interactive: false,
      scale: 1280 / 720,
      // scale: 1.777,
      target: 'wsj',
      publish: 'image',
      group: 'Promo',
    },
    {
      id: 'ipadFeed2305',
      label: 'Ipadfeed2305',
      minWidth: 380,
      width: 553,
      height: 369,
      scale: 4.168,
      interactive: false,
      target: 'wsj',
      publish: 'image',
      group: 'Promo',
    },
    {
      id: 'video',
      label: 'video',
      width: videoWidth,
      height: videoHeight,
      interactive: true,
      scale: 1,
      target: 'wsj',
      publish: 'image',
      group: 'Video',
    },
    {
      id: 'verticalVideo',
      label: 'vertical video',
      width: videoHeight,
      height: videoWidth,
      interactive: true,
      scale: 1,
      target: 'wsj',
      publish: 'image',
      group: 'Video',
    },
  ],
};

export const FALLBACK_SIZE = '4u';

// Fonts URL to embed to the svg chart sent to Cloudinary
export const STYLES =
  '\n@import url("https://asset.wsj.net/dynamic-insets/charts/wsj-digital-svg.css");\n@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;700&display=swap");\n@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap");\n@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..900&display=swap");';

export default WSJ_TARGETS;
