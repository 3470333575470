import Annotations from 'highcharts/modules/annotations';
import Exporting from 'highcharts/modules/exporting';
import Stock from 'highcharts/modules/stock';
// import Accessibility from 'highcharts/modules/accessibility';

const EXTENSIONS = {
  setBarXAxisLineHeight: {
    method: 'Tick',
    funcToWrap: 'addLabel',
    cb: function (p) {
      p.call(this);
      const { type, className } = this.axis?.chart?.userOptions?.chart;
      if (type === 'bar' || /video/i.test(className)) {
        const label = this.label;
        const labelOptions = this.axis.options.labels;
        if (label) {
          label.css({
            lineHeight: labelOptions.style.lineHeight,
          });
        }
      }
    },
  },
  hideLegendColorChips: {
    method: 'Series',
    funcToWrap: 'drawGraph',
    cb: function (p) {
      p.call(this);
      if (this.legendLine && !this.chart?.userOptions?.legend?.showColorChips) {
        this.legendLine.hide();
      }
    },
  },
  setPlotBandLabelLineHeight: {
    method: 'PlotLineOrBand',
    funcToWrap: 'render',
    cb: function (p) {
      p.apply(this, Array.prototype.slice.call(arguments));
      const { label } = this;
      if (label?.alignOptions?.style) {
        label.css({
          lineHeight: label.alignOptions.style.lineHeight,
        });
      }
    },
  },
};

const REPLACE_METHODS = {
  /*
   * temporary fix for clip path annotation issue
   * PR in progress https://github.com/highcharts/highcharts/pull/13168
   */
  annotations: (H) => (H.Annotation.prototype.addClipPaths = function () {}),
  draggableAnnotation: (H) => (H.Annotation.prototype.defaultOptions.draggable = ''),
  // scatter charts - makes legend symbol a rectangle instead of being path
  scatterLegendSymbol: (H) =>
    (H.seriesTypes.scatter.prototype.drawLegendSymbol =
      H.seriesTypes.column.prototype.drawLegendSymbol),
};

const MODULES = [Annotations, Exporting, Stock /*Accessibility*/];

export { EXTENSIONS, MODULES, REPLACE_METHODS };
