/* eslint-disable no-param-reassign */
import merge from 'lodash.merge';
import highchartsVersion from './highchartsVersion';
import filterOptionsForSize from './filterOptionsForSize';
import processData from './processData';
import processCategories from './processCategories';
import processSeries from './processSeries';
import { getStyles } from '../style/index';
import { hasWsjPrint } from '../inner/utils';

export default function processOptions(options, Highcharts) {
  removeOldProperties(options);
  options = copyOptions(options, Highcharts);
  options = filterOptionsForSize(options);
  options = processData(options);
  options = processCategories(options);
  options.styles = getStyles(options.product, options.id);
  options = processSeries(options);
  // eliminate special wsj print case
  if (!hasWsjPrint(options.size)) options.el = containerElement(options.el);
  // new added propeerties are handled here
  addNewProperties(options);
  return options;
}

function removeOldProperties(options) {
  // update series order for charts created before 'legend items in order' bugfix
  if (
    options.legend.itemsInOrder &&
    options.series.length > 1 &&
    options.series.initialIndex === undefined
  ) {
    options.series = sortSeries(options.series);
  }
}

function copyOptions(options, Highcharts) {
  return merge(
    {},
    {
      HIGHCHARTS_VERSION: highchartsVersion(Highcharts),
      id: options.el.getAttribute('id') || options.size,
    },
    options
  );
}

// Prepares the container DOM element.
function containerElement(el) {
  el = typeof el === 'string' ? document.querySelector(el) : el;
  if (!el.querySelector('div.dj-chart-container')) {
    const divA = document.createElement('div');
    const divB = document.createElement('div');
    divA.className = 'dj-chart-container';
    divB.className = 'dj-chart-inner';
    el.appendChild(divA);
    el.appendChild(divB);
  }
  return el;
}

function addNewProperties(options) {
  // promo fields
  options.headings.sourcePromo = options.headings.sourcePromo || {
    text: options.headings.source.text,
    enabled: true,
  };
  options.headings.notePromo = options.headings.notePromo || {
    text: options.headings.note.text,
    enabled: true,
  };
  options.headings.subdekPromo = options.headings.subdekPromo || {
    text: options.headings.subdek.text,
    enabled: true,
  };
  // plot lines
  options.plotLines = options.plotLines || [
    {
      plotLineValue: '',
      plotLineDashStyle: 'solid',
      plotLineLabel: '',
      plotLineLabelPosition: 'top right',
      plotLineColor: '',
    },
  ];
  // allowDecimals yAxis
  options.allowDecimals = options.allowDecimals === undefined ? true : options.allowDecimals;
}

function sortSeries(series) {
  return series
    .map((s, i) => {
      s.initialIndex = i;
      return s;
    })
    .sort((a, b) => b.data[b.data.length - 1].y - a.data[a.data.length - 1].y);
}
