export const defs = (data, options) => {
  const refX = findOffset(options);
  const width = arrowWidth(options.print);
  const midpoint = width / 2;
  const height = getHeight(width, midpoint);
  // eslint-disable-next-line no-param-reassign
  data.defs.marker0 = {
    tagName: 'marker',
    id: `custom-arrow-${options.print ? `print` : `digital`}`,
    refY: midpoint,
    refX,
    children: [
      {
        tagName: 'path',
        d: `M 0 0 L ${height} ${midpoint} L 0 ${width} Z`,
      },
    ],
  };
};

export function arrowWidth(print) {
  return print ? 2.75 : 6.5;
}

export function findOffset(options) {
  const { print, product } = options;
  // const printOffset = print ? 4.25 : 8.5;
  const printOffset = print ? 2.5 : 8.5;
  // const productOffset = product === 'wsj' ? 4 : 0;
  return printOffset;
}

export function getHeight(width, midpoint) {
  return Math.sqrt(width * width - midpoint * midpoint);
}
