import { hasPromo } from '../../ui/util/cropsUtil';

// TO DO: check if we can remove this file
//exporting to utils
export function getStartY(titleGroup, options) {
  const { id, product, print } = options;
  switch (product) {
    case 'wsj':
      switch (print) {
        case true:
          switch (titleGroup) {
            case true:
              return 12.325;
            default:
              return 9.325;
          }
        default:
          switch (id) {
            case 'appfeed':
            case 'homepage':
            case 'baby_homepage':
              return 12;
            case 'soc':
              return 35;
            case 'ipadFeed2305':
              return 15;
            default:
              switch (titleGroup) {
                case true:
                  return 18;
                default:
                  return 15;
              }
          }
      }
    default:
      switch (titleGroup) {
        case true:
          return 18;
        default:
          return 15;
      }
  }
}

export function getHedDekSpacing(options) {
  const { product, print } = options;
  switch (product) {
    case 'wsj':
      switch (print) {
        case true:
          return -2;
        default:
          return 1;
      }
    default:
      return 1;
  }
}

export function getHedOrDeKSubdekSpacing(titleGroup, dekGroup, options) {
  const { print } = options;
  if (titleGroup && !dekGroup) {
    if (print) return -4;
    return 1;
  } else if (!titleGroup && dekGroup) {
    if (print) return 6;
    return 15;
  } else if (dekGroup) {
    if (print) return 5;
    return 13;
  } else return 0;
}

export function getDy(options, groupName) {
  const { product, print } = options;
  const dy = {
    print: {
      headings: 9.6,
      credits: 7.2,
    },
    digital: {
      headings: 20,
      credits: 17,
    },
  };
  if (product === 'wsj')
    switch (print) {
      case true:
        return dy.print[groupName];
      default:
        return dy.digital[groupName];
    }
  return dy.digital[groupName];
}

//this styles needed to calculate line width to insert breaks in the right spot in svg;
//bold and normal get same width
export function getStyles(options, name) {
  const { product, print } = options;
  let font;
  switch (product) {
    case 'wsj':
      switch (print) {
        case true:
          font = {
            title: 'bold 12.6px Retina',
            dek: '300 8.6px Retina',
            subdek: '300 8.6px Retina',
            date: '300 6px RetinaNarrow',
            note: '6px "Retina Narrow"',
            source: '6px "Retina Narrow"',
            yLabel: '300 7px Retina Narrow',
          };
          break;
        default:
          font = {
            title: 'bold 18px Retina',
            dek: 'normal 15px Retina',
            subdek: 'normal 15px Retina',
            subdekPromo: '15.3px Retina',
            date: '300 13px Retina Narrow',
            note: '300 13px Retina',
            notePromo: '300 13.2px Retina',
            source: '300 13px Retina',
            sourcePromo: '300 13.2px Retina',
            yLabel: '300 13px Retina Narrow',
          };
      }
      break;
    default:
      font = {
        title: 'bold 18px Retina',
        dek: 'normal 15px Retina',
        subdek: 'normal 15px Retina',
        date: '300 13px Retina Narrow',
        note: '300 13px Retina',
        source: '300 13px Retina',
      };
  }
  return font[name];
}

//exporting to inner/index.js
export function getHeadingMargin(options) {
  const { print, product, legend, type, horizontalBarType, annotations, id } = options;
  const { enabled, layout } = legend;
  //if legend top
  if (enabled && layout === 'top') {
    if (annotations.length) {
      if (print) return -9;
    }
    if (print) return -4;
    if (product === 'wsj') {
      switch (id) {
        case 'promo':
          return 13;
        default:
          return 5;
      }
    }
    return -8;
  } else {
    if (print) {
      if (type === 'bar') {
        if (horizontalBarType === 'noScaleAndLeft' || horizontalBarType === 'normal') return -1;
        else return -5;
      } else return 6;
    }
    if (type === 'bar') {
      if (horizontalBarType === 'normal') return -10;
      if (horizontalBarType === 'noScaleAndLeft') {
        switch (id) {
          default:
            return 8;
        }
      }
      //abovebar
      switch (id) {
        default:
          return 0;
      }
    }
    return 20;
  }
}

//sets proper spacing bw credit text items
export function getCreditYAddition(name, creditType, options) {
  const { print, product } = options;
  if (name === 'date') return 0;
  if (name.includes('note')) {
    switch (product) {
      case 'wsj':
        switch (print) {
          case true:
            return 2;
          default:
            return 5;
        }
    }
  } else {
    switch (product) {
      case 'wsj':
        switch (print) {
          case true:
            return 4;
          default:
            // return 10
            return 8;
        }
    }
  }
}

//space bw chart labels and credits
function getExtraCreditSpace(options, dateGroup) {
  switch (options.print) {
    case true:
      if (options.type === 'bar' && options.horizontalBarType === 'aboveBar') return -5;
      return 11;
    default:
      switch (dateGroup) {
        //if rolling date is enabled
        case true:
          return 10;
        default:
          if (options.type === 'bar' && options.horizontalBarType === 'aboveBar') return 0;
          if (hasPromo(options.id)) return 14;
          return 20;
      }
  }
}

//calculate proper height of credits group
export function getCreditsHeight(groups, creditsGroup, options) {
  const { dateGroup, noteGroup, sourceGroup, notePromoGroup, sourcePromoGroup } = groups;
  if (!sourceGroup && !noteGroup && !sourcePromoGroup && !notePromoGroup) {
    if (options.print) return -9;
    if (hasPromo(options.id)) {
      if (dateGroup) {
        if (options.id === 'soc') return 35;
        return 20;
      }
      return 10;
    }
    if (dateGroup) return 30;
    return 20;
  }
  return (
    creditsGroup.getBBox().height +
    (options.id === 'soc' ? 20 : 0) +
    getExtraCreditSpace(options, !!dateGroup)
  );
}
