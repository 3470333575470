// @ts-check
import { hasVideoGroup, hasPromoCrop, hasPrint, hasWSJVideoGroup } from '../utils';

/**
 * Get hidden categories depending on
 * belonging to a certain chart group (article|promo|print).
 * @param {ChartlosOptions} options
 * @returns {Array} - hidden categories for crop size
 */
function getHiddenCategories(options) {
  if (hasPrint(options.size)) return options.hiddenCategoriesPrint;
  if (hasPromoCrop(options.size)) return options.hiddenCategoriesPromo;
  return options.hiddenCategoriesArticle;
}

function alignLabels(options, chart) {
  const xAxis = chart.xAxis[0];
  const { ticks, tickPositions } = xAxis;

  let firstLabel;
  let secondLabel;
  let isChecked = false;
  const tickArr = tickPositions.sort((a, b) => a - b);
  // highcharts isLast property is not reliable adding second check to get last label
  const lastTick = getLastLabel(options.xAxisTicks);
  /*
  TODO: isFirst & isLast not reliable in this ex: wsj/chart/4ba48b007406d0d8b04b51bb
  not fixing it yet bc of potential side effects
  */
  tickArr.forEach((tick) => {
    const currTick = ticks[tick];
    const { isFirst, isLast, label } = currTick;
    if (!firstLabel && label) firstLabel = label.element;
    else if (!secondLabel && label) secondLabel = label.element;
    if (label && (isFirst || isLast || lastTick)) labelAlignHelper(currTick, options.axes, chart);
    if (!isChecked && secondLabel) {
      const { firstX, firstWidth, firstEnd, secondStart } = getLabelPositions(
        firstLabel,
        secondLabel
      );
      const overlap = firstEnd - secondStart;
      if (overlap > 0) {
        const shifted = tryShiftFirst(firstLabel, firstX, firstWidth, overlap, options.print);
        if (!shifted) removeFirstLabel(firstLabel, secondLabel);
      }
      isChecked = true;
    }
  });
}

function getLastLabel(xAxisTicks) {
  if (!xAxisTicks || typeof xAxisTicks !== 'object') return null;
  const ticksArr = Object.keys(xAxisTicks);
  for (let i = ticksArr.length - 1; i > 0; i--) {
    if (xAxisTicks[ticksArr[i]].label) return ticksArr[i];
  }
  return null;
}

function alignBarLabels(options, chart) {
  const tickArr = Object.keys(chart.yAxis[0].ticks);
  const { axes, id: chartId } = options;
  const isVideo = hasVideoGroup(chartId);
  // solves y-axis label cut off
  tickArr.forEach((tick) => {
    const currTick = chart.yAxis[0].ticks[tick];
    const { isLast, label, isFirst } = currTick;
    const lastYLabel = (isFirst && axes.reversed) || (isLast && !axes.reversed);
    if (label && lastYLabel) labelAlignHelper(currTick, axes, chart);
  });
}

function labelAlignHelper(currTick, axes, chart) {
  const { label, isFirst } = currTick;
  const text = label.element;
  const { width } = text.getBBox();
  const { x } = label.xy;
  const startOrEnd = isFirst && !axes.reversed ? x - width / 2 : x + width / 2;
  if (isFirst && startOrEnd < 0) {
    const newX = x + Math.abs(startOrEnd);
    positionLabels(text, newX);
  } else {
    const { width: yAxisWidth, left: yAxisStart } = chart.yAxis[0];
    const chartWidth = yAxisWidth + yAxisStart;
    if (startOrEnd > chartWidth) {
      const newX = x - (startOrEnd - chartWidth);
      positionLabels(text, newX);
    }
  }
}

function positionLabels(text, newX) {
  const tspans = text.querySelectorAll('tspan');
  [...tspans].forEach((label, i) => {
    if (i) label.setAttribute('x', newX);
  });
  text.setAttribute('x', newX);
}

function getLabelPositions(first, second) {
  const firstWidth = first.getBBox().width;
  const firstX = +first.getAttribute('x');
  const firstEnd = firstX + firstWidth / 2;
  const secondStart = +second.getAttribute('x') - second.getBBox().width / 2;
  return { firstX, firstWidth, firstEnd, secondStart };
}

function tryShiftFirst(firstLabel, firstX, firstWidth, overlap, isPrint) {
  const includesMonth = /[a-z]/gi.test(firstLabel.textContent);
  if (!includesMonth) return false;
  const padding = isPrint ? 0 : 2;
  const firstStart = firstX - firstWidth / 2;
  const newFirstStart = firstStart - overlap - padding;
  const labelFits = newFirstStart > 0;
  if (labelFits) {
    firstLabel.setAttribute('x', newFirstStart + firstWidth / 2);
    return true;
  }
  return false;
}

function removeFirstLabel(first, second) {
  const isShort = second.textContent.startsWith(`'`);
  if (isShort) {
    const firstText = first.textContent.split(' ');
    const start = firstText[firstText.length === 1 ? 0 : 1].slice(0, 2);
    first.textContent = '';
    second.textContent = `${start}${second.textContent.slice(1)}`;
  } else first.textContent = '';
}

function setZeroLine(yTicks, seriesGroup) {
  for (const tick of Object.keys(yTicks)) {
    if (yTicks[tick].pos === 0) {
      const zeroGridLine = yTicks[tick].gridLine.element;
      zeroGridLine.classList.replace('highcharts-grid-line', 'zero-line');
      if (seriesGroup) seriesGroup.element.appendChild(zeroGridLine);
      break;
    }
  }
}

function setZeroLineVideo(yTicks, seriesGroup) {
  const ticks = Object.keys(yTicks).map((num) => +num);
  const zeroLine = yTicks[ticks.includes(0) ? 0 : Math.min(...ticks)].gridLine.element;
  zeroLine.classList.replace('highcharts-grid-line', 'zero-line');
  if (seriesGroup) seriesGroup.element.appendChild(zeroLine);
}
// TO DO: see if we need this id
function setCJKId(chart, options) {
  const label = getSuffixLabel(chart, options);
  label.setAttribute('id', 'cjk');
}

function getSuffixLabel(chart, options) {
  let label = null;
  const isVerticalType =
    ['line', 'column', 'area', 'step', 'scatter'].includes(options.type) && !options.axes.reversed;
  for (const tick of Object.keys(chart.yAxis[0].ticks)) {
    if (
      (isVerticalType && chart.yAxis[0].ticks[tick].isLast) ||
      (!isVerticalType && chart.yAxis[0].ticks[tick].isFirst)
    ) {
      label = chart.yAxis[0].ticks[tick].label.element;
      return label;
    }
  }
}

function fixGridLines(chart, gridLines) {
  const { seriesGroup, yAxis } = chart;
  const { ticks } = yAxis[0];
  let zeroGridLine = false;
  for (const tick of Object.keys(ticks)) {
    if (ticks[tick].pos === 0) {
      zeroGridLine = ticks[tick].gridLine.element;
      zeroGridLine.classList.replace('highcharts-grid-line', 'zero-line');
      // checks whether gridlines placed properly for hor bar charts and sets z-index for zero line

      const dx = zeroGridLine.getAttribute('d').split(' ')[1];
      const serie = seriesGroup.element.querySelectorAll('.highcharts-series')[0];
      const serieTransform = serie.getAttribute('transform');
      const transformX = +serieTransform.split(',')[0].slice(10);
      const rect = serie.querySelectorAll('rect')[0];
      const value = +[...rect.classList].filter((c) => c.includes('value'))[0].slice(6);
      const y = +rect.getAttribute('y');
      let newZeroLineX;
      if (value > 0) {
        const height = +rect.getAttribute('height');
        newZeroLineX = transformX - height - y;
      } else {
        newZeroLineX = transformX - y;
      }
      const offset = newZeroLineX - dx;

      if (offset) {
        gridLines.forEach((gridLine) => {
          const d = gridLine.getAttribute('d').split(' ');
          const oldX = +d[1];
          d[1] = d[4] = oldX + offset;
          gridLine.setAttribute('d', d.join(' '));
        });
      }
      seriesGroup.element.appendChild(zeroGridLine);
      break;
    }
  }
}

function appendSuffix(options, chart) {
  const { suffix } = options.axes.units;
  const suffixText =
    hasWSJVideoGroup(options) && typeof suffix === 'string' ? suffix.toUpperCase() : suffix;
  const label = getSuffixLabel(chart, options);
  const y = label.getAttribute('y');
  const x = +label.getAttribute('x');
  const spaceWidth = 2;
  const leadingSpaces = /^(\s)*/g;
  const spaces = suffix.match(leadingSpaces)[0].length;
  const xOffset = spaceWidth * spaces;
  const newX = x + xOffset + (hasVideoGroup(options.id) ? label.getBBox().width : 0);
  const text = `<span class='highcharts-suffix'>${suffixText}</span>`;
  const attr = {
    'text-anchor': 'start',
    class: 'highcharts-suffix highcharts-axis-labels',
  };
  const css = {
    fontFamily: getFont(options),
    fontWeight: options.styles.suffixFontWeight,
  };

  chart.suffix = chart.renderer.text(text, newX).attr(attr).css(css).add(chart.yAxis[0].labelGroup);
  chart.suffix.element.setAttribute('y', y);
}

function getFont(options) {
  switch (options.headings.chartFont) {
    case 'ch':
      return options.styles.chFontFamily;
    case 'ja':
      return options.styles.jaFontFamily;
    default:
      return options.styles.labelFontFamily;
  }
}

function getXAxisClassName(isScatter, isThumb) {
  if (isScatter) return 'scatter';
  return isThumb ? 'thumb' : '';
}

export {
  getHiddenCategories,
  alignLabels,
  alignBarLabels,
  positionLabels,
  setZeroLine,
  setZeroLineVideo,
  setCJKId,
  getSuffixLabel,
  fixGridLines,
  appendSuffix,
  getXAxisClassName,
};
