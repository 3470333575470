/**
 * Options passed to the renderer include options for multiple sizes. This
 * function narrows down all those options to those relevant to the current
 * size only.
 */

//todo: revisit - currently see only article sizes
export default function filterOptionsForSize(options) {
  const size = getSize(options);
  const sizeObj = options.sizes[size.id];
  const notInteractive = ['soc', 'ipadFeed2305', 'print-wsj'].includes(options.id);
  options.interactive = notInteractive ? false : size.interactive;

  options.target = size.target;
  options.size = options.size || size.id;
  options.height = options.height || sizeObj.height || size.height;
  const headings = options.headings || sizeObj.headings;
  options.headings = writeHeadings(headings, options.width, options.id);
  options.stacking = sizeObj.stacking;
  options.axes = sizeObj.axes;
  options.xAxis = sizeObj.xAxis;
  options.yAxis = sizeObj.yAxis;
  options.tooltips = sizeObj.tooltips.sameAsAxes ? sizeObj.axes.units :
    sizeObj.tooltips;
  return options;
}

function writeHeadings(headings, width, id) {
  const copiedHeadings = Object.assign({}, headings);
  return copiedHeadings;
}

export function getSize(options) {
  const index = indexOfSize(options);
  if (index !== -1) {
    return options.defaults.sizes[index];
  }
  options.defaults.sizes.sort((a, b) => {
    if (a.hasOwnProperty('minWidth') && b.hasOwnProperty('minWidth')) {
      return a.minWidth - b.minWidth;
    }
    return 1;
  });
  let size = options.defaults.sizes[0];
  options.defaults.sizes.forEach((item, index) => {
    if (item.hasOwnProperty('minWidth') &&
        Math.ceil(options.width) >= item.minWidth) {
      size = options.defaults.sizes[index];
    }
  });
  return size;
}


function indexOfSize(options) {
  if (options.size) {
    for (let i = 0; i < options.defaults.sizes.length; i++) {
      if (options.size === options.defaults.sizes[i].id) {
        return i;
      }
    }
  }
  return -1;
}
