// @ts-check
import STYLES from './constants';

/**
 * Generate styles for each crop see {@link STYLES}
 * @param {Publications} publication
 * @param {string} cropSize
 * @return {StyleOptions}
 */
const getStyles = (publication, cropSize) =>
  Object.keys(STYLES).reduce((publicationStyle, prop) => {
    if (STYLES[prop][publication]) {
      publicationStyle[prop] =
        STYLES[prop][publication][cropSize] || STYLES[prop][publication].default;
    } else if (STYLES[prop].default) {
      publicationStyle[prop] = STYLES[prop].default;
    } else {
      console.error(
        `${publication} publication is not found in the ${prop}, please check the STYLES constant in the chart/style/contants`
      );
    }
    return publicationStyle;
  }, {});

export { getStyles };
