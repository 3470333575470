// @ts-check
import * as format from './format';
import datetime from './datetime';
import createLabel from './createLabel';
import { QUARTERLY_MINOR_TICK_THRESHOLD, INTERVAL_OPTIONS, TICK_TYPES, TIMETYPES, QUARTERLY_YEAR } from './constants';
import moment from 'moment';
import { hasPromoCrop, hasPrint } from '../inner/utils';
import { findInterval, getMinor } from './axisIntervalsUtils';
import { isSameQuarter } from './quarterly'

const { CENTURY, DECADE, HALFDECADE, YEAR, QUARTER, MONTH, DATE, DAY, HOUR, HALF_HOUR, MINUTE } = INTERVAL_OPTIONS;
const { MAJOR, MINOR } = TICK_TYPES;
/**
 * Returns major and minor ticks interval
 * based on belonging to a specific group (Article|promo|print)
 * @param {ChartlosOptions} options - chartlos options
 * @returns {string[]} - an array of major and minor intervals
 */
const getMajorMinorValues = (options) => {
  if (hasPrint(options.id)) return [options.majorTicksPrint, options.minorTicksPrint];
  if (hasPromoCrop(options.id)) return [options.majorTicksPromo, options.minorTicksPromo];
  return [options.majorTicksArticle, options.minorTicksArticle];
}

/**
 * Processes and optimizes the chart data before building categories and series.
 */
export default function processData(options) {

  if (options.categoriesAreDates) {
    options.dates = datetime.parse(options.categories, options);

    if (options.type !== 'bar') {
      const {
        fiscalYears,
        dateSpan,
        liveMarketData,
        quarterlyResults
      } = options;

      const sortedDates = [...options.dates].sort((a, b) => a.value - b.value);

      options.xAxisTicks = {};
      const [majorValue, minorValue] = getMajorMinorValues(options);
      const [isDecade, isHalfDecade, isCentury] = [DECADE, HALFDECADE, CENTURY].map(
        (timePeriod) => timePeriod === majorValue
      );
      const isHalfHour = minorValue === HALF_HOUR;
      const longerThanYear = isDecade || isHalfDecade || isCentury;

      // for intervals longer than yearly, use YEAR value on date object
      const major = longerThanYear ? YEAR : majorValue;
      const minor = getMinor(minorValue);

      if(options.dates.length===1){
        const date = options.dates[0];
        const label = createLabel({ dateObj:date, type: majorValue, isFirstLabel:true, fiscalYears });
        options.xAxisTicks[date.value] = { label, type: MAJOR };
        return options;
      }

      const majorChecker = checkMajor({type: major, longerThanYear, isDecade, isHalfDecade, isCentury, quarterlyResults});
      const minorChecker = checkMinor({type: minor, minorValue, quarterlyResults, isHalfHour});

      const firstDate = sortedDates[0];
      const lastDate = sortedDates[sortedDates.length - 1];

      let [majorTick, minorTick] = [major, minor].map((type) => firstDate[type]);
      let isFirstLabel = true;

      const interval = findInterval(majorValue, quarterlyResults);

      const tickPerColumn = dateSpan / interval >= options.series[0].data.length - 2;

      if (options.type === 'column' && tickPerColumn) {
        // column charts that can fit a label for each column
        sortedDates.forEach((date) => {
          const label = createLabel({dateObj:date, type: majorValue, isFirstLabel, fiscalYears});
          options.xAxisTicks[date.value] = { label, type: MAJOR };
          isFirstLabel = false;
        });
      } else {
        if (liveMarketData) {
          /*
            because market data dates are predictable,
            base ticks/labels off first date within designated time period
          */
          sortedDates.forEach((date, i) => {
            const secondDate = sortedDates[1];
            const needsMajor = majorChecker({date, majorTick, i, nextDate: secondDate, liveMarketData});
            const needsMinor = minorChecker({date, minorTick, i, nextDate: secondDate, liveMarketData});
            if (needsMajor || needsMinor) {
              const [value, type] = needsMajor ? [majorValue, MAJOR] : [minorValue, MINOR];
              const label =
                isFirstLabel || needsMajor
                ? createLabel({dateObj:date, type:value, isFirstLabel, fiscalYears}) : '';
              options.xAxisTicks[date.value] = { label, type };
              if (needsMajor) majorTick = date[major];
              minorTick = date[minor];
              isFirstLabel = false;
            }
          });
        } else {
          /*
            for non-market data charts, set 1st tick,
            then add interval to it for subsequent ticks
          */
          let firstTick;
          let count = 0;

          for (let i = 0; i < sortedDates.length; i++) {
            const date = sortedDates[i];
            const nextDate = sortedDates[i+1];
            const needsMajor = majorChecker({date, majorTick, i, nextDate});
            const needsMinor = minorChecker({date, minorTick, i, nextDate});


            if (needsMajor || needsMinor) {
              firstTick = date.value;
              if (i !== 0) count++;
              break;
            }
          }

          const tick = moment.utc(firstTick);
          const lastMoment = moment.utc(lastDate.value);

          while (
            (tick.isBefore(lastMoment) || tick.isSame(lastMoment)) &&
            count < sortedDates.length
          ) {
            const nextDate = sortedDates[count+1];
            const needsMajor = majorChecker({date:tick, majorTick, i:count, nextDate});
            let tickExist = true;
            if (major === DATE || minor === DATE || major === HOUR || minor === HOUR) {
              tickExist = sortedDates.find((date) => date.value === tick.valueOf());
            }
            if (tickExist) {
              if (needsMajor) {
                const quarterlyYear = options.quarterlyResults && options.categories.length < QUARTERLY_MINOR_TICK_THRESHOLD && majorValue === YEAR && minorValue === YEAR;
                const type = quarterlyYear ? QUARTERLY_YEAR : majorValue;
                const label = createLabel({dateObj: tick.toObject(), type, isFirstLabel, fiscalYears});
                options.xAxisTicks[tick.valueOf()] = { label, type: MAJOR };
                majorTick = tick[major]();
                isFirstLabel = false;
              } else {
                const qLabelDisplayed = quarterlyResults && minorValue===QUARTER && majorValue === QUARTER;
                const needsLabel = isFirstLabel && (!quarterlyResults || quarterlyResults && minorValue!==QUARTER) || qLabelDisplayed;
                const label = needsLabel
                  ? createLabel({dateObj: tick.toObject(), type: minorValue, isFirstLabel, fiscalYears})
                  : '';
                options.xAxisTicks[tick.valueOf()] = { label, type: MINOR };
                if(needsLabel && !qLabelDisplayed) isFirstLabel = false;
              }
            }
            const [n, timeType] = getTimeType(
              minorValue,
              sortedDates[count < sortedDates.length ? count : sortedDates.length - 1],
              nextDate
            );
            tick.add(n, timeType);
            if (timeType === HOUR || timeType === MINUTE && minorValue === HALF_HOUR) {
              const newTickValue = tick.valueOf();
              const idx = sortedDates.slice(count).findIndex((el) => el.value === newTickValue);
              if( idx !== -1) count = idx + count;
            } else count++;
          }
        }

        const tickValues = Object.keys(options.xAxisTicks);
        const labeledTicks = tickValues.filter((key) => options.xAxisTicks[key].label);
        if (labeledTicks.length === 1) {
          // ensure line charts have at least two labels
          const lastTickValue = Math.max(...tickValues);
          const firstTickValue = Math.min(...tickValues);
          const lastTickDate = moment.utc(lastTickValue).toObject();
          const firstTickDate = moment.utc(firstTickValue).toObject();
          const needsAdditionalTick = majorValue === HOUR;
          if (needsAdditionalTick) {
            options.xAxisTicks[firstDate.value] = {};
            const newFirstTickDate = moment.utc(firstDate.value).toObject();
            options.xAxisTicks[firstDate.value].label = createLabel({
              dateObj: newFirstTickDate,
              type: minorValue,
              isFirstLabel: true,
              fiscalYears
            });
          }
          const isLastLabeled =
            minor === MONTH && lastTickDate.months === sortedDates[sortedDates.length - 1].month;
          if (minor === MONTH && !isLastLabeled) {
            const lastLabel = sortedDates[sortedDates.length - 1];
            options.xAxisTicks[lastLabel.value] = {};
            options.xAxisTicks[lastLabel.value].label = createLabel({
              dateObj: lastLabel,
              type: minorValue,
              isFirstLabel: false,
              fiscalYears: false
            });
          } else
            options.xAxisTicks[lastTickValue].label = createLabel({dateObj: lastTickDate, type: minorValue, isFirstLabel:false, fiscalYears: false});
          const needsLabel = isFirstLabel && firstTickDate.months !== 11 && sortedDates[1].month;
          if (needsLabel)
            options.xAxisTicks[firstTickValue].label = createLabel({
              dateObj: firstTickDate,
              type: minorValue,
              isFirstLabel:!needsAdditionalTick,
              fiscalYears
            });
        } else if (!labeledTicks.length) {
          // ensure column charts have at least one label
          const label = createLabel({dateObj: firstDate, type:minorValue, isFirstLabel: true, fiscalYears});
          options.xAxisTicks[firstDate.value] = { label, type: MINOR };
        }
      }
    }

    options.datesAll = datetime.all(options.dates);
    if (options.type !== 'bar') {
      options.series = insertDates(options.series, options);
    }
  }
  if (options.categoriesAreDates && !options.quarterlyResults || (options.quarterlyResults && options.series[0].data.length > QUARTERLY_MINOR_TICK_THRESHOLD)) {
    options = sortDataByDate(options);
  }
  if (options.axes && options.axes.units && options.axes.units.divisor !== 'none') {
    options = divideYAxisValues(options);
  }
  options.series.forEach((currentSeries) =>
    currentSeries.data.forEach((point) => (point.className = `value-${point.y}`))
  );
  return options;
}

function checkMajor({type, longerThanYear, isDecade, isHalfDecade, isCentury, quarterlyResults}) {
  return function ({date, majorTick, i, nextDate, liveMarketData}) {
    const [majorValue, year, majorDate] = getValues(date, type);
    const isFirst = i === 0;
    const dateCheck =  !isFirst && !liveMarketData && type === DATE && nextDate && majorDate.month !== nextDate.month;
    const firstDateCheck = isFirst && isFirstMajor({majorDate, nextDate, type, quarterlyResults});
    if(quarterlyResults) {
      const isQ = type === QUARTER;
      const isY = type === YEAR;
      if(isQ) {
        return majorDate.month < 3;
      } else if (isY && !longerThanYear) {
        const sameQuarter = isSameQuarter({date:majorDate, nextDate});
        if(sameQuarter) return sameQuarter;
      }
    }
    return (
      (majorValue !== majorTick ||
        dateCheck || firstDateCheck) &&
      (!longerThanYear ||
        (isDecade && year % 10 === 0) ||
        (isHalfDecade && year % 5 === 0) ||
        (isCentury && year % 100 === 0))
    );
  };
}

const isFirstMajor = ({majorDate, nextDate, type, quarterlyResults}) => {
  const { year, month, date, hour } = majorDate;
  const { year: nextYear, month: nextMonth, date: nextDay } = nextDate;
  const isYearly =
    year !== nextYear &&
    (month === nextMonth || (month === 0 && nextMonth === undefined));

  const startsFromJanuary = year === nextYear && month === 0;
  switch(type){
    case YEAR:
      if (isYearly || (date === 1 && startsFromJanuary)) return true;
      if (quarterlyResults && month<3) return true;
      return false
    case QUARTER:
      if (month<3) return true;
    case MONTH:
      if (date === 1 && !hour) return true;
      return false
    case DATE:
      if ((date !== nextDay || (date === nextDay && month !== nextMonth)) && !hour) return true;
      return false
    default:
      return false
  }
};

function checkMinor({type, minorValue, quarterlyResults, isHalfHour}) {
  return function ({date, minorTick, i, nextDate, liveMarketData}) {
    const [value, year, minorDate] = getValues(date, type);
    const dateCheck = !liveMarketData && type === DATE && !minorDate.hour && nextDate && minorDate.month !== nextDate.month;
    const firstDateCheck = !liveMarketData && i === 0 && isFirstMinor({dateObj:minorDate, nextDate, type, quarterlyResults});

    if(isHalfHour) {
      return value !== minorTick && value % 30 === 0;
    }

    return (
      (value !== minorTick || dateCheck || firstDateCheck) &&
      (minorValue !== DECADE || year % 10 === 0)
    );
  };
}

const isFirstMinor = ({dateObj, nextDate, type, quarterlyResults}) => {
  const { year, month, date, hour } = dateObj;
  const { year: nextYear, month: nextMonth, date: nextDay } = nextDate;
  const endOfTheMonth = [26, 27, 28, 29, 30, 31];
  const monthly = month !== nextMonth;
  const sameDay = date === nextDay;
  const endSameDay = endOfTheMonth.includes(date) && endOfTheMonth.includes(nextDay);
  switch(type){
    case YEAR:
      const isJanuary = month === 0;
      const isYearly = year !== nextYear && month === nextMonth;
      if (isYearly) return true;
      if (monthly && (sameDay || endSameDay)) return isJanuary;
      return false
    case QUARTER:
      return quarterlyResults && month>=3;
    case MONTH:
      const sameMonth = month === nextMonth;
      const isDecember = month === 11;
      const notDaily =
        nextDay - date > 1 || (nextDay - date <= 1 && nextMonth !== month);
      const firstWeek = nextDay - date <= 7 && date <= 7;
      if (
        (monthly && ((sameDay && !isDecember) || !endOfTheMonth.includes(date) || endSameDay)) ||
        (sameMonth && notDaily && firstWeek)
      )
        return true;
        return false
      case DATE:
        if ((date !== nextDay || (date === nextDay && month !== nextMonth)) && !hour)
        return true;
        return false
      default:
        return false;
    }
  }

function convertMoment(date) {
  const convertedDate = {};
  Object.keys(date).forEach((key) => {
    if (key === DATE) convertedDate[key] = date[key];
    else convertedDate[key.slice(0, -1)] = date[key];
  });
  return convertedDate;
}

/**
 * @param {(Date|Moment)} date - Date or Moment
 * @param  {String} value - either hour, date, month, year - based on user's minor/major interval selection
 * @returns {(Array)} -  an array that contains either year, month, day or hour {number}; year - that helps with half-decade, decade and century checks {number} and date object helps with a first tick logic.
 */
function getValues(date, value) {
  const isMoment = checkMoment(date);
  return isMoment
    ? [date[value](), date.year(), convertMoment(date.toObject())]
    : [date[value], date.year, date];
}

function checkMoment(date) {
  return moment.isMoment(date);
}

function getTimeType(minorValue, currentDate, nextDate) {
  let timeType = minorValue;
  if (minorValue === DATE) timeType = 'day';
  if (minorValue === DECADE) timeType = YEAR;
  if (minorValue === HOUR) {
    if (nextDate && currentDate.date !== nextDate.date && nextDate.minute === 30)
      timeType = MINUTE;
    if (currentDate.minute === 30) timeType = MINUTE;
  }
  if(minorValue.includes(MINUTE)) timeType = MINUTE;
  const n = getN(minorValue, timeType, currentDate, nextDate);
  return [n, timeType];
}

function getN(minorValue, timeType, currentDate, nextDate) {
  const { year, month, date, hour, minute, value } = currentDate;
  if (minorValue === DECADE) return 10;
  if(nextDate){
    const { year: nextYear, month: nextMonth, date: nextDay, hour: nextHour, minute: nextMinute, value: nextValue } = nextDate;
    if (timeType === YEAR && nextYear - year) return nextYear - year;
    if (timeType === MONTH && nextMonth - month) return nextMonth + 1 - ((month + 1) % 12);
    if (timeType === DAY) return Math.ceil((nextValue - value) / TIMETYPES.D);
    if (timeType === MINUTE) {
      if(minorValue === HOUR){
        if (nextMinute === 30 && 24 - hour + nextHour > 1) {
          return (24 - hour + nextHour) * 60 + nextMinute;
        } else if (minute === 30) return minute;
      } else return 30
    }
  }
  return 1;
}


// Insert date values into all data.
function insertDates(data, options) {
  const proxyYears = getProxyYears(options);
  data.forEach((series) => {
    series.data.forEach((item, index) => {
      let dateObj = options.dates[index];
      let date = new Date(dateObj.value);
      if (dateObj.year === undefined && proxyYears[index] !== undefined) {
        dateObj.yearIsProxy = true;
        date.setUTCFullYear(proxyYears[index]);
      }
      item.x = date.valueOf();
    });
  });
  return data;
}

// For data with dates with months but not years, create proxy years so the
// dates can be sorted correctly.
function getProxyYears(options) {
  let proxyYears = [];
  if (options.datesAll.month && !options.datesAll.year) {
    let year = new Date().getUTCFullYear();
    let month = undefined;
    for (let i = options.dates.length - 1; i >= 0; i--) {
      if (month !== undefined && month <= options.dates[i].month) {
        year -= 1;
      }
      proxyYears[i] = year;
      month = options.dates[i].month;
    }
  }
  return proxyYears;
}

function sortDataByDate(options) {
  options.series.forEach((item) => {
    item.data.forEach((pt, index) => {
      pt.index = index;
    });
    if (item.data.every((point) => point.x)) {
      item.data.sort((a, b) => {
        return a.x - b.x;
      });
    }
  });
  return options;
}

function divideYAxisValues(options) {
  const divisor = format.getDivisor(options.axes.units.divisor);
  for (let i = 0; i < options.series.length; i++) {
    const yAxisValue = options.series[i].data;
    yAxisValue.forEach((value) => {
      if (value.y !== null) {
        value.y = value.y / divisor;
      }
    });
  }
  return options;
}
