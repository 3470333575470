import { findOffset, arrowWidth, getHeight } from './defs';
import { hasVideoGroup, createGroup, hasWSJVideoGroup } from '../utils';
import { positionLabels } from '../axes/axes.utils';

export function sortAnnotations(shapesGroup, allLabels, labels) {
  const { shapesFiltered, sortedShapesWithIdx } = sortShapesHelper(shapesGroup);
  // sort labels based on corresponding shape index
  const sortedLabels = Object.values(sortedShapesWithIdx).map((key) => [...allLabels][key.index]);
  // reason for sorting labels is that style for annotations no longer set inline, it's part of 'labels' array.
  // it's ordered based on ui user order. later style properties would be used to set line type, align, showLine
  const sortedLabelsOptions = sortedLabels.map((key) => {
    for (let i = 0; i < labels.length; i++) {
      if (key === labels[i].graphic.element) {
        return labels[i];
      }
    }
  });
  const sortedShapes = [...shapesFiltered].sort((a, b) => findAttr(a) - findAttr(b));
  return [sortedLabels, sortedShapes, sortedLabelsOptions];
}

export function sortPaths(shapesGroup, annotationG) {
  const { sortedShapesWithIdx } = sortShapesHelper(shapesGroup);
  const pathsGroup = annotationG.element.querySelector('.annotation-paths');
  const paths = pathsGroup.querySelectorAll('path');
  // based on the index of shape sort paths,
  // convert into nodeList,
  // remove old children and append sorted children
  const sortedPathsArr = sortedShapesWithIdx.reduce((arr, shape, idx) => {
    arr[shape.index] = paths[idx];
    return arr;
  }, []);
  const sortedPathsList = toNodeList(sortedPathsArr);
  const children = pathsGroup.childNodes;
  [...children].forEach((child) => {
    pathsGroup.removeChild(child);
  });
  [...sortedPathsList].forEach((node) => {
    pathsGroup.appendChild(node);
  });
}

function toNodeList(arrayOfNodes) {
  const fragment = document.createDocumentFragment();
  arrayOfNodes.forEach((item) => {
    if (item) {
      fragment.appendChild(item.cloneNode(true));
    }
  });
  return fragment.childNodes;
}

function sortShapesHelper(shapesGroup) {
  // remove 'highcharts trackers' paths, so they're not affect arrow(which is also path) marker-end case.
  const shapesFiltered = [...shapesGroup.element.childNodes].filter(
    (child) => child.getAttribute('class') !== 'highcharts-tracker-line'
  );
  // array of objects is used to properly sort labels
  const shapesWithIdx = shapesFiltered.map((el, idx) => ({ index: idx, value: el }));
  // shape indexes sorted
  const sortedShapesWithIdx = shapesWithIdx.sort((a, b) => findAttr(a.value) - findAttr(b.value));
  return { shapesFiltered, sortedShapesWithIdx };
}

function findAttr(el) {
  const type = el.tagName;
  switch (type) {
    case 'circle':
      return +el.getAttribute('cx');
    case 'path':
      return getArrowPosition(el).x;
  }
}

export function spaceOutAndDrawLines(
  options,
  labels,
  shapes,
  userOptions,
  gridLine,
  annotationG,
  chart,
  yAxisLabels
) {
  // digital
  const isVideo = hasVideoGroup(options.id);
  const yAxisLabel = yAxisLabels[options.type === 'bar' ? 0 : yAxisLabels.length - 1];
  const { recessionLabel } = chart;
  const [suffix, recession] = getEls(options, yAxisLabel, recessionLabel, chart, isVideo);

  const margin = options.print ? 6 : 16;
  const x = getLeftX(options, yAxisLabel, margin, suffix, chart);
  const args = [labels, shapes, x, margin, suffix, recession, isVideo];
  shiftLabelsRight(...args);
  shiftLabelsLeft(
    options,
    labels,
    shapes,
    x,
    margin,
    userOptions,
    gridLine,
    annotationG,
    chart,
    suffix,
    recession
  );
}
export function centerVideoLabels(options, annotationG) {
  if (hasVideoGroup(options.id)) {
    const pathsGroup = annotationG.element.querySelector('.annotation-paths');
    const paths = pathsGroup.querySelectorAll('path');
    const labelsGroup = annotationG.element.querySelector('.highcharts-annotation-labels');
    const labels = labelsGroup.querySelectorAll('text');
    labels.forEach((label, i) => {
      if (paths[i]) {
        const pathX = paths[i].getAttribute('d').split(' ')[1];
        const labelWidth = label.getBBox().width;
        const currentX = +label.getAttribute('x');
        const isMiddle = pathX <= options.width - labelWidth / 2;
        const isLeft = pathX < currentX + labelWidth / 2;
        const newX = currentX + labelWidth / 2;
        const tspans = label.querySelectorAll('tspan');
        [...tspans].forEach((tspan, idx) => {
          if (isLeft) {
            const translateX = parseTransform(label.parentElement).x;
            tspan.setAttribute('x', pathX - translateX);
          } else if (isMiddle) {
            tspan.setAttribute('x', newX);
            tspan.setAttribute('text-anchor', 'middle');
          }
          // eslint-disable-next-line no-param-reassign
          if (idx) tspan.style.fontSize = '28px';
        });
      }
    });
  }
}
function getEls(options, yAxisLabel, recessionLabel, chart, isVideo) {
  let suffix = null;
  let recession = null;
  if (recessionLabel) {
    const labelWidth = getWidth(recessionLabel.element);
    const startX = +recessionLabel.element.getAttribute('x');
    const endX = startX + labelWidth;
    const midPoint = startX + labelWidth / 2;
    recession = {
      type: 'recession',
      startX,
      endX,
      midPoint,
    };
  }
  if (options.axes.units.suffix) {
    const padding = isVideo ? 5 : 0;
    const suffixEl = chart.suffix;
    suffix = {
      type: 'suffix',
      text:
        hasWSJVideoGroup(options) && typeof options.axes.units.suffix === 'string'
          ? options.axes.units.suffix.toUpperCase()
          : options.axes.units.suffix,
      startX: +suffixEl.element.getAttribute('x'),
      endX: +suffixEl.element.getAttribute('x') + getWidth(suffixEl) + padding,
    };
  }
  return [suffix, recession];
}

function getLeftX(options, yAxisLabel, margin, suffix, chart) {
  if (options.print) return 0;
  const shortSuffix = suffix && suffix.text.length <= 2;
  if (shortSuffix) return +yAxisLabel.getAttribute('x') + getWidth(yAxisLabel) + margin;
  return topLabelX(chart, options) + margin;
}

function topLabelX(chart, options) {
  const { reversed } = options.axes;
  const yAxis = chart.yAxis[0];
  const { tickPositions } = yAxis;
  const tickPosition = reversed ? tickPositions[0] : lastItem(tickPositions);
  return yAxis.ticks[tickPosition].label.xy.x;
}

function shiftLabelsRight(labels, shapes, hardLeft, margin, suffix, recession, isVideo) {
  // from left to right, ensure proper spacing between labels
  let startX = hardLeft;
  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];
    const shape = shapes[i];
    const labelWidth = getWidth(label);
    let { x } = parseTransform(label);
    if (x < 0 && isVideo) x = 0;
    let needTransform = false;
    if (suffix || recession) {
      const { moveX, shapeX } = getPositions(x, shape, labelWidth);
      const [shiftRight, overlap] = needShiftRight(moveX, suffix, recession);
      if (shiftRight) {
        x = shiftRightX(overlap, shapeX, labelWidth);
        needTransform = true;
      }
    }
    // } else {
    const { shapeX } = getPositions(x, shape, labelWidth);
    if (startX === 0) {
      // set x, so label is centered
      if (shapeX - labelWidth / 2 >= 0) {
        x = shapeX - labelWidth / 2;
      } else {
        // start label from 0
        x = 0;
      }
      needTransform = true;
    } else if (x < startX) {
      x = startX;
      needTransform = true;
    }
    // else if (needTransform = x < startX) x = startX;
    if (needTransform) {
      updateTransform(label, x);
    }
    startX = x + labelWidth + margin; // farthest left next label can be
  }
}

function shiftRightX(overlap, shapeX, labelWidth) {
  // position label to ensure line wraps around recession/suffix
  const centeredOnOverlap = overlap.startX + 2 - labelWidth / 2;
  return shapeX >= centeredOnOverlap && shapeX <= overlap.endX ? shapeX + 1 : centeredOnOverlap;
}

function needShiftRight(moveX, suffix, recession) {
  const overlap = checkOverlap(moveX, recession, suffix);
  const shiftRight =
    overlap.type === 'suffix' || (overlap.type === 'recession' && moveX >= recession.midpoint);
  return [shiftRight, overlap];
}
function checkOverlap(moveX, recession, suffix) {
  // does line overlap with recession or suffix label
  if (recession && moveX >= recession.startX && moveX <= recession.endX) {
    return recession;
  }
  if (suffix && moveX >= suffix.startX && moveX <= suffix.endX) {
    return suffix;
  }
  return { type: 'none' };
}

function shiftLabelsLeft(
  options,
  labels,
  shapes,
  hardLeft,
  margin,
  userOptions,
  gridLine,
  annotationG,
  chart,
  suffix,
  recession
) {
  /*
   * From right to left, ensure proper spacing between labels
   * After positioning each label, draw its line
   * Hide annotations pushed off left of chart or into suffix label
   */

  const hardRight = userOptions[0]?.chart?.chartWidth;
  // create annotation-paths group inside main annotation group to store custom paths
  const pathGroup = createGroup(chart.renderer, 'annotation-paths', annotationG);

  const drawLine = lineRenderer(chart.renderer, options, margin, gridLine);

  let startX = hardRight;
  let nextLabel;
  let nextShape;
  for (let i = labels.length - 1; i >= 0; i--) {
    const label = labels[i];
    const shape = shapes[i];
    const labelWidth = getWidth(label);
    // since we using styled mode, styles no longer get passed inline.
    const [flushAlign, showLine, dotted] = getProps(userOptions[i].options.style);
    let { x } = parseTransform(label);
    let transformX;
    if (x + labelWidth >= startX) {
      x = startX - labelWidth;
      const itemFlush = i === labels.length - 1 && flushAlign;
      // for right-most labels pushed off chart with flush alignment selected
      if (itemFlush) label.setAttribute('text-anchor', 'end');
      transformX = itemFlush ? hardRight : x;
    }

    if (recession || suffix) {
      const { moveX, shapeX } = getPositions(x, shape, labelWidth);
      const overlap = checkOverlap(moveX, recession, suffix);
      if (overlap.type !== 'none') {
        x = shiftLeftX(overlap, shapeX, labelWidth);
        transformX = x;
      }
    }

    const positions = getPositions(x, shape, labelWidth);
    const hidden =
      x < hardLeft ||
      (options.product !== 'financialnews' && suffix && positions.moveX <= suffix.endX);
    if (hidden) {
      hideAnnotation(label, shape);
    } else {
      if (transformX) {
        updateTransform(label, transformX);
      }
      if (showLine) {
        drawLine(label, shape, nextLabel, nextShape, positions, dotted, pathGroup, i, chart);
      }
    }

    [nextLabel, nextShape] = [positions.moveX, positions.shapeX]; // to determine whether jointed lines overlap
    startX = x - margin; // farthest right next label can be
  }
}

function shiftLeftX(overlap, shapeX, labelWidth) {
  // position label to ensure line wraps around recession/suffix
  const centeredOnOverlap = overlap.startX - 2 - labelWidth / 2;
  return shapeX <= centeredOnOverlap + labelWidth && shapeX >= overlap.startX
    ? shapeX - labelWidth - 1
    : centeredOnOverlap;
}

function getWidth(el) {
  // return el.getBoundingClientRect().width;
  return el.getBBox().width;
}

function getElHeight(el) {
  return el.getBBox().height;
}

function parseTransform(label) {
  // get label's x, y
  const transform = label.getAttribute('transform');
  const start = transform.indexOf('(') + 1;
  const end = transform.indexOf(')');
  const nums = transform.slice(start, end).split(',');
  return { x: +nums[0], y: +nums[1] };
}

function getPositions(x, shape, labelWidth, direction) {
  const shapeX = getShapeX(shape);
  const labelX = x + labelWidth / 2; // label center
  const jointed = isJointed(labelX, shapeX, labelWidth, direction);
  const moveX = jointed ? labelX : shapeX; // line top
  return { labelX, moveX, shapeX, jointed };
}

function updateTransform(label, x) {
  // set new x for label
  const { y } = parseTransform(label);
  const translate = `translate(${x},${y})`;
  label.setAttribute('transform', translate);
}

function lineRenderer(renderer, options, margin, gridLine) {
  const cushion = margin / 2;
  let addPx = cushion;
  return function (label, shape, nextLabel, nextShape, positions, dotted, pathGroup, i, chart) {
    const { labelX, moveX, shapeX, jointed } = positions;
    const [labelY, shapeY] = [getLabelY(label), adjustShapeY(options, shape, gridLine, chart, i)];
    // for highstocks charts, if shape cut off on left, re-render shape to force added space
    if (options.irregularDates) checkCutoff(chart, options, shape, shapeX, shapeY);

    const [m, jointPath, lastL] = getPathStart(moveX, labelY, shapeX, shapeY);
    if (jointed) {
      // draw jointed line
      const jointRight = labelX > shapeX;
      let jointY = options.print ? labelY + 2.5 : initialJointY(chart, margin, jointRight);
      // let jointY = initialJointY(container, margin, jointRight);
      // if jointed lines overlap, bump 2nd line down
      if (nextLabel) {
        if (shapeX >= nextLabel) {
          jointY += addPx;
          addPx += cushion; // keep increasing addPx if >2 consecutive jointed lines overlap
        } else if (moveX >= nextShape) {
          jointY -= addPx;
          addPx += cushion;
        } else {
          addPx = cushion; // reset addPx once consecutive jointed lines don't overlap
        }
      }
      jointPath.push(...['L', labelX, jointY, 'L', shapeX, jointY]);
    }
    const path = [...m, ...jointPath, ...lastL];

    renderPath(renderer, options, path, dotted, pathGroup, i);
  };
}

function initialJointY(chart, margin, jointRight) {
  const divisor = jointRight ? 1 : 2;
  return chart.plotTop + margin / divisor;
}
function checkCutoff(chart, options, shape, shapeX, shapeY) {
  if (+shape.getAttribute('stroke-width') === 0) {
    const isDot = shape.tagName === 'circle';
    const width = arrowWidth(options.print);
    const midpoint = width / 2;
    const r = isDot ? +shape.getAttribute('r') : midpoint;
    const isCircle = isDot && r > (options.print ? 1.25 : 3);

    if (shapeX - r <= chart.plotLeft) {
      const cy = shape.getAttribute('cy');
      shape.setAttribute('display', 'none');
      const className = getShapeClassName(isCircle, isDot);
      const attr = { class: className, zIndex: 4 };

      if (isDot) {
        chart.renderer.circle(shapeX, cy, r).attr(attr).add();
      } else {
        // arrow
        const height = getHeight(width, midpoint);
        const path = [
          'M',
          shapeX,
          shapeY,
          'L',
          shapeX + midpoint,
          shapeY,
          'L',
          shapeX,
          shapeY + height,
          'L',
          shapeX - midpoint,
          shapeY,
          'z',
        ];

        chart.renderer.path(path).attr(attr).add();
      }
    }
  }
}

function getShapeClassName(isCircle, isDot) {
  if (isCircle) return 'annotations-circle';
  if (isDot) return 'annotations-dot';
  return 'custom-annotations-arrow';
}

function getShapeX(shape) {
  return shape.tagName === 'circle' ? +shape.getAttribute('cx') : getArrowPosition(shape).x;
}

function isJointed(labelX, shapeX, labelWidth) {
  return Math.abs(labelX - shapeX) >= labelWidth / 2;
  // draw jointed line only if shapeX outside label width
  // return Math.abs(labelX - shapeX+20) >= labelWidth / 2;
}

function getLabelY(label) {
  // line top positioned below label
  const { y } = parseTransform(label);
  const labelHeight = getElHeight(label);
  // shpuld be 2.5px higher according to print specs
  return y + labelHeight + 2.5;
}

/**
 * a utility function that finds visible top rectangle
 * @param {{series:[]}} chart
 * @param {number} currentCategory
 * @returns {{graphic: SVGElement} }
 */
const findTopSeries = (chart, currentCategory) => {
  const TRANSPARENT = 'rgba(0, 0, 0, 0)';
  // eslint-disable-next-line no-restricted-syntax
  for (const series of chart.series) {
    const topPoint = series.data.find(
      (point) => point.x === currentCategory && point.graphic && point.options.color !== TRANSPARENT
    );
    if (topPoint) return topPoint;
  }
  console.error(`There are no graphic elements in your series, ${chart.series}`);
};

function adjustShapeY(options, shape, gridLine, chart, i) {
  let shapeY;
  // for stacked column charts always end path at the top of the column
  if (options.type === 'column' && options.stacking) {
    const shapeObject = chart.annotations[0].userOptions.shapes[i];
    const currentCategory = shapeObject.point?.x ?? shapeObject.points[0]?.x;
    const topSeries = findTopSeries(chart, currentCategory);

    shapeY =
      +topSeries.graphic.element.getAttribute('y') + topSeries.graphic.parentGroup.translateY;
    if (shape.tagName === 'path' && shape.getAttribute('d')) {
      const shapeD = shape.getAttribute('d').split(' ');
      shapeD[shapeD.length - 1] = shapeY;
      shape.setAttribute('d', shapeD.join(' '));
    }
  } else
    shapeY = shape.tagName === 'circle' ? +shape.getAttribute('cy') : getArrowPosition(shape).y;
  if (!shapeY) {
    // check whether category enabled
    // const xAxisPathPoints = highchart.axes[0].axisLine.d.split(' ');
    const xAxisPathPoints = gridLine.getAttribute('d').split(' ');

    shapeY = +lastItem(xAxisPathPoints); // line extends to xAxis
  }
  return adjustLineEnd(options, shape, shapeY);
}

function lastItem(arr) {
  return arr[arr.length - 1];
}

function adjustLineEnd(options, shape, shapeY) {
  // for arrow/circle line ends, shorten line for proper intersection with shape
  let offsetY = 0;
  const radius = +shape.getAttribute('r');
  const isDot = shape.tagName === 'circle';
  if (isDot) {
    const type = getDotLineEnd(options, radius);
    shape.classList.add(`annotations-${type}`);
    offsetY = +shape.getAttribute('r');
  } else if (shape.tagName === 'path') {
    // arrow
    if (shape.getAttribute('marker-end')) {
      shape.classList.add(`annotations-arrow`);
      offsetY = findOffset(options);
    }
  }
  return shapeY - offsetY;
}

function getDotLineEnd(options, radius) {
  if (hasVideoGroup(options.id)) return radius > 6.5 ? 'dot' : 'circle';
  return radius > (options.print ? 1.25 : 3) ? 'circle' : 'dot';
}
function renderPath(renderer, options, path, dotted, pathGroup, i) {
  const { print, id } = options;
  const strokeWidth = getStrokeWidth(print, dotted);
  const gap = print ? 3 : 5;
  const dashArray = dotted ? `0.1,${gap}` : undefined;

  renderer
    .path(path)
    .attr({
      class: 'annotations-path',
      stroke: id === 'video' || id === 'twitterVideo' || id === 'verticalVideo' ? '#fff' : '#333',
      'stroke-width': strokeWidth,
      'stroke-dasharray': dashArray,
      'stroke-linecap': 'round',
      zIndex: i,
    })
    .add(pathGroup);
}

function getStrokeWidth(print, dotted) {
  if (print && dotted) return 1.5;
  if (print && !dotted) return 0.5;
  if (!print && dotted) return 2.5;
  return 1;
}

function getPathStart(moveX, labelY, shapeX, shapeY) {
  // top, bottom of line
  return [['M', moveX, labelY], [], ['L', shapeX, shapeY]];
}

function hideAnnotation(...args) {
  args.forEach((arg) => arg.setAttribute('display', 'none'));
}

function getProps(item) {
  // get properties set in prerender
  const { flushAlign, showLine, lineType } = item;
  return [
    !(flushAlign === 'default'),
    showLine === true || showLine === undefined,
    lineType === 'dotted',
  ];
}

function getArrowPosition(path) {
  // get arrow's x, y
  const d = path.getAttribute('d').split(' ');
  return { x: +d[4], y: +d[5] };
}

// function initialJointY(container, margin, jointRight) {
//   console.log('container', container)
//   const yAxisLabels = container.querySelector('.highcharts-yaxis-labels');
//   const textsY = yAxisLabels.querySelectorAll('text');
//   const lastText = textsY[textsY.length-1];
//   const plotTop = lastText.getAttribute('y');
//   const divisor = jointRight ? 1 : 2;
//   return plotTop + margin / divisor;
// }
