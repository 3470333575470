import moment from 'moment';

export const MORE_TICKERS = {
  'Oil Futures': [
    { name: 'Crude Oil', symbol: 'Crude%20Oil%20-%20Electronic' },
    { name: 'Brent Crude', symbol: 'BRENT%20CRUDE' },
  ],
  Currencies: [
    { name: 'USD/EUR', symbol: 'USDEUR' },
    { name: 'USDJPY', symbol: 'USDJPY' },
    { name: 'WSJ DollarIdx', symbol: 'XX:BUXX' },
  ],
  Bonds: [{ name: '10 Year Yield', symbol: 'BX:TMUBMUSD10Y' }],
  'Foreign Indexes': [
    { name: 'Stoxx Europe 600', symbol: 'XX:SXXP' },
    { name: 'Nikkei IDX', symbol: 'JP:NIK' },
    { name: 'HANG SENG INDEX', symbol: 'HK:HSI' },
    { name: 'Shanghai Comp', symbol: 'CN:SHCOMP' },
    { name: 'Shenzhen', symbol: 'CN:399106' },
  ],
};

export const RANGE = {
  '1D': { value: 'D1', start: 'PT1M', end: 'PT1H', default: 'PT5M' },
  '2D': { value: 'D2', start: 'PT1M', end: 'PT1H', default: 'PT5M' },
  '3D': { value: 'D3', start: 'PT1M', end: 'PT1H', default: 'PT5M' },
  '4D': { value: 'D4', start: 'PT1M', end: 'PT1H', default: 'PT10M' },
  '5D': { value: 'D5', start: 'PT1M', end: 'P1D', default: 'PT10M' },
  '10D': { value: 'D10', start: 'PT1M', end: 'P1D', default: 'PT15M' },
  '1M': { value: 'P29D', start: 'P1D', end: 'P7D', default: 'P1D' },
  '3M': { value: 'P3M', start: 'P1D', end: 'P1M', default: 'P1D' },
  '6M': { value: 'P6M', start: 'P1D', end: 'P3M', default: 'P1D' },
  YTD: { value: 'YTD', start: 'P1D', end: 'P1M', default: 'P1D' },
  '1Y': { value: 'P1Y', start: 'P1D', end: 'P3M', default: 'P1D' },
  '2Y': { value: 'P2Y', start: 'P1D', end: 'P1Y', default: 'P7D' },
  '3Y': { value: 'P3Y', start: 'P1D', end: 'P1Y', default: 'P7D' },
  '5Y': { value: 'P5Y', start: 'P1D', end: 'P1Y', default: 'P1M' },
  '10Y': { value: 'P10Y', start: 'P1D', end: 'P1Y', default: 'P3M' },
  All: { value: 'all', start: 'P1D', end: 'P1Y', default: 'P3M' },
  Custom: { value: 'custom' },
};

export const FREQUENCY = [
  { value: 'PT1M', text: '1 min' },
  { value: 'PT5M', text: '5 min' },
  { value: 'PT10M', text: '10 min' },
  { value: 'PT15M', text: '15 min' },
  { value: 'PT1H', text: 'Hourly' },
  { value: 'P1D', text: 'Daily' },
  { value: 'P7D', text: 'Weekly' },
  { value: 'P1M', text: 'Monthly' },
  { value: 'P3M', text: 'Quarterly' },
  { value: 'P1Y', text: 'Yearly' },
];

const D = 1000 * 60 * 60 * 24;
const M = D * 30;
const Y = D * 365;

export const TIMETYPES = { D, M, Y };

export const dataDefaults = {
  markdown: '{series}',
  showInLegend: true,
  line: 'solid',
};

export const seriesDefaults = {
  firstRowAsNames: false,
  firstColumnAsCategories: false,
  categoriesAreDates: true,
};

export const PRESETS = {
  'DJIA + SPX + COMP': {
    prop: 'marketDataPreset',
    propsToUpdate: ['tickers', 'dateRange', 'isRolling', 'frequency'],
    subdek: 'Index performance',
    seriesNames: {
      DJIA: 'Dow industrials',
      SPX: 'S&P 500',
      COMP: 'Nasdaq Composite',
    },
    isRolling: true,
    isPercent: true,
    frequency: 'PT10M',
    dateRange: '3D',
    majorTicks: 'month',
    minorTicks: 'date',
    presetSuffix: '%',
  },
};
