/**
 * Datetime is a module with methods that parse date strings into discrete
 * components (years, months, hours, etc).
 * TODO: Write unit tests.
 */
import { stripZeroes, addZeroes } from './zero';
import {
  REGEXP_QUARTER,
  REGEXP_Q_ONLY,
  REGEXP_MDY,
  REGEXP_MDY_FRED,
  REGEXP_MD,
  REGEXP_MY,
  REGEXP_Y,
  REGEXP_TIME
} from './constants';
import {
  fullYear,
  abbrYear,
  isSameCentury,
  getQuarter,
  getHalf,
  quarterToMonth,
  quarterToDate,
  genericTimeValue
} from './dateutils.js';
import * as quarterly from './quarterly';
import assign from 'lodash.assign';



// let DEBUG = false;

/**
 * Converts/parses an array of category strings into objects with date and
 * time properties.
 * TODO: explains @params
 * @returns {datetimeTemplate[]}
 */
function parse(categories, options) {
  let dates = [];
  let timezone = null;
  const category0 = categories[0].toString();
  const isQuarterlyString = !!category0.match(REGEXP_Q_ONLY);
  categories.forEach((category, index) => {
    dates[index] = components(category, timezone, options, isQuarterlyString);
    timezone = dates[index].z;
  });

  if (options) {
    dates = options.fiscalYears ? quarterly.makeFiscalYears(dates) :
      quarterly.removeFiscalYears(dates)
    dates = options.quarterlyResults ? quarterly.makeQuarterly(dates) :
      quarterly.removeQuarterly(dates);
  }

  dates = quarterly.normalize(dates);
  dates = generateValues(dates);
  dates = removeExtraneousComponents(dates);
  return dates;
}


/**
 * Breaks a single category value into an object with date/time components.
 */
function components(value, timezone, options, isQuarterlyString) {
  value += '';
  const result = datetimeTemplate();
  componentsDate(value, result, options, isQuarterlyString);
  componentsTime(value, result, timezone);
  if (!result.hour) {
     const newDate = new Date(result.year, result.month, result.date, 0, 0, 0, 0).getTime();
     result.value = newDate;
  }
  return result;
}


/**
 * @private
 * For use with components().
 */
function componentsDate(value, result, options, isQuarterlyString = false) {
  let match = null;
  if (isQuarterlyString) {
    match = value.match(REGEXP_QUARTER);
    match = match || value.match(REGEXP_Q_ONLY) || [];
    result.quarter = getQuarter(match[1]);
    result.half = getHalf(match[1]);
    result.month = quarterToMonth(match[1]);
    result.date = quarterToDate(result.month);
    result.fiscalYear = !!match[2];
    result.year = match[3] ? +match[3] : undefined;

  } else if (match = value.match(REGEXP_MDY)) {
    writeDateMonth(result, match, options.reverseDateMonth);
    result.year = +fullYear(match[3]);

  } else if (match = value.match(REGEXP_MDY_FRED)) {
    result.year = +match[1];
    result.month = Math.max(0, +match[2] - 1);
    result.date = +match[3];

  } else if (match = value.match(REGEXP_MD)) {
    writeDateMonth(result, match, options.reverseDateMonth);

  } else if (match = value.match(REGEXP_MY)) {
    result.month = +match[1] - 1;
    result.year = +fullYear(match[2]);

  } else if (match = value.match(REGEXP_Y)) {
    result.year = +fullYear(match[1]);
  }
}

function writeDateMonth(result, match, reverseDateMonth) {
  if (reverseDateMonth) {
    result.month = +match[2] - 1;
    result.date = +match[1];
  } else {
    result.month = +match[1] - 1;
    result.date = +match[2];
  }
}


/**
 * @private
 * For use with components().
 */
function componentsTime(value, result, timezone = undefined) {
  const [date, time, ampm] = value.split(' ');
  if (!time) return result;
  const [hours, minutes, seconds] = time.split(':');
  if (!minutes) return result;
  result.hour = +hours;
  result.minute = +minutes;
  result.second = +seconds;
  result.a = ampm;
  result.hour %= 12;
  if (result.a === 'p') {
    result.hour += 12;
  }
}


/**
 * @param {datetimeTemplate[]} dates
 * @param {string} prop
 * @return {boolean} true if all dates have a value for prop.
 */
function allHave(dates, prop) {
  return dates.length !== 0 && !dates.some(date => {
    return (prop === 'fiscalYear' && !date.fiscalYear) ||
        date[prop] === undefined;
  });
}


/**
 * @param {datetimeTemplate[]} dates
 * @returns {boolea} true if all date objects represent valid dates.
 */
function allValidDates(dates) {
  return !dates.some(d => {
    return d.second === undefined &&
      d.minute === undefined &&
      d.hour === undefined &&
      d.date === undefined &&
      d.month === undefined &&
      d.year === undefined;
  });
}


/**
 * @param {datetimeTemplate[]} dates
 * @returns {object} with true/false for each date/time property depending
 *   on whether all dates have a value for each property.
 */
function all(dates) {
  let obj = datetimeTemplate();
  Object.keys(obj).forEach(key => {
    obj[key] = allHave(dates, key);
  });
  return obj;
}


/**
 * @param {datetimeTemplate[]} dates
 * @returns {boolean} true if all dates have a value for `quarter` or `half`.
 */
function allQuarterly(dates) {
  return dates.every(item => {
    return item.quarter || item.half;
  });
}


/**
 * @returns {object} with keys for all the properties useful in the charts
 *   tool, each set to undefined or false.
 */
function datetimeTemplate() {
  return {
    z: undefined,
    a: undefined,
    second: undefined,
    minute: undefined,
    hour: undefined,
    date: undefined,
    month: undefined,
    year: undefined,
    value: undefined,
    fiscalYear: false,
    quarter: undefined,
    half: undefined
  };
}


/**
 * Remove values that repeat for all dates and therefore are superfluous.
 * @private
 * @param {datetimeTemplate[]} dates
 * @returns {datetimeTemplate[]} Copy of dates if altered; original dates if
 *    unaltered.
 */
function removeExtraneousComponents(dates) {
  var common = all(dates);
  if (common.month && common.date) {// && !common.year) {
    dates = removeExtraneous(dates, 'date');
  }
  if (common.month && common.year) {
    dates = removeExtraneousMD(dates);
  }
  if (common.hour && common.minute) {
    dates = removeExtraneous(dates, 'minute');
  }
  if (common.minute && common.second) {
    dates = removeExtraneous(dates, 'seconds');
  }
  return dates;
}

/**
 * Often dates are in the format Jan 1 YYYY, where Jan 1 repeats for *all*
 * rows but the years increment. In this special case, the month and date
 * should be disregarded. Also works if months but no dates are specificed;
 * e.g. 1/2000, 1/2001, etc.
 * @private
 * @param {datetimeTemplate[]} dates
 * @returns {datetimeTemplate[]} Copy of dates if altered; original dates if
 *    unaltered.
 */
function removeExtraneousMD(dates) {
  let datesCopy = [];
  let month = undefined;
  let date = undefined;
  let year = undefined;
  for (var i = 0; i < dates.length; i++) {
    var d = assign({}, dates[i]);
    if (i === 0) {
      month = d.month;
      date = d.date;
      year = d.year;
    } else if (d.month !== month || d.date !== date) {
      return dates;
    } else if (d.year <= year) {
      return dates;
    }
    year = d.year;
    d.month = d.date = undefined;
    datesCopy[i] = d;
  }
  return datesCopy;
}


/**
 * Removes values (such as seconds, minutes, or dates) when the values are the
 * same for each and every date.
 * @private
 * @param {datetimeTemplate[]} dates
 * @returns {datetimeTemplate[]} Copy of dates if altered; original dates if
 *    unaltered.
 */
function removeExtraneous(dates, prop) {
  let datesCopy = [];
  let propVal = undefined;
  let value = undefined;
  const genericVal = genericTimeValue(prop);
  for (let i = 0; i < dates.length; i++) {
    var d = assign({}, dates[i]);
    if (i === 0) {
      // Set initial values to compare to.
      propVal = d[prop];
      value = d.value;
    } else if (Math.abs(d.value - value) < genericVal) {
      // Values do not increment.
      return dates;
    } else if (d[prop] !== propVal) {
      // Values are not all the same and therefor not extraneous.
      return dates;
    }
    value = d.value;
    d[prop] = undefined;
    datesCopy[i] = d;
  }
  return datesCopy;
}

/**
 * Sets a value in milliseconds for each datetime object.
 * @private
 * @param {datetimeTemplate[]} dates
 * @returns {datetimeTemplate[]}
 */
function generateValues(dates) {
  dates.forEach(date => {
    date.value = getValue(date);
  });
  return dates;
}

/**
 * @private
 * @param {datetimeTemplate}
 * @returns {number} value in milliseconds
 */
function getValue(date) {
  const d = new Date(new Date().getUTCFullYear(), 0, 1, 0, 0, 0, 0);

  if (isDef(date.year) && isDef(date.month) && isDef(date.date)) {
    d.setUTCFullYear(date.year, date.month, date.date);
  } else if (isDef(date.year) && isDef(date.month)) {
    d.setUTCFullYear(date.year, date.month);
  } else if (isDef(date.year)) {
    d.setUTCFullYear(date.year, 0);
  } else if (isDef(date.month) && isDef(date.date)) {
    d.setUTCMonth(date.month, date.date);
  } else if (isDef(date.month)) {
    d.setUTCMonth(date.month);
  } else if (isDef(date.date)) {
    d.setUTCDate(date.date);
  }

  if (isDef(date.hour) && isDef(date.minute) && isDef(date.second)) {
    d.setUTCHours(date.hour, date.minute, date.second);
  } else if (isDef(date.hour) && isDef(date.minute)) {
    d.setUTCHours(date.hour, date.minute);
  } else if (isDef(date.hour)) {
    d.setUTCHours(date.hour);
  } else if (isDef(date.minute) && isDef(date.second)) {
    d.setUTCMinutes(date.minute, date.second);
  } else if (isDef(date.minute)) {
    d.setUTCMinutes(date.minute);
  } else if (isDef(date.second)) {
    d.setUTCSeconds(date.second);
  } else {
    d.setUTCHours(0, 0, 0);
  }

  return d.valueOf();
}

function isDef(value) {
  return value !== undefined;
}

// Not used in this module but is used by the app/client.
// @returns {boolean} true is @param str is a quartlery date value.
function isQuarterlyString(str) {
  return REGEXP_Q_ONLY.test(str);
}


export default {
  parse,
  components,
  allHave,
  all,
  allQuarterly,
  stripZeroes,
  addZeroes,
  allValidDates,
  datetimeTemplate,
  isQuarterlyString
};