// TODO: see if this file can be removed after transition to the new engine
export const japaneseRegex = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/gu;
export const japaneseFont = 'Noto Sans CJK JP, sans-serif';
export const japaneseRecessionLabel = '景気後退';
export const checkJapanese = (text) => {
  return japaneseRegex.test(text);
}
export function japaneseCharacterCheck(svgString) {
  let input = svgString;
  if (checkJapanese(input)) {
    input = input.split(/text/g);
    const inputArr = [];
    for(let i=0; i<input.length; i++){
      let text = input[i];
      if (checkJapanese(text)) {
        const fontRegex = /RetinaLight|RetinaMedium|RetinaNarrowLight|Retina Narrow Light|Retina Narrow|Retina Light|Retina/g;
        if(fontRegex.test(text)){
          text = text.replace(fontRegex, japaneseFont);
        } else {
          let prevText = inputArr[i-1];
          if(prevText) inputArr[i-1] = prevText.replace(fontRegex, japaneseFont)
        }
        inputArr.push(text)
      } else {
        inputArr.push(text)
      }
    }
    input = inputArr.join('text');
    return input;
  } else {
    return false;
  }
}

export function japaneseURLFont() {
  return '\n@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;700&display=swap");\n@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap");\n@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..900&display=swap");';
}
