// @ts-check
import * as legend from './legend/index';
import * as axes from './axes/index';
import * as annotations from './annotations/index';
// import * as pie from './chart/pie';
import { hasVideoGroup, findYLabels, hasThumb, hasSmallMW, hasZT } from './utils';

/**
 * Fires when the chart is finished loading.
 * Contains everything that is not handled natively by Highcharts
 * @param {Highcharts.Chart} chart
 * @param {Object} options chartlos options
 */
export const loadDigital = (chart, options) => {
  const smallCrops = hasThumb(options.id) || hasSmallMW(options.id);
  if (smallCrops) return;

  if (options.type === 'pie') {
    if (options?.legend?.enabled && options?.legend?.layout === 'bottom') {
      legend.positionBottomLegend(chart);
    }
    return;
  }

  const isZT = hasZT(options.size);
  // ticks are not sorted for some reason
  if (options.type !== 'scatter') {
    chart.xAxis[0].tickPositions.sort((a, b) => a - b);
    chart.yAxis[0].tickPositions.sort((a, b) => a - b);
  }

  if (!isZT) legend.positionRightAlignedLegend(chart, options);

  // find yAxis labels for all charts except aboveBar & noScaleAndLeft
  const yAxisLabels =
    options.type !== 'bar' || (options.type === 'bar' && options.horizontalBarType === 'normal')
      ? findYLabels(chart.yAxis[0].ticks)
      : null;
  // logic for scatter
  // if (options.type === 'scatter') {
  //   const { suffix } = options.axes.units;
  //   setZeroLine(chart.yAxis[0].ticks, false);
  //   setZeroLine(chart.xAxis[0].ticks, false);
  //   const label = getSuffixLabel(chart.yAxis[0].ticks, options);
  //   if (suffix) {
  //     appendSuffix(options, chart.yAxis[0].labelGroup, suffix, label, chart.renderer, chart);
  //   } else if (checkJapanese(label.textContent)) {
  //     setCJKId(chart.yAxis[0].labelGroup, options);
  //   }
  //   return;
  // }

  const isVideoGroup = hasVideoGroup(options.id);
  const isAboveBar = options.type === 'bar' && options.horizontalBarType === 'aboveBar';
  if (isAboveBar) {
    /*
    category labels are positioned in the middle of bar by default
    to place them 'above bar'
    calculate delta bw first bar and first label, add padding (5),
    and shift label group container delta pxs up
    */
    const seriesG = chart.seriesGroup.element.querySelector('g');
    const transformG = seriesG.getAttribute('transform');
    const translateY = +transformG.slice(transformG.indexOf(',') + 1, transformG.indexOf(')'));
    const firstRect = seriesG.querySelector('rect');
    const barX = +firstRect.getAttribute('x');
    const barHeight = +firstRect.getAttribute('width');
    const barTop = translateY - barX - barHeight - (isVideoGroup ? 5 : 3);
    const categoryLabel = chart.xAxis[0].labelGroup.element.querySelector('text');
    const labelTop = +categoryLabel.getAttribute('y');
    const delta = labelTop - barTop;
    chart.xAxis[0].labelGroup.element.setAttribute('transform', `translate(0, -${delta})`);
  }
  // extend gridlines and then draw baseline
  axes.setGridLineStartAndSuffix(chart, options);
  if (!options.logScale) axes.drawZeroLine(chart, options);
  if (options.type === 'scatter') {
    axes.alignXAxisLabels(chart, options);
    return;
  }

  annotations.onLoad(chart, options, yAxisLabels);

  if (isZT) return;

  axes.alignXAxisLabels(chart, options);
  if (options.type === 'bar') return;

  axes.shortenMinorTicks(chart, options);
};
