// @ts-check

// x-axis
/**
 * Calculates the distance of
 * the axis title from the axis line
 * based on the crop size
 * @see https://api.highcharts.com/highcharts/xAxis.title.offset
 * @param {boolean} isPrint - whether crop is print
 * @param {boolean} isVideo - whether crop is video
 * @returns {number}
 */
export const xAxisTitleOffset = (isPrint, isVideo) => {
  if (isPrint) return 13;
  return isVideo ? 50 : 25;
};

// y-axis
/**
 * Calculates the distance in pixels
 * from the plot area to the axis line
 * @see https://api.highcharts.com/highcharts/yAxis.offset
 * @param {boolean} isVideo - whether crop is video
 * @returns {number}
 */
export const yAxisOffset = (isVideo) => (isVideo ? 15 : 0);

/**
 * Calculates the distance of
 * the axis title from the axis line
 * @see https://api.highcharts.com/highcharts/yAxis.title.offset
 * @param {boolean} isVideo - whether crop is video
 * @returns {number}
 */
export const yAxisTitleOffset = (isVideo) => (isVideo ? 80 : 40);

// TODO: create constant with possible dash styles
export const yAxisGridLineDashStyle = 'solid';

const LINEAR = 'linear';
const LOGARITHMIC = 'logarithmic';

export { LINEAR, LOGARITHMIC };
