/**
 * Bring addZeroes and stripZeroes function directly into dateutils to 
 * expose for testing
 */

import { addZeroes, stripZeroes } from './zero.js';

/**
 * Transform a partial year value (84) into a full year value (1984)
 * according to a formula agreed upon at WSJ.
 * @param {number|string} value
 * @returns {number}
 */

export function fullYear(value) {
  value = +value;
  if (value < 100) {
    if (value + 2000 > (new Date().getUTCFullYear() + 15)) {
      value += 1900;
    } else {
      value += 2000;
    }
  }
  return value;
}


/**
 * Transforms a year value into 2-digit number: 1984 to 84.
 * @param {number|string} value
 * @returns {string} of length 2
 */
export function abbrYear(value) {
  value = addZeroes(stripZeroes(value), 2);
  return value.substr(-2, 2);
}


/**
 * @param {number|string} year1
 * @param {number|string} year2
 * @returns {boolean} true if y1 and y2 represent years in the same century.
 */
export function isSameCentury(year1, year2) {
  const y1 = addZeroes(fullYear(year1), 4);
  const y2 = addZeroes(fullYear(year2), 4);
  return y1.substr(0, 2) === y2.substr(0, 2);
}



/**
 * @private
 * @param {string} str
 * @returns {string|undefined} if str is a Quartar ("2Q") or else undefined.
 */
export function getQuarter(str) {
  if (typeof str === 'string') {
    str = str.toUpperCase();
    return (str.indexOf('Q') !== -1) ? str : undefined;
  }
  return undefined;
}


/**
 * @private
 * @param {string} str
 * @returns {string|undefined} if str is a Half ("2H") or else undefined.
 */
export function getHalf(str) {
  if (typeof str === 'string') {
    str = str.toUpperCase();
    return (str.indexOf('H') !== -1) ? str : undefined;
  }
  return undefined;
}


/**
 * For a given quarter, returns the index of the month that represents the end
 * of that quarter.
 * @private
 * @param {number} q Index of a quarter, 0 to 3.
 * @returns {number} Index of month, 2, 4, 8, or 11.
 */
export function quarterToMonth(q) {
  q = (typeof q === 'string') ? q.toUpperCase() : null;
  switch (q) {
    case '1Q':
      return 2;
    case '2Q':
    case '1H':
      return 5;
    case '3Q':
      return 8;
    case '4Q':
    case '2H':
      return 11;
  }
  return undefined;
}


/**
 * For a given month that represents the end of a quarter, returns the date
 * that represents the end of that quarter.
 * @private
 * @param {number} month Index of a month, 0 to 11.
 * @returns {number} Date of the month, 1 to 31.
 */
export function quarterToDate(month) {
  switch (month) {
    case undefined:
    case null:
      return undefined;
    case 1:
      return 28;
    case 3:
    case 5:
    case 8:
    case 10:
      return 30;
    default:
      return 31;
  }
}



/**
 * For use with removeExtraneous().
 * @private
 * @param {string} prop 'second', 'minute', etc.
 * @returns {number} in milliseconds
 */
export function genericTimeValue(prop) {
  switch (prop) {
    case 'second':
      return 1000 * 60;
    case 'minute':
      return 1000 * 60 * 60;
    case 'hour':
      return 1000 * 60 * 60 * 60;
    case 'date':
      return 1000 * 60 * 60 * 24;
  }
  return 0;
}