// import * as format from '../../data/format';
/**
 * Adds options for pie charts.
 * @see https://api.highcharts.com/highcharts/plotOptions.pie
 * @param {Object} data
 * @param {ChartlosOptions} options
 * @returns {Object} updated data with added pie options
 */
export function preRender(data, options) {
  data.plotOptions.pie = {
    innerSize: '45%',
    size: '90%',
    dataLabels: {
      enabled: false,
    },
    showInLegend: true,
  };

  return data;
}
