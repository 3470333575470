// @ts-check
import {
  VIDEO_GROUP_REGEX,
  VIDEO_REGEX,
  TWITTER_VIDEO_REGEX,
  ARTICLE_CROPS_REGEX,
  WSJ_PROMO_CROPS_REGEX,
  BARRONS_PROMO_CROPS_REGEX,
  MARKETWATCH_PROMO_CROPS_REGEX,
  MANSION_GLOBAL_PROMO_CROPS_REGEX,
  FNLONDON_PROMO_CROPS_REGEX,
  PRINT_CROPS_REGEX,
  WSJ_PRINT_CROPS_REGEX,
  SMALL_MW_REGEX,
  ZT_MW_REGEX,
  THUMB_REGEX,
} from './constants/regex';

const regexTest = (regex, id) => regex.test(id);
// crops util
/**
 * checks whether crop is print
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasPrint = (id) => regexTest(PRINT_CROPS_REGEX, id);
/**
 * checks whether crop is wsj print
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasWsjPrint = (id) => regexTest(WSJ_PRINT_CROPS_REGEX, id);
/**
 * checks whether crop is article
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasArticleCrop = (id) => regexTest(ARTICLE_CROPS_REGEX, id);
/**
 * checks whether crop is promo
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasPromoCrop = (id) =>
  regexTest(WSJ_PROMO_CROPS_REGEX, id) ||
  regexTest(BARRONS_PROMO_CROPS_REGEX, id) ||
  regexTest(MARKETWATCH_PROMO_CROPS_REGEX, id) ||
  regexTest(MANSION_GLOBAL_PROMO_CROPS_REGEX, id) ||
  regexTest(FNLONDON_PROMO_CROPS_REGEX, id);
/**
 * checks whether crop is video|twitterVideo|verticalVideo
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasVideoGroup = (id) => regexTest(VIDEO_GROUP_REGEX, id);
/**
 * checks whether crop is video
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasVideoCrop = (id) => regexTest(VIDEO_REGEX, id);
/**
 * checks whether crop is twitter video
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasTwitterVideoCrop = (id) => regexTest(TWITTER_VIDEO_REGEX, id);
/**
 * checks whether crop one of mw-MA | mw-ZR | mw-ZT
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasSmallMW = (id) => regexTest(SMALL_MW_REGEX, id);
/**
 * checks whether crop mw-ZT
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasZT = (id) => regexTest(ZT_MW_REGEX, id);
/**
 * checks whether crop is thumb
 * @param {string} id - crop size
 * @returns {boolean}
 */
export const hasThumb = (id) => regexTest(THUMB_REGEX, id);

// chart engine
/**
 * Draw and return an svg group element
 * @export
 * @param {Highcharts.SVGRenderer} renderer
 * @param {string} className
 * @param {Highcharts.SVGElement} [parentGroup=null]
 * @return {Highcharts.SVGElement}
 */
export const createGroup = (renderer, className, parentGroup = null) =>
  renderer.g().attr({ class: className }).add(parentGroup);
/**
 * Draw and return a path
 * @export
 * @param {Highcharts.SVGRenderer} renderer
 * @param {[]} path
 * @param {Object} attr
 * @param {Highcharts.SVGElement} [parentGroup=null]
 * @return {Highcharts.SVGElement}
 */
export const createPath = (renderer, path, attr, parentGroup = null) =>
  renderer.path(path).attr(attr).add(parentGroup);

/**
 * Draw and return text
 * @param {Highcharts.SVGRenderer} renderer - The renderer instance of the chart
 * @param {string} labelText - The text of (subset) HTML to draw
 * @param {number} x - The x position of the text's lower left corner
 * @param {number} y - The y position of the text's lower left corner
 * @param {string} className class name for the label
 * @param {Object} css - A style object with camel case property names to define visual appearance of a SVG element or HTML element
 * @return {Highcharts.SVGElement} text element
 */
export const createLabel = (renderer, labelText, x, y, className, css) =>
  renderer.text(labelText, x, y).attr({ class: className }).css(css).add();

// gridline util
/**
 * Returns bottom y-axis gridline element
 * @param {Highcharts.Dictionary<Highcharts.Tick>} yTicks
 * @returns {HTMLElement|SVGElement} - bottom y-axis gridline
 */
export const getFirstGridLine = (yTicks) => {
  for (const tick of Object.keys(yTicks)) {
    if (yTicks[tick].isFirst) {
      return yTicks[tick].gridLine.element;
    }
  }
};
/**
 * Sets path starting point to zero
 * @export
 * @param {SVGPathElement} path
 */
export const extendGridLine = (path) => {
  const d = path.getAttribute('d');
  if (d) {
    const dArr = d.split(' ');
    dArr[1] = '0';
    const newD = dArr.join(' ');
    path.setAttribute('d', newD);
  }
};
/**
 * Utility function that looks for elements such as "g|path|text" in the specified group
 * @param {Highcharts.SVGElement} group
 * @param {string} type
 * @returns {NodeList}
 */
export const findEls = (group, type) => group?.element.querySelectorAll(type);

export const findYLabels = (yTicks) => {
  const ticks = Object.keys(yTicks);
  return ticks.map((tick) => {
    if (yTicks[tick].label) return yTicks[tick].label.element;
  });
};

/**
 * Utility function that returns whether this chart is from WSJ Video
 * @returns {boolean}
 */
export const hasWSJVideoGroup = (options) => hasVideoGroup(options.id) && options.product === 'wsj';

// TODO: not all tspans have a 'highcharts-br' class, find a better way
function setDy(tspans, dy) {
  tspans.forEach((tspan) => {
    const hasLineBreak = tspan.className?.baseVal?.includes('highcharts-br');
    if (hasLineBreak) tspan.setAttribute('dy', dy);
  });
}

export { setDy };
