// @ts-check
import * as format from '../../data/format';
import { TRANSPARENT } from '../../data/constants';
import { isTooltipEnabled } from './tooltips.utils';
import { hasWSJVideoGroup } from '../utils';

/**
 * Set proper tooltip settings
 * Tooltip is enabled if crop size is interactive
 * (rules can be found in src/ui/constants/target-wsj.js)
 * and if a user intentionally not disabled the setting
 * (options.showTooltips).
 * However, tooltip is always disabled for 'above bar`
 * and 'left of bar/no scale' horizontal bar charts
 * @see https://api.highcharts.com/highcharts/tooltip
 * @param {Object} data - config options to pass to highcharts
 * @param {ChartlosOptions} options - chartlos options
 * @returns {Object} mutable data object
 */

export function preRender(data, options) {
  data.tooltip = { enabled: isTooltipEnabled(options) };
  const { tooltip } = data;
  if (tooltip.enabled) {
    tooltip.borderWidth = 1;
    tooltip.split = false;
    tooltip.shared = false;

    tooltip.shadow = false;
    tooltip.hideDelay = options.type === 'line' || options.type === 'area' ? 500 : 5000;
    tooltip.formatter = function () {
      return formatter(data, options, this);
    };
  }

  if (options.product === 'wsj') {
    tooltip.borderRadius = 0;
    tooltip.padding = 5;
  }

  return data;
}

function formatter(data, options, context) {
  // hide tooltip for transparent data
  if (context.color === TRANSPARENT) return false;
  if (options.type === 'pie') {
    const value = format.applyFull(context.y, options.tooltips);
    return formatStandard(options, context, {
      category:
        hasWSJVideoGroup(options) && typeof context.key === 'string'
          ? context.key.toUpperCase()
          : context.key,
      value: hasWSJVideoGroup(options) && typeof value === 'string' ? value.toUpperCase() : value,
      index: context.colorIndex,
    });
  }

  const {
    needsCommas,
    liveMarketData,
    isPercent,
    isIntraday,
    series,
    longCategories,
    tooltips,
    type,
    categoriesAreDates,
    stacking,
    axes,
  } = options;

  const categories = longCategories || (options.categories ? options.categories[0] : null);

  let category;
  const marketNotBar = liveMarketData && type !== 'bar';
  if (marketNotBar) {
    const catIdx = series[0].data.findIndex(({ x }) => x === context.x);
    category = formatCategory(categories[catIdx]);
  }

  const { decimals, prefix, suffix } = context.series.userOptions;
  const tooltipY = getTooltipY(axes, tooltips, context.point.y);
  const isStackedPercent =
    (type === 'column' || type === 'area' || type === 'bar') && stacking === 'percent';
  const value = marketNotBar
    ? getValue(
        context.y,
        isPercent,
        prefix,
        suffix,
        decimals,
        needsCommas,
        hasWSJVideoGroup(options)
      )
    : format.applyFull(tooltipY, tooltips, true, true, false, isStackedPercent);

  if (!marketNotBar) {
    const firstYear = categories[0];
    const lastYear = categories[categories.length - 1];
    let isReverse = false;
    if (typeof firstYear === 'string' && typeof lastYear === 'string' && categoriesAreDates) {
      const firstYearSplit = firstYear.split(' ');
      const lastYearSplit = lastYear.split(' ');
      if (firstYearSplit.indexOf('p.m.') !== -1 && firstYearSplit.indexOf('a.m.') !== -1) {
        const firstYearTime = firstYearSplit[firstYearSplit.length - 2];
        let firstYearTimeSplit = firstYearTime.split(':');
        let hourFirstTimeSplit = parseInt(firstYearTimeSplit) + 12;
        hourFirstTimeSplit = hourFirstTimeSplit.toString();
        firstYearTimeSplit[0] = hourFirstTimeSplit;
        firstYearTimeSplit = firstYearTimeSplit.join(':');
        const lastYearTime = lastYearSplit[lastYearSplit.length - 2];
        let lastYearTimeSplit = lastYearTime.split(':');
        let hourLastTimeSplit = parseInt(lastYearTimeSplit) + 12;
        hourLastTimeSplit = hourLastTimeSplit.toString();
        lastYearTimeSplit[0] = hourLastTimeSplit;
        lastYearTimeSplit = lastYearTimeSplit.join(':');

        isReverse = firstYearTimeSplit > lastYearTimeSplit;
      } else {
        isReverse = findIfReverse(categories);
      }
    }

    if (isReverse) {
      categories.reverse();
    }
    const seriesData = !data.series ? series : data.series;
    var dataPoint = getDataPoint(seriesData, context, isReverse);
  }
  const index = dataPoint ? dataPoint.index : null;

  if (!marketNotBar) {
    category = categories
      ? categories[index]
      : context.point.category || context.point.options.name;
  } else if (options.type !== 'scatter') {
    category = category.trim();
  }

  const formatter = {
    category:
      hasWSJVideoGroup(options) && typeof category === 'string' ? category.toUpperCase() : category,
    value: hasWSJVideoGroup(options) && typeof value === 'string' ? value.toUpperCase() : value,
    index,
  };

  if (isStackedPercent) {
    return formatStackedPercent(data, options, context, formatter);
  }
  return formatStandard(options, context, formatter);
}

function formatCategory(category) {
  const month = category.match(/([A-za-z]{3,})(\s|.)/g);
  const day = category.match(/(\d{1,2}),/g);
  const year = category.match(/\d{4}/g);
  const time = category.match(/(\d{1,2}):(\d{2})|\s\d{1,2}\s/g);
  const ampm = category.match(/(a|p).m./g);
  let updatedDate = '';
  if (month) updatedDate += `${formatMonth(month)} `;
  if (day) updatedDate += `${day[0]} `;
  if (year) updatedDate += year[0];
  if (time) {
    updatedDate += ` ${time[0]} ${ampm[0]}`;
  }
  return updatedDate;
}

function formatMonth(month) {
  return month[0].slice(0, 3);
}

function getTooltipY(axes, tooltips, y) {
  if (axes.units) {
    return (format.getDivisor(axes.units.divisor) * y) / format.getDivisor(tooltips.divisor);
  }
  return y;
}

function getValue(y, isPercent, prefix, suffix, decimals, needsCommas, transformUppercase) {
  const formattedPrefix =
    transformUppercase && typeof prefix === 'string' ? prefix.toUpperCase() : prefix;
  const formattedSuffix =
    transformUppercase && typeof suffix === 'string' ? suffix.toUpperCase() : suffix;

  return isPercent
    ? `${y.toFixed(1)}%`
    : formatY(y, decimals, needsCommas, formattedPrefix, formattedSuffix);
}

function formatY(y, decimals, needsCommas, prefix, suffix) {
  y = y.toFixed(decimals);

  if (!needsCommas) return y;

  const [dollars, cents] = y.split('.');
  const digitsArr = dollars.split('').reverse();
  const commasAdded = addCommas(digitsArr);
  let finalString = prefix + formatDollars(commasAdded);
  if (cents) finalString += `.${cents}`;
  return finalString + suffix;
}

function addCommas(digits) {
  return digits.reduce((str, digit, i) => str + checkComma(i) + digit, '');
}

function checkComma(i) {
  return i % 3 === 0 && i > 0 ? ',' : '';
}

function formatDollars(digits) {
  return digits.split('').reverse().join('');
}

/**
 * Sometimes category date comes in the following format - "Aug. 11, 2023"
 * which is acceptable for chrome, but returns NaN in firefox.
 * This function simply removes the dot to avoid that.
 * @param {String} dateStr Aug. 11, 2023
 * @returns {String} Aug 11, 2023
 */

function prepDateForParsing(dateStr) {
  return dateStr.replace('.', '');
}

function findIfReverse(categories) {
  let currentDateComparison = prepDateForParsing(categories[0]);
  if (Number.isNaN(Date.parse(currentDateComparison))) {
    const currentDateComparisonLength = currentDateComparison.split(' ').length;
    if (currentDateComparisonLength === 2) {
      currentDateComparison = currentDateComparison.split(' ').join(' 1, ');
    }
  }
  for (let f = 1; f < categories.length; f++) {
    let currentCategory = prepDateForParsing(categories[f]);
    if (Number.isNaN(Date.parse(currentCategory))) {
      const currentIndexComparisonLength = categories[f].split(' ').length;
      if (currentIndexComparisonLength === 2) {
        currentCategory = currentCategory.split(' ').join(' 1, ');
      }
    }
    if (Number.isNaN(new Date(currentCategory))) {
      return false;
    }
    if (f === 1) {
      if (Date.parse(currentDateComparison) < Date.parse(currentCategory)) {
        return false;
      }
    } else {
      if (Date.parse(currentDateComparison) < Date.parse(currentCategory)) {
        return false;
      }
      if (
        f === categories.length - 1 &&
        Date.parse(currentDateComparison) > Date.parse(currentCategory)
      ) {
        return true;
      }
    }
  }
}

function getDataPoint(series, context, isReverse) {
  if (context.x !== undefined) {
    for (let s = 0; s < series.length; s++) {
      for (let d = 0; d < series[s].data.length; d++) {
        if (series[s].data[d].x === context.x) {
          return { point: series[s].data[d], index: series[s].data[d].index };
        }
      }
    }
  }
  return {
    point: context.point,
    index: context.point.hasOwnProperty('index') ? context.point.index : context.point.x,
  };
}

function formatStandard(options, context, formatter) {
  const width = options.width - 130;

  return `<span style="width:${width}px">${formatSeries(options, context) || ''}${
    formatter.category ? `<span> ${formatter.category}</span>` : ''
  }<span class="bold-tooltip"> ${formatter.value}</span></span>`;
}

function formatStackedPercent(data, options, context, formatter) {
  let total = 0;
  data.series.forEach((item) => {
    total += item.data[formatter.index].y;
  });
  return `<span>${formatSeries(options, context) || ''} ${
    formatter.category || ''
  }<span class="bold-tooltip">${formatter.value} (${Math.round(
    (context.point.y / total) * 100
  )}%)</span></span>`;
}

function formatSeries(options, context) {
  if (options.legend.enabled && options.series.length > 1) {
    let nameArray =
      options.type === 'scatter'
        ? context.point.value.toString().split(' ')
        : context.series.name.toString().split(' ');
    if (nameArray[0] === '<br') {
      for (let i = 0; i < nameArray.length; i++) {
        if (nameArray[i] === '<br' || nameArray[i] === '/>') {
          nameArray.splice(i, 1);
          i--;
        }
      }
    }
    nameArray = nameArray.join(' ');
    nameArray = hasWSJVideoGroup(options) ? nameArray.toUpperCase() : nameArray;
    return `<span class='highcharts-color-${
      context.series?.colorIndex || context.colorIndex
    }'>${nameArray} </span>`;
  }
}
