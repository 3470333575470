import { hasVideoGroup } from '../inner/utils';
import { getBestRule, isCompactThumb } from './text-rules';
import { removeEl, getNumberOfLines } from './util';
import { getHeadlinesStyles } from '../style/outer';

export function postRender(highchart, options) {
  const R = getRules(options);
  const marketwatchThumb = isCompactThumb(options);

  const result = {
    hed: null,
    dek: null,
    subdek: null,
    x: highchart.spacing[3],
    y: highchart.spacing[0],
    width: highchart.chartWidth - highchart.spacing[3] - highchart.spacing[1],
  };
  if (!marketwatchThumb) {
    if (R.HED) {
      writeHed(options, highchart.renderer, R.HEADINGS.title.text, result);
    }
    if ((R.HED && R.DEK) || (R.HED && R.SUBDEK)) {
      result.y += getMargin(options, true);
      if (options.product === 'wsj') result.y -= 3;
      if (options.product === 'wsj' && hasVideoGroup(options.id)) result.y += 12;
    }
    if (R.DEK) {
      writeDek(options, highchart.renderer, R.HEADINGS.dek.text, result, R);
    }
    if ((R.HED && R.DEK && R.SUBDEK) || (R.DEK && R.SUBDEK)) {
      result.y += getMargin(options, false);
      if (options.product === 'wsj') result.y += 9;
    }
    if (R.SUBDEK && !R.RULE.subdekPromo) {
      writeChartLabel(options, highchart.renderer, R.HEADINGS.subdek.text, result, R);
    }
    if (R.SUBDEKPROMO) {
      writeChartLabel(options, highchart.renderer, R.HEADINGS.subdekPromo.text, result, R);
    }
  }
  return result;
}

export function renderInvalidated(rendered, options, invalidated) {
  if (__ALL__) {
    if (!rendered.outerInfo.info) {
      return false;
    }
    const R = getRules(options);
    const result = rendered.outerInfo.info.headlines;
    const invalidHed = invalidated.indexOf('headings/title') !== -1;
    const invalidDek =
      invalidated.indexOf('headings/dek') !== -1 || (R.DEK && invalidHed && !result.hed);

    if ((invalidHed || invalidDek) && !!rendered.outer.spacing) {
      result.y = rendered.outer.spacing[0];

      // If previously there was no HED but now there is, rewrite the DEK to
      // force the face to change from bold to normal.
      const invalidDek2 = R.DEK && invalidHed && !result.hed;

      if (invalidHed) {
        removeEl(result.hed);
        if (R.HED) {
          writeHed(options, rendered.outer.renderer, R.HEADINGS.title.text, result);
        } else {
          result.hed = null;
        }
      } else if (result.hed) {
        result.y = result.hed.bottom;
      }

      if (R.HED && R.DEK) {
        result.y += HED_AND_DEK_MARGIN;
      }

      if (invalidDek) {
        removeEl(result.dek);
        if (R.DEK) {
          writeDek(options, rendered.outer.renderer, R.HEADINGS.dek.text, result, R.HED);
        } else {
          result.dek = null;
        }
      } else if (result.dek) {
        if (result.y !== result.dek.y) {
          const delta = result.y - result.dek.y;
          if (result.dek.el) {
            const nowY = +result.dek.el.element.getAttribute('y');
            result.dek.el.element.setAttribute('y', nowY + delta);
          }
          result.dek.y += delta;
          result.dek.bottom += delta;
        }
        result.y = result.dek.bottom;
      }

      rendered.outerInfo.y = result.y;
      return true;
    }
    return false;
  }
}

function getRules(options) {
  const RULE = getBestRule(options.size);
  const HEADINGS = options.headings;
  const HED = HEADINGS.title.text && HEADINGS.title.enabled && RULE.title;
  const DEK = HEADINGS.dek.text && HEADINGS.dek.enabled && RULE.dek;
  const SUBDEK = HEADINGS.subdek.text && HEADINGS.subdek.enabled && RULE.subdek;
  const SUBDEKPROMO = HEADINGS.subdekPromo.text && HEADINGS.subdekPromo.enabled && RULE.subdekPromo;
  return { RULE, HEADINGS, HED, DEK, SUBDEK, SUBDEKPROMO };
}

function writeHed(options, renderer, text, result) {
  const css = getCss(options, 'hed', result.width);
  let financialnewsFirstLetterJ = false;
  if (__FNLONDON__) {
    if (text.charAt(0) === 'J') {
      financialnewsFirstLetterJ = true;
    }
  }
  const [formattedText, offset] = hasVideoGroup(options.id)
    ? [text.toUpperCase(), options.legend.enabled ? 0 : 15]
    : [text, 0];
  const obj = writeText(
    financialnewsFirstLetterJ,
    renderer,
    formattedText,
    result.x,
    result.y,
    css,
    false,
    'charts-headline'
  );
  result.hed = {
    x: result.x,
    y: result.y,
    width: result.width,
    offsetY: obj.offsetY,
    bottom: obj.bottom,
    el: obj.el,
  };
  result.y = obj.bottom + offset;
}

function writeDek(options, renderer, text, result, rules) {
  const financialnewsFirstLetterJ = false;
  const css = getCss(options, 'dek', result.width, rules);
  const formattedText = hasVideoGroup(options.id) ? text.toUpperCase() : text;
  // if (options.product === 'mansionglobal') {
  //   css.lineHeight = options.styles.lineHeight.dek;
  // }
  const obj = writeText(
    financialnewsFirstLetterJ,
    renderer,
    formattedText,
    result.x,
    result.y,
    css,
    null,
    'charts-dek'
  );
  result.dek = {
    x: result.x,
    y: result.y,
    width: result.width,
    offsetY: obj.offsetY,
    bottom: obj.bottom,
    el: obj.el,
  };
  const offset = hasVideoGroup(options.id) ? 30 : 0;
  result.y = obj.bottom + offset;
}

function writeChartLabel(options, renderer, text, result, rules) {
  const financialnewsFirstLetterJ = false;

  const css = getCss(options, 'subdek', result.width, rules);
  const boldSubdek = rules.DEK || (!rules.HED && !rules.DEK);
  // if (options.product === 'mansionglobal') {
  //   css.lineHeight = options.styles.lineHeight.subdek;
  //   css.fontWeight = options.styles.weight.semibold;
  // }
  const className = `charts-dek ${boldSubdek ? 'charts-dek--bold' : ''}`;

  const obj = writeText(
    financialnewsFirstLetterJ,
    renderer,
    text,
    result.x,
    result.y,
    css,
    null,
    className
  );
  result.chartLabel = {
    x: result.x,
    y: result.y,
    width: result.width,
    offsetY: obj.offsetY,
    bottom: obj.bottom,
    el: obj.el,
  };
  result.y = obj.bottom;
}

function getCss(options, headline, width, rules) {
  const prop = headline === 'hed' ? 'headline' : 'description';
  const boldSubdek = headline === 'subdek' && (rules.DEK || (!rules.HED && !rules.DEK));
  return getHeadlinesStyles({ hedType: prop, boldSubdek, width, options, headline });
}

// Renders a generic block of text (either hed, dek or subdek) and returns the element
// and its bottom y.
function writeText(
  financialnewsFirstLetterJ,
  renderer,
  text,
  x,
  y,
  css,
  skipOffsetY = false,
  className = ''
) {
  let offsetY;
  if (skipOffsetY) offsetY = 0;
  else offsetY = parseInt(css.fontSize, 10);
  const addOffset = offsetY;
  if (financialnewsFirstLetterJ) {
    x = 3;
  }
  const el = renderer
    .text(text)
    .css(css)
    .attr({ x, y: y + offsetY, class: className })
    .add();
  const tspans = el.element.querySelectorAll('tspan');
  const lines = getNumberOfLines(tspans);
  const lineHeight = parseInt(css.lineHeight, 10);
  const bottom = y + addOffset + lineHeight * lines;
  return { el, offsetY, bottom };
}

function getMargin(options, hed) {
  if (options.product === 'fnlondon') return options.styles.dekFontSize;
  switch (options.id) {
    case 'print-barrons':
      return hed ? 3.5 : 2;
    default:
      return hed ? 8 : 14;
  }
}
