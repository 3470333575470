// @ts-check
import {
  getLegendLayout,
  getPrintLegendMargin,
  setPrintLegendLineHeight,
  alignLegendWithColorChip,
  removeWhiteStackingBorder,
  removeLabels,
} from './legend.utils';
import { setVerticalAnnotations } from '../annotations/verticalAnnotations.prerender';
import * as plotBandsUtils from '../plot-bands/plot-bands.utils';

/**
 * Update width of right aligned legend
 * if it's less than 30% of chart container,
 * or if a word in the label takes more than 30%
 * and line breaks not generated.
 * @param {Highcharts.Chart} chart highcharts options
 * @param {Object} options - chartlos options
 */
function positionRightAlignedLegend(chart, options) {
  const { enabled, layout, forceLegendTop } = options.legend;
  if (!enabled || layout === 'top' || (layout === 'default' && forceLegendTop)) return;
  const width = chart.legend.maxItemWidth + chart.legend.symbolWidth + chart.legend.padding;
  const shouldLegendUpdate = width < chart.legend.legendWidth;
  if (shouldLegendUpdate) {
    /**
     * using chart.update method to set animation to false
     * otherwise animation is picked up by converter
     */
    chart.update(
      {
        legend: {
          width,
        },
      },
      true,
      false,
      false // animation
    );
  }
  // check if legend item wider than legend box (long one word scenario)
  if (chart.legend.maxItemWidth > chart.legend.legendWidth) {
    chart.update(
      {
        legend: {
          width: chart.legend.maxItemWidth,
        },
      },
      true,
      false,
      false // animation
    );
  }
  // update plot bands after a chart was resized
  if (options.plotBandPresets.length || options.plotBands.length) {
    // update function doesn't remove existing labels by default
    const PLOT_BAND_LABEL_SELECTOR = '.highcharts-plot-band-label';
    removeLabels(PLOT_BAND_LABEL_SELECTOR, chart);
    const plotBands = plotBandsUtils.applyLineHeight(options);
    chart.xAxis[0].update({ plotBands });
  }
  if (options.plotLines[0].plotLineValue) {
    const PLOT_LINE_LABEL_SELECTOR = '.highcharts-plot-line-label';
    removeLabels(PLOT_LINE_LABEL_SELECTOR, chart);
    chart.yAxis[0].update({ plotLines: setVerticalAnnotations(options) });
  }
}

/**
 * Set legend align to left for bottom positioned legend
 * Currently there is no built-in method to set align to left without adding extra left margin.
 * A workaround is to set legend group transform x to zero.
 * @param {*} chart
 */
function positionBottomLegend(chart) {
  const legendGroup = chart.legend.group.element;
  const transform = legendGroup.getAttribute('transform');
  const re = /translate\((-?\d+),(-?\d+)\)/;
  const newTransform = transform.replace(re, 'translate(0,$2)');
  legendGroup.setAttribute('transform', newTransform);
}

function setLegendPositionPrint(options, chart, annotationsHeight) {
  if (options.legend.enabled) {
    const isLegendPositionTop = getLegendLayout(options) === 'top';
    if (isLegendPositionTop) {
      let margin = getPrintLegendMargin(options);
      if (annotationsHeight) {
        // as mentioned above extra 5px required for annotations
        margin += annotationsHeight + 5;
      }
      if (chart.legend.margin !== margin) {
        // this sets legend into proper start position and adds space between legend& chart container
        chart.legend.update({ y: 0, margin }, true, false, false);
      }
    } else {
      positionRightAlignedLegend(chart, options);
    }
  }
}

function onPrintLoad(chart, options, annotationsHeight) {
  if (chart.legend?.group) {
    // update legend first
    setLegendPositionPrint(options, chart, annotationsHeight);

    // important to make changes after 'legend update'
    // adjusts legend dy for print charts
    setPrintLegendLineHeight(chart);
    // q:for print only?
    // align legend labels with color chip
    alignLegendWithColorChip(options, chart);
    // print - if stacking, remove stacking white border around legend labels;
    removeWhiteStackingBorder(options, chart);
  }
}

export { positionRightAlignedLegend, onPrintLoad, positionBottomLegend };
