import moment from 'moment-timezone';

import { RANGE, FREQUENCY, TIMETYPES } from '../constants/marketdata';

export function checkInstrumentType(tickers, instrumentType) {
  return tickers.some(({ type }) => type === instrumentType);
}

export function selectProps(obj, props) {
  return props.reduce((newObj, prop) => Object.assign(newObj, { [prop]: obj[prop] }), {});
}

export function createNewProps(props, isCustom, categories, prop) {
  const { start, end, frequency } = props;
  const newProps = findStartEnd(isCustom, start, end, categories, frequency);

  if (prop !== 'update') {
    if (prop === 'marketDataPreset') {
      props.propsToUpdate.forEach((field) => {
        newProps[field] = props[field];
      });
    } else {
      newProps[prop] = props[prop];
    }
  }

  const hasCrypto = checkCrypto(props, prop);
  if (prop === 'dateRange' || hasCrypto) newProps.frequency = frequency;

  return newProps;
}

export function getSourceLine(series) {
  const sources = compileSources(series);
  const sourceNames = Object.keys(sources);

  return sourceNames.length === 1
    ? `Source: ${sourceNames[0]}`
    : writeMultipleSources(sourceNames, sources);
}

export function checkIntradayFrequency(frequency) {
  return (frequency.includes('T') && frequency.includes('M')) || frequency.includes('H');
}

export function createETMoment(date) {
  return moment.tz(date, 'America/New_York');
}

export function lastItem(arr) {
  return arr[arr.length - 1];
}

export function getRangeOptions(chartType) {
  const ranges = Object.keys(RANGE);
  const offset = chartType === 'column' ? 4 : 0;
  return ranges.slice(offset);
}

export function getFrequencyOptions(
  dateRange,
  startDate,
  endDate,
  hasCrypto,
  currentFrequency,
  chartType
) {
  // populate frequency dropdown with proper options for specified date range
  const isCustom = dateRange === 'Custom';
  if (isCustom) {
    // for custom dates, determine which preset date range is closest in length
    const [unixStart, unixEnd] = [startDate, endDate].map(convertDate);
    const timeSpan = unixEnd - unixStart;
    dateRange = getRange(timeSpan);
  }
  const noIntraday = checkCustomIntraday(endDate, isCustom); // for older custom dates, intraday intervals unavailable

  const { start, end, defaultFrequency } = getFrequencyDefaults(
    dateRange,
    hasCrypto,
    chartType,
    noIntraday
  );

  const frequencyOptions = getOptions(hasCrypto, defaultFrequency, start, end, chartType);

  const frequency = getDefaultFrequency(
    isCustom,
    frequencyOptions,
    defaultFrequency,
    currentFrequency
  );

  return { frequencyOptions, defaultFrequency: frequency };
}

export function parseDateRange(dateRange) {
  return {
    timePeriodType: dateRange.slice(-1),
    timeValue: +dateRange.slice(0, -1),
  };
}

export function getFrequencyDefaults(dateRange, hasCrypto, chartType, noIntraday) {
  const { start, end, default: frequency } = RANGE[dateRange];

  const isColumn = chartType === 'column';
  const isIntraday = isFrequencyIntraday(frequency);
  const needsInterday = (noIntraday || isColumn) && isIntraday;
  if (needsInterday) {
    return { start: 'P1D', end: getEnd(end), defaultFrequency: 'P1D' };
  }

  const defaultFrequency = hasCrypto && frequency === 'P7D' ? 'P1M' : frequency;
  return { start, end, defaultFrequency };
}

export function checkSeriesLength(series) {
  return series && series.length === 1;
}

export function getLastValue(data) {
  for (let i = data.length - 1; i >= 0; i--) {
    const value = data[i];
    const { y } = value;
    if (y !== null && y !== undefined) {
      return value;
    }
  }
  return null;
}

export function parseData(data) {
  return data.map((point) => selectProps(point, ['y']));
}

export function parseDataColumn(data, prevData) {
  return data
    .map((point, i) => {
      if (i > 0) {
        const y = getColumnY(point, data, i);
        const updatedPoint = { y };
        if (prevData && prevData[i - 1] && prevData[i - 1].color) {
          updatedPoint.color = prevData[i - 1].color;
        }
        return updatedPoint;
      }
    })
    .slice(1);
}

// getFrequencyOptions helper functions
function convertDate(date) {
  return date.format('x');
}

function getRange(timeSpan) {
  const timePeriods = getTimePeriods();
  let current;
  for (let i = 0; i < timePeriods.length; i++) {
    current = timePeriods[i];
    if (timeSpan < current.milliseconds) {
      const prev = timePeriods[i - 1];
      return i === 0 || checkDate(timeSpan, prev, current) ? current.range : prev.range;
    }
  }
  return current.range;
}

function getTimePeriods() {
  return Object.keys(RANGE)
    .map(createTimeSpanObj)
    .filter(({ milliseconds }) => !isNaN(milliseconds));
}

function createTimeSpanObj(range) {
  const { timePeriodType, timeValue } = parseDateRange(range);
  const milliseconds = TIMETYPES[timePeriodType] * timeValue;
  return { range, milliseconds };
}

function checkDate(timeSpan, prev, current) {
  return timeSpan - prev.milliseconds > current.milliseconds - timeSpan;
}

function checkCustomIntraday(endDate, isCustom) {
  return isCustom && endDate.isBefore(moment().subtract(2, 'w'));
}

function getOptions(hasCrypto, defaultFrequency, start, end) {
  return hasCrypto && !isFrequencyIntraday(defaultFrequency)
    ? getInterdayCryptoOptions()
    : getDefaultOptions(start, end);
}

function isFrequencyIntraday(code) {
  return code.includes('T');
}

function getInterdayCryptoOptions() {
  return FREQUENCY.filter(({ value }) => value !== 'P7D' && !isFrequencyIntraday(value));
}

function getDefaultOptions(start, end) {
  const [startIdx, endIdx] = [start, end].map(findFrequencyIndex);
  return FREQUENCY.slice(startIdx, endIdx + 1);
}

function findFrequencyIndex(prop) {
  return FREQUENCY.findIndex(({ value }) => value === prop);
}

function getDefaultFrequency(isCustom, frequencyOptions, defaultFrequency, currentFrequency) {
  const validFrequency = frequencyOptions.some(({ value }) => value === currentFrequency);
  return isCustom && validFrequency ? currentFrequency : defaultFrequency;
}

// getFrequencyDefaults helper function
function getEnd(end) {
  return isFrequencyIntraday(end) ? 'P1D' : end;
}

// createNewProps helper functions
function findStartEnd(isCustom, startProp, endProp, categories, frequency) {
  const [start, end] =
    isCustom &&
    frequency !== 'P7D' &&
    frequency !== 'P1M' &&
    frequency !== 'P3M' &&
    frequency !== 'P1Y'
      ? [startProp, endProp]
      : getDates(categories);
  return { start, end };
}

function getDates(categories) {
  return [categories[0], lastItem(categories)].map(formatDate);
}

function formatDate(date) {
  const isIntraday = date.split(' ').length > 1;
  if (isIntraday) date += 'm';
  const format = isIntraday ? 'MM-DD-YYYY h:mm:ss a' : 'MM-DD-YYYY';
  return moment.tz(date, format, 'America/New_York').format();
}

function checkCrypto(props, prop) {
  const { tickers } = props;
  const cryptoAdded = tickers.length && lastItem(tickers).type === 'CryptoCurrency';
  return prop === 'tickers' && cryptoAdded;
}

// getSourceLine helper functions
function compileSources(series) {
  const sources = {};

  series.forEach(({ sourceInfo }) => {
    const { type, symbol, source } = sourceInfo;

    if (!sources[source]) sources[source] = [];
    const types = sources[source];

    if (checkMultiple(series, type, source)) {
      types.push(symbol); // when same ticker type appears under different sources, list symbol
    } else if (!types.includes(type)) {
      types.push(type); // otherwise list type
    }
  });

  return sources;
}

function checkMultiple(series, currentType, currentSource) {
  // does same ticker type appear under multiple sources?
  return series.some(({ sourceInfo }) => {
    const { type, source } = sourceInfo;
    return type === currentType && source !== currentSource;
  });
}

function writeMultipleSources(sourceKeys, sources) {
  return sourceKeys
    .reduce((str, source) => str + writeSource(sources, source), 'Sources: ')
    .slice(0, -2);
}

function writeSource(sources, source) {
  return `${source} (${writeList(sources[source])}); `;
}

function writeList(listArr) {
  return listArr.reduce((str, listItem) => `${str}${listItem}, `, '').slice(0, -2);
}

// parseDataColumn helper function
function getColumnY(point, data, i) {
  const prevY = data[i - 1].y;
  return ((point.y - prevY) / prevY) * 100;
}

export function getIdx(companies) {
  if (companies.list && companies.list.length && companies.type === 'market') {
    const idx = companies.list.findIndex(
      (company) => ['DJIA', 'SPX', 'COMP'].includes(company.ticker) && company.type === 'Index'
    );
    return idx === -1 ? 0 : idx;
  }
  return 0;
}

export function getTicker(companiesList, index) {
  return companiesList && companiesList[0] ? companiesList[index].ticker : '';
}

export function getMarketProps(data) {
  const propsToSelect = [
    'tickers',
    'dateRange',
    'start',
    'end',
    'frequency',
    'isPercent',
    'isRolling',
  ];
  return selectProps(data, propsToSelect);
}
