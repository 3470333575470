import { hasVideoGroup } from '../utils';

/**
 * Adds options for LINE charts.
 */
export function preRender(data, options) {
  let radius = 0;
  let hoverRadius = 2.5;
  const isVideoGroup = hasVideoGroup(options.id);
  // marked datapoints are not aplicable for video charts
  if (options.markedDataPoints && !isVideoGroup) {
    if (options.print) radius = !options.step ? 1.25 : 0;
    else {
      radius = !options.step ? 2.5 : 0;
      hoverRadius = !options.step ? 3.5 : 0;
    }
  }

  data.plotOptions.line = {
    clip: !isVideoGroup,
    enableMouseTracking: options.interactive,
    // dataGrouping: false,
    states: {
      hover: {
        halo: {
          size: hoverRadius,
        },
      },
    },
  };
  //not aplicable for video (no css has been added)
  data.plotOptions.series = {
    marker: {
      symbol: 'circle',
      radius,
      enabled: options.markedDataPoints,
    },
    dataGrouping: {
      enabled: false,
    },
  };

  return data;
}

export function renderInvalidated(rendered, options, invalidated) {
  if (__ALL__) {
    renderInvalidatedColors(rendered, invalidated);
  }
}
