const WSJ_PRINT_GRID = {
  offsetY: 3.2,
  headlineMargin: 8,
  notesMargin: 8,
  line: 9.6,
  columns: [
    114.0,
    237.6,
    361.2,
    484.8,
    608.4,
    732.0,
  ],
};

export default WSJ_PRINT_GRID;
