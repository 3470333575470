import {
  alignBarLabels,
  alignLabels,
  setZeroLine,
  setZeroLineVideo,
  fixGridLines,
  appendSuffix,
  getSuffixLabel,
} from './axes.utils';
import { hasVideoGroup, extendGridLine, findEls, createPath } from '../utils';

export const alignXAxisLabels = (chart, options) => {
  const { type } = options;
  type === 'bar' ? alignBarLabels(options, chart) : alignLabels(options, chart);
};

/**
 * Sets zero-line css class to zero grid line
 * For video charts sets zero-line for bottom line
 * For 'no scale and left' bar chart - draws new path
 * Not applicable for 'above bar' type chart
 * @param {Highcharts.Chart} chart - highcharts options
 * @param {Object} options - chartlos options
 */
export const drawZeroLine = (chart, options) => {
  const { type: chartType, horizontalBarType } = options;
  const isVideoGroup = hasVideoGroup(options.id);
  if (chartType === 'scatter') {
    setZeroLine(chart.yAxis[0].ticks, false);
    setZeroLine(chart.xAxis[0].ticks, false);
  } else if (chartType === 'bar') {
    // no need for zero line for above bar charts
    if (horizontalBarType === 'aboveBar') return;
    // since noScale&left doesn't draw zero line by default use rederer
    if (horizontalBarType === 'noScaleAndLeft') {
      const { plotLeft, plotTop, plotHeight, seriesGroup } = chart;
      const path = ['M', plotLeft, plotTop, 'L', plotLeft, plotTop + plotHeight];
      const zeroGridLine = createPath(chart.renderer, path, { class: 'zero-line' });
      if (seriesGroup) seriesGroup.element.appendChild(zeroGridLine.element);
    } else {
      // below needs to be doble checked
      const gridLines = findEls(chart.yAxis[0].gridGroup, 'path');
      // checks normal bar charts for offset. offset appears when chart has negative and positive datapoints
      const positiveAndNegative = chart.yAxis[0].min < 0 && chart.yAxis[0].max > 0;
      positiveAndNegative && !chart.yAxis[0].reversed
        ? fixGridLines(chart, gridLines)
        : setZeroLine(chart.yAxis[0].ticks, chart.seriesGroup);
    }
  } else if (isVideoGroup)
    setZeroLineVideo(chart.yAxis[0].ticks, chartType === 'column' ? chart.seriesGroup : false);
  else setZeroLine(chart.yAxis[0].ticks, chartType === 'column' ? chart.seriesGroup : false);
};

export const setGridLineStartAndSuffix = (chart, options) => {
  const { type: chartType, horizontalBarType, axes } = options;
  const { suffix } = axes.units;
  // top fn label painted in a diff color
  if (options.product === 'fnlondon') {
    const label = getSuffixLabel(chart, options);
    label.classList.add('highcharts-suffix');
  }
  if (chartType !== 'bar' && horizontalBarType !== 'noScaleAndLeft') {
    if (options.type !== 'bar') {
      if (suffix) {
        appendSuffix(options, chart);
      }
      if (chartType !== 'scatter') {
        const gridLines =
          chart.yAxis[0].gridGroup && chart.yAxis[0].gridGroup.element
            ? findEls(chart.yAxis[0].gridGroup, 'path')
            : null;
        const start = 0;
        [...gridLines].forEach((line) => extendGridLine(line, start));
        if (chart.xAxis[0].axisLine) extendGridLine(chart.xAxis[0].axisLine.element, start);
        // if (suffix) {
        //   appendSuffix(options, chart.yAxis[0].labelGroup, suffix, label, chart.renderer, chart);
        // }
        // if (isCJKLabel) {
        //   setCJKId(chart.yAxis[0].ticks, options);
        // }
      }
    }
  }
};

export const shortenMinorTicks = (chart, options) => {
  const { categoriesAreDates, xAxisTicks } = options;
  if (!categoriesAreDates) return;
  // const { tickPositions, ticks } = chart.xAxis[0];
  // shorten minor x axis ticks

  chart.xAxis[0].tickPositions.forEach((position) => {
    if (xAxisTicks[position]?.type === 'minor') {
      const d = chart.xAxis[0].ticks[position].mark.d.split(' ');
      d[5] = +d[5] - 2.5;
      chart.xAxis[0].ticks[position].mark.element.setAttribute('d', d.join(' '));
    }
  });
};
