export function decimals(value, props, min) {
  const fd = getMaxDecimalValue(props.forceDecimals);
  const md = getMaxDecimalValue(props.maxDecimals);
  // value = +value;
  value = value.toString();
  if (fd > -1) {
    value = round(value, fd); //value.toFixed(fd);
    if (md > -1 && md < fd) {
      value = round(value, md); //(+value).toFixed(md);
    }
    return value;
  } else if (md > -1) {
    value = round(value, md); //(+value).toFixed(md);
    while (value.substring(value.length - 1) === '0') {
      value = value.substr(0, value.length - 1);
      if (value.substring(value.length - 1) === '.') {
        value = value.substr(0, value.length - 1);
        break;
      }
    }
  }
  if (min && value.toString() !== '0') {
    const numArr = value.toString().split('.');
    const integer = numArr[0];
    let decimal = numArr[1] || '';
    while (decimal.length < min) {
      decimal += '0';
    }
    value = integer + '.' + decimal;
  }
  return value.toString();
}

function addZeros(num) {
  return new Array(num + 1).join('0');
}

function useFixed(value, places) {
  const rounded = (+value).toFixed(places - 1);
  const decPt = places === 1 ? '.' : '';
  return rounded + decPt + '0';
}

function round(value, places) {
  if (places === 0) return (+value).toFixed(0);
  const decimalIdx = value.indexOf('.');
  if (decimalIdx > -1) {
    const dec = value.slice(decimalIdx + 1);
    const diff = places - dec.length;
    if (diff > 0) {
      return value + addZeros(diff);
    }
    const fixedPlaces = dec.slice(0, places - 1);
    let lastDigit = +dec[places - 1];
    const nextDigit = +dec[places];
    if (nextDigit && nextDigit >= 5) {
      if (lastDigit === 9) {
        return useFixed(value, places);
      } else {
        lastDigit += 1;
      }
    }
    const rounded = fixedPlaces + lastDigit.toString();
    return value.slice(0, decimalIdx + 1) + rounded;
  }
  const zeros = addZeros(places);
  return value + '.' + zeros;
}

function getMaxDecimalValue(value) {
  if (typeof value === 'string' && value) {
    return +value;
  } else if (typeof value === 'number' && !isNaN(value)) {
    return value;
  }
  return -1;
}

export function commas(value, commas = true) {
  value += '';
  if (commas) {
    let dotIndex = value.indexOf('.');
    if (dotIndex === -1) {
      dotIndex = value.length;
    }
    value = [
      value.substr(0, dotIndex).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      value.substr(dotIndex),
    ].join('');
  }
  return value;
}

// Returns a useful number that corresponds to a divisor string.
export function getDivisor(unit) {
  switch (unit) {
    case 'hundreds':
      return 100;
    case 'thousands':
      return 1000;
    case 'millions':
      return 1000000;
    case 'billions':
      return 1000000000;
    case 'trillions':
      return 1000000000000;
  }
  return 1;
}

export function applyFull(
  value,
  props,
  skipZero = true,
  tooltipOrAnnotation = false,
  isSpecialHorizontal = false,
  isStackedPercent = false,
  isVideo = false
) {
  if (value === undefined || value === null) return 'n/a';
  const newProps = Object.assign({}, props);
  if (tooltipOrAnnotation) {
    const decimalN = isVideo ? 2 : 3;
    let splitDecimals = value.toString().split('.');
    splitDecimals = splitDecimals.length > 1 ? splitDecimals[1].length : 0;
    if (Number(splitDecimals) >= decimalN && newProps.prefix === '') {
      newProps.forceDecimals = decimalN;
    }
    if (newProps.prefix !== '') {
      if (newProps.prefix !== undefined) {
        newProps.forceDecimals = splitDecimals >= 2 ? 2 : splitDecimals;
      }
    } else {
      newProps.forceDecimals = splitDecimals;
    }
    newProps.maxDecimals = decimalN;
  }
  value = decimals(value, newProps);
  value = commas(value, newProps.commas);
  if (isSpecialHorizontal) {
    return value;
  }

  if (value.charAt(0) === '-') {
    value =
      newProps.prefix !== undefined
        ? `-${newProps.prefix}${value.substr(1)}`
        : `${value.substr(1)}`;
  } else {
    value = newProps.prefix !== undefined ? `${newProps.prefix}${value}` : `${value}`;
  }
  if (!isStackedPercent) value += newProps.suffix;
  return value;
}
