import moment from 'moment';
import { RANGE } from '../../src/ui/constants/marketdata';
import { createETMoment, checkIntradayFrequency } from '../../src/ui/util/marketdata';
import { MONTHS } from '../../src/chart/data/constants';
import { checkArray } from './helpers';
const hstri = require('hstri');

// functions to export
function getMikeParams(query) {
  return ['instrument', 'start', 'end', 'frequency', 'format', 'dateRange', 'product', 'type'].map(
    (prop) => getParam(query, prop)
  );
}

function createDataFormatter(res, isPercent, isCustom, dateRange, frequency, end, instruments) {
  return function (data) {
    const series = formatForChartlos(
      data,
      isPercent,
      isCustom,
      dateRange,
      frequency,
      end,
      instruments
    );
    res.send({ series });
  };
}

function fetchData(
  instrument,
  start,
  end,
  frequency,
  isCustom,
  dateRange,
  isRolling,
  product,
  done
) {
  const isIntraday = /\d+D/.test(dateRange) || (isCustom && checkIntradayFrequency(frequency));
  const formattedEnd = findEndDate(end, isRolling, isCustom, isIntraday);

  const token = getToken(product);

  queryHstri(start, instrument, frequency, formattedEnd, token, dateRange).then((data) =>
    done(data)
  );
}

function createDataParser(res, isPercent, isCustom, frequency, dateRange, end) {
  const isMultiple = res.length > 1;

  const showAsPercentProps = checkShowAsPercent(res, isPercent);
  const usePercent = isPercent || mustShowAsPercent(showAsPercentProps);

  const { TimeInfo, Series } = res[0].raw;
  const { IsIntraday } = TimeInfo;

  const startDates = findStartDates(Series, dateRange);

  const needsLast = !isCustom || checkToday(isCustom, IsIntraday, end, moment());
  const allDates = buildDatesArr(res, [...startDates], end, isCustom, frequency, needsLast);

  const lastIndex = getLastIdx(allDates);

  const lastQuoteDate = findLastQuoteDate(Series);
  // const isCustomRolling = isCustom && !frequency.startsWith('PT') && moment(end).endOf('day').valueOf()>=createETMoment().valueOf();
  const customEndToday =
    isCustom && end.slice(0, 10) === moment(lastQuoteDate).format('YYYY-MM-DD');
  const customEndStopped = isCustom && !customEndToday && lastQuoteDate > moment(end).valueOf();
  // const customEndStopped = isCustom && (end.slice(0, 10) === moment(lastQuoteDate).format('YYYY-MM-DD'));
  // const lastDate = IsIntraday ? allDates[lastIndex] : lastQuoteDate;
  const lastDate = getLastDate(
    customEndStopped,
    end,
    IsIntraday,
    allDates,
    lastIndex,
    lastQuoteDate
  );
  const categories = createCategories(
    allDates,
    needsLast,
    lastIndex,
    IsIntraday,
    frequency,
    lastQuoteDate
  );

  const date = formatAsOfDate(lastDate, IsIntraday, frequency, customEndStopped);

  const dataTemplate = buildDataTemplate(allDates);

  return function (series, index) {
    const { data, raw } = series;
    const { InstrumentType, ExtraData } = raw.Series[index];

    const { Value } = findPriorClose(ExtraData);
    const priorDate = startDates[index];

    const seriesArr = mapSeriesData(
      data,
      !usePercent,
      InstrumentType,
      priorDate,
      Value,
      dataTemplate
    );

    return [seriesArr, categories, date, showAsPercentProps];
  };
}

function getLastDate(customEndStopped, end, IsIntraday, allDates, lastIndex, lastQuoteDate) {
  if (customEndStopped && !IsIntraday) return moment(end).valueOf();
  if (IsIntraday) return allDates[lastIndex];
  return lastQuoteDate;
}
// getMikeParams helper function
function getParam(query, prop) {
  const param = query[prop];
  switch (prop) {
    case 'instrument':
      return checkArray(param);
    case 'format':
      return param === 'percent';
    case 'type':
      return param === 'rolling';
    default:
      return param;
  }
}

// createDataFormatter helper functions
function formatForChartlos(res, isPercent, isCustom, dateRange, frequency, end, instruments) {
  const parseData = createDataParser(res, isPercent, isCustom, frequency, dateRange, end);
  return res.map((series, index) => {
    const [seriesArr, categories, date, showAsPercentProps] = parseData(series, index);
    const { raw, name, ticker } = series;
    const { TimeInfo, Series } = raw;
    const { IsIntraday: isIntraday } = TimeInfo;
    const { InstrumentType: tickerType, FormatHints } = Series[index];
    const instrument = instruments[index];
    const sourceInfo = getSourceInfo(tickerType, ticker, instrument);
    const [prefix, suffix, decimals] = setFormatProps(FormatHints, tickerType);
    const needsCommas = tickerType !== 'Index';
    return {
      data: seriesArr,
      name,
      symbol: ticker,
      needsCommas,
      index,
      date,
      prefix,
      suffix,
      decimals,
      isIntraday,
      sourceInfo,
      tickerType,
      showAsPercentProps,
      categories,
    };
  });
}

function getSourceInfo(type, symbol, fullSymbol) {
  return {
    source: getSource(type, symbol, fullSymbol),
    type: getPlural(type),
    symbol,
  };
}

function getSource(type, symbol, fullSymbol) {
  switch (type) {
    case 'Bond':
    case 'Currency':
      return 'Tullett Prebon';
    case 'Index':
      return getIndexSource(symbol);
    case 'CryptoCurrency':
      return getCryptoSource(symbol, fullSymbol);
    default:
      return 'FactSet';
  }
}

function getIndexSource(symbol) {
  switch (symbol) {
    case 'BUXX':
    case 'NEXT50':
    case 'VCIPO':
      return 'Dow Jones Market Data';
    default:
      return 'FactSet';
  }
}

function getCryptoSource(symbol, fullSymbol) {
  const symbols = [
    'BTCEUR',
    'BTCUSD',
    'ETHEUR',
    'ETHUSD',
    'XRPEUR',
    'XRPUSD',
    'LTCEUR',
    'LTCUSD',
    'BCHEUR',
    'BCHUSD',
    'XMREUR',
    'ETCEUR',
    'XMRUSD',
    'DASHEUR',
    'ZECUSD',
    'ZECEUR',
    'ETCUSD',
    'DASHUSD',
    'DOGEUSD',
    'DOGEEUR',
    'USDTUSD',
    'USDTEUR',
    'ADAUSD',
    'ADAEUR',
    'DOTUSD',
    'DOTEUR',
    'UNIUSD',
    'UNIEUR',
    'LINKUSD',
    'LINKEUR',
    'XLMUSD',
    'XLMEUR',
    'ATOMUSD',
    'ATOMEUR',
    'AAVEUSD',
    'AAVEEUR',
    'XTZUSD',
    'XTZEUR',
    'ALGOUSD',
    'ALGOEUR',
    'ANTEUR',
    'ANTUSD',
    'EOSUSD',
    'EOSEUR',
    'TRXUSD',
    'REPUSD',
    'REPEUR',
    'TRXEUR',
    'REPV2USD',
    'REPV2EUR',
    'USDCUSD',
    'USDCEUR',
    'FILUSD',
    'BALEUR',
    'BALUSD',
    'FILEUR',
    'DAIUSD',
    'DAIEUR',
    'KSMUSD',
    'KSMEUR',
    'MANAUSD',
    'MANAEUR',
    'GRTUSD',
    'GRTEUR',
    'SNXUSD',
    'SNXEUR',
    'BATUSD',
    'BATEUR',
    'YFIEUR',
    'YFIUSD',
    'QTUMUSD',
    'QTUMEUR',
    'WAVESUSD',
    'WAVESEUR',
    'STORJUSD',
    'STORJEUR',
    'SCUSD',
    'SCEUR',
    'EWTUSD',
    'EWTEUR',
    'ICXUSD',
    'ICXEUR',
    'NANOUSD',
    'NANOEUR',
    'KNCUSD',
    'KNCEUR',
    'OCEANUSD',
    'OCEANEUR',
    'FLOWEUR',
    'FLOWUSD',
    'OMGUSD',
    'OMGEUR',
    'CRVUSD',
    'CRVEUR',
    'LSKUSD',
    'LSKEUR',
    'GNOUSD',
    'GNOEUR',
    'KEEPUSD',
    'KEEPEUR',
    'KAVAUSD',
    'KAVAEUR',
    'MLNUSD',
    'MLNEUR',
    'PAXGUSD',
    'PAXGEUR',
    'TBTCUSD',
    'TBTCEUR',
    'OXTUSD',
    'OXTEUR',
  ];
  return symbols.includes(symbol) && fullSymbol.includes('KRAKEN') ? 'Kraken' : 'CoinDesk';
}

function getPlural(type) {
  switch (type) {
    case 'Index':
      return 'indexes';
    case 'Commodity':
      return 'commodities';
    case 'CryptoCurrency':
      return 'crypto currencies';
    case 'Currency':
      return 'currencies';
    case 'ExchangeTradedFund':
      return 'ETFs';
    case 'ExchangeTradedNote':
      return 'ETNs';
    case 'Adr':
      return 'ADRs';
    case 'PreferredStock':
      return 'preferred stocks';
    // case 'MutualFundClosed':
    //   return;
    // case 'MutualFundOpen':
    //   return;
    // case 'RealEstateInvestmentTrust':
    //   return;
    default:
      return `${type.toLowerCase()}s`;
  }
}

function setFormatProps(formatHints, tickerType) {
  const { UnitSymbol, DecimalPlaces, Suffix } = formatHints;
  const prefix = Suffix || !UnitSymbol ? '' : UnitSymbol;
  const suffix = Suffix ? UnitSymbol : '';
  const decimals = checkDecimals(tickerType, DecimalPlaces) ? DecimalPlaces : 2;
  return [prefix, suffix, decimals];
}

function checkDecimals(tickerType, places) {
  return tickerType === 'Bond' || tickerType === 'Currency' || places <= 2;
}

// fetchData helper functions
function findEndDate(end, isRolling, isCustom, isIntraday) {
  const currentTime = moment();
  /*
   * Use the current time for custom interday with an end date of today
   */
  const isCustomToday = checkToday(isCustom, isIntraday, end, currentTime);
  if (isCustomToday) return currentTime;
  /*
   * For other custom auto-updating charts, update only until specified end date
   */
  if (isRolling && isCustom) return moment.min(moment(end), currentTime);
  /*
   * Otherwise use specified end date
   */
  return end;
}

function checkToday(isCustom, isIntraday, end, currentTime) {
  return isCustom && !isIntraday && end.slice(0, 10) === currentTime.format('YYYY-MM-DD');
}

function getToken(product) {
  switch (product) {
    case 'wsj':
      return '57494d5ed7ad44af85bc59a51dd87c90';
    case 'barrons':
      return 'c1b7ca756343461b86565b61bbd2ac6f';
    case 'marketwatch':
      return 'cecc4267a0194af89ca343805a3e57af';
    case 'mansionglobal':
      return '45mb4gzz6uqrogv7xgv37k654z2e5d6q';
    case 'fnlondon':
      return 'brewtbcvblmff8sb1kcdbj21c29j8lv5';
    default:
      return '57494d5ed7ad44af85bc59a51dd87c90';
  }
}

function queryHstri(start, instrument, frequency, end, entitlementToken, timeFrame) {
  return hstri({
    start: getHstriDate(start),
    timeFrame: RANGE[timeFrame].value,
    instrument,
    frequency: frequency || 'P1D',
    end: getHstriDate(end),
    service: 'michelangelo',
    entitlementToken,
  });
}

function getHstriDate(date) {
  return date ? createETMoment(date) : '';
}

// createDataParser helper functions
function checkShowAsPercent(series, isPercentProp) {
  // "Show as percent change" toggle should be locked into true or false in certain situations
  const hasBonds = series.some(checkBonds);
  const differentUnitSymbols = series.some((currentSeries, i) =>
    checkUnitSymbols(series[0], currentSeries, i)
  );
  const disabled = hasBonds || differentUnitSymbols;
  const isPercent = !hasBonds && (differentUnitSymbols || isPercentProp);
  return { isPercent, disabled };
}

function checkBonds(series, i) {
  const instrumentType = getSeriesProp(series, i, 'InstrumentType');
  return instrumentType === 'Bond';
}

function getSeriesProp(series, i, prop) {
  return series.raw.Series[i][prop];
}

function checkUnitSymbols(firstSeries, currentSeries, i) {
  return getUnitSymbol(currentSeries, i) !== getUnitSymbol(firstSeries, 0);
}

function getUnitSymbol(series, i) {
  return getSeriesProp(series, i, 'FormatHints').UnitSymbol;
}

function mustShowAsPercent(showAsPercentProps) {
  const { isPercent, disabled } = showAsPercentProps;
  return isPercent && disabled;
}

function buildDatesArr(res, rawDateArr, end, isCustom, frequency, needsLast) {
  const compiledDates = compileDates(res, rawDateArr);
  const lastDate = compiledDates[getLastIdx(compiledDates)];

  const longerThanDaily = checkLongerThanDaily(frequency);

  const isBefore = longerThanDaily && checkBefore(frequency, lastDate, end);

  return isCustom && end && !needsLast && isBefore ? compiledDates.slice(0, -1) : compiledDates;
}

function compileDates(res, rawDateArr) {
  res.forEach((series) => rawDateArr.push(...series.ticks));
  const uniqueDates = new Set(rawDateArr);
  return [...uniqueDates].filter((date) => date).sort((a, b) => a - b);
}

function getLastIdx(arr) {
  return arr.length - 1;
}

function checkLongerThanDaily(frequency) {
  return ['P7D', 'P1M', 'P3M', 'P1Y'].some((code) => code === frequency);
}

function checkBefore(frequency, lastDate, end) {
  const date = moment(lastDate);
  return convertDate(date, frequency).isAfter(end, convertFrequency(frequency));
}

export function convertDate(date, frequency) {
  switch (frequency) {
    case 'P7D':
      return date.add(5, 'd');
    case 'P1M':
      return date.utc().endOf('month');
    case 'P3M':
      return date.utc().add(2, 'M').endOf('month');
    case 'P1Y':
      return date.utc().endOf('year');
    default:
      return date;
  }
}

/**
 *
 * @param {string} frequency
 */
export function convertFrequency(frequency) {
  switch (frequency) {
    case 'P1D':
    case 'P7D':
      return 'day';
    case 'P1M':
    case 'P3M':
      return 'month';
    case 'P1Y':
      return 'year';
  }
}

function findStartDates(series, dateRange) {
  return series.map((currentSeries) => {
    const { MarketSessions, ExtraData, InstrumentType } = currentSeries;
    if (MarketSessions && dateRange !== 'Custom') {
      const priorClose = findPriorClose(ExtraData).Date;
      const closingTime = MarketSessions.reverse().find(
        (session) => session.Kind === 'Trading'
      ).End;
      const date = moment(priorClose).utc();
      const year = date.year();
      const month = date.month();
      const day = date.date();
      const time = moment(closingTime);
      const hour = time.hour();
      const minutes = time.minute();
      const seconds = time.second();
      return +moment()
        .year(year)
        .month(month)
        .date(day)
        .hour(hour)
        .minute(minutes)
        .second(seconds)
        .format('x');
    }
    if (dateRange === 'YTD') {
      return findPriorClose(ExtraData).Date;
    }
  });
}

function findPriorClose(extraData) {
  return extraData.find((data) => data.Name === 'PriorClose');
}

function findLastQuoteDate(series) {
  const currentQuoteDates = series.map((currentSeries) => currentSeries.CurrentQuote.DateUtc);
  return Math.max(...currentQuoteDates);
}

function createCategories(dates, needsLast, lastIndex, isIntraday, frequency, lastDate) {
  return dates.map((x, i) => {
    const isLast = needsLast && i === lastIndex;
    return formatCategory(x, isIntraday, frequency, isLast, lastDate);
  });
}

function formatCategory(x, isIntraday, frequency, isLast, lastDate) {
  const date = getDate(x, isIntraday, frequency, isLast, lastDate);
  const dayFormat = 'MM/DD/YYYY';
  return isIntraday ? date.format(`${dayFormat} h:mm:ss a`).slice(0, -1) : date.format(dayFormat);
}

function getDate(x, isIntraday, frequency, isLast, lastDate) {
  const needsToday = checkNeedsToday(frequency, isLast);
  const date = needsToday
    ? moment.tz(lastDate, 'America/New_York')
    : convertDate(createETMoment(x), frequency);
  return isIntraday || needsToday ? date : date.utc();
}

function checkNeedsToday(frequency, isLast) {
  return isLast && checkLongerThanDaily(frequency);
}

function formatAsOfDate(x, isIntraday, frequency, customEndStopped) {
  const date = createETMoment(x);
  const month = MONTHS[date.month()];
  const day = date.date();
  const formattedDate = `${month} ${day}`;

  const time = date.format('h:mm a');
  if (customEndStopped) {
    if (!frequency.startsWith('PT')) return `${formattedDate}`;
  }
  const formattedTime = formatTime(time);
  return formattedDate + formattedTime;
}

function formatTime(time) {
  const zerosRemoved = time.replace(':00', '');
  return `, ${zerosRemoved.slice(0, -1)}.m. ET`;
}

function buildDataTemplate(dates) {
  const dataTemplate = {};
  dates.forEach((date) => {
    dataTemplate[date] = null;
  });
  return dataTemplate;
}

function mapSeriesData(data, notPercent, tickerType, firstDate, firstValue, dataTemplate) {
  const seriesData = Object.assign({}, dataTemplate);

  const value = notPercent ? firstValue : 0;
  if (firstDate) seriesData[firstDate] = value;

  const initialY = firstDate ? firstValue : findFirstY(data);

  data.forEach((item, index) => {
    const x = item[0];
    const y = getY(item[1], initialY, notPercent);
    if (seriesData[x] === null) seriesData[x] = y;
  });

  return Object.values(seriesData).map((y) => {
    return { y };
  });
}

function findFirstY(data) {
  // find first non-null Y value (needed to calculate percent change)
  return data.find((point) => point[1])[1];
}

function getY(y, initialY, notPercent) {
  if (y === undefined || y === null) return null;
  if (notPercent) return y;
  return getChange(initialY, y);
}

function getChange(initialY, y) {
  // percentage change from first Y value
  return ((y - initialY) / initialY) * 100;
}

export { getMikeParams, createDataFormatter, fetchData, createDataParser };
