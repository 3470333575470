export function removeChild(el, parentEl) {
  parentEl = parentEl || el.parentElement;
  if (parentEl) {
    parentEl.removeChild(el);
  }
}

export function boldText() {
  let bold = false;
  return function(str, openTag, closeTag) {
    let startIdx = 0;
    let asteriskIdx;
    let newStr = bold ? openTag : '';
    while ((asteriskIdx = str.indexOf('**', startIdx)) !== -1) {
      newStr += str.substring(startIdx, asteriskIdx) + (bold ? closeTag : openTag);
      bold = !bold;
      startIdx = asteriskIdx + 2;
    }
    return newStr + str.substring(startIdx) + (bold ? closeTag : '');
  }
}

export function replaceLineBreaks(text) {
  return text.replace(/[\n|\r|↵]/g, '<br>');
}