export const MAX_4U_WIDTH = 359;
// 5 column chart width (114 previously)
export const MAX_NARROW_WIDTH_PRINT = 140;

export const LONG = 'long';
export const SHORT = 'short';


export const REGEXP_QUARTER = /^([1-4]Q|[1-2]H)?\s*(FY)?['|\u2019]?(\d{2,4})/i;
export const REGEXP_Q_ONLY = /^([1-4]Q|[1-2]H)/i;

export const REGEXP_MDY = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})\b/;
export const REGEXP_MDY_FRED = /^(\d{4})-(\d{2})-(\d{2})\b/;
export const REGEXP_MD = /^(\d{1,2})\/(\d{1,2})\b/;
export const REGEXP_MY = /^(\d{1,2})\/(\d{3,4})\b/;
export const REGEXP_Y = /^(\d{4})\b/;
export const REGEXP_TIME = /\b(\d{1,2}):(\d{1,2})(:\d{1,2})?(\s?[a|p]\.+m\.+?)?(\s[a-z]{2,3})?$/i;

export const MONTHS = ['Jan.', 'Feb.', 'March', 'April', 'May', 'June', 'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'];
export const MONTHS_SHORT = 'JFMAMJJASOND';

export const CATEGORY = /\{category\}/g;
export const SERIES = /\{series\}/g;
export const VALUE = /\{value\}/g;
export const POINT = /\{point\}/g;
export const SYMBOL = /\{symbol\}/g;

export const APOSTROPHE = '’';

export const TRANSPARENT = 'rgba(0, 0, 0, 0)';

export const ANNOTATIONS_SPACE = 4;
export const ANNOTATIONS_SPACE_PRINT = 2;

// timetypes 
const D = 1000 * 60 * 60 * 24;
const M = D * 30;
const Y = D * 365;

export const TIMETYPES = { D, M, Y };

// x-axis intervals constants

export const QUARTERLY_Q_LABEL_THRESHOLD = 10;
export const QUARTERLY_MINOR_TICK_THRESHOLD = 40;

const CENTURY = 'century';
const DECADE = 'decade';
const HALFDECADE = 'halfDecade';
const YEAR = 'year';
const QUARTER = 'quarter';
const MONTH = 'month';
const DATE = 'date';
const DAY = 'day';
const HOUR = 'hour';
const HALF_HOUR = '30-minutes';
const MINUTE = 'minute';

export const QUARTERLY_YEAR = 'QUARTERLY_YEAR';

const MAJOR = 'major';
const MINOR = 'minor';

export const TICK_TYPES = { MAJOR, MINOR };

export const INTERVAL_OPTIONS = {
  CENTURY,
  DECADE,
  HALFDECADE,
  YEAR,
  QUARTER,
  MONTH,
  DATE,
  DAY,
  HOUR,
  HALF_HOUR,
  MINUTE,
};

export const INTERVALS = [
  { name: CENTURY, time: 100 * Y },
  { name: DECADE, time: 10 * Y },
  { name: HALFDECADE, time: 5 * Y },
  { name: YEAR, time: Y },
  { name: MONTH, time: M },
  { name: DATE, time: D },
  { name: HOUR, time: 1000 * 60 * 60 },
  { name: HALF_HOUR, time: 1000 * 60 * 30 },
  { name: MINUTE, time: 1000 * 60 },
];

export const Q_INTERVALS = [
  { name: CENTURY, time: 100 * Y },
  { name: DECADE, time: 10 * Y },
  { name: HALFDECADE, time: 5 * Y },
  { name: YEAR, time: Y },
  { name: QUARTER, time: 3 * M },
];