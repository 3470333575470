// @ts-check
import '../../typedefs';
import { hasThumb, hasZT } from '../utils';
/**
 * Distance between outer edge of chart and headlines/credits.
 * Returns spacing for every publication that supports print
 * @param {ChartlosOptions['type']} chartType - chart type
 * @returns {number[]} spacing - spacing around chart container
 */
function getPrintSpacing(chartType) {
  switch (chartType) {
    case 'bar':
      return [0, 0, 0, 0];
    default:
      return [12, 0, 0, 0];
  }
}

/**
 * Calculates spacing for WSJ crop sizes
 * based on crop size, legend position,
 * chart type and annotations setting.
 * If legend set to top - legend margin
 * is controlled by legend.margin property
 * in inner/legend/legend.js
 * @param {SpacingOptions} spacingOptions
 * @returns {number[]} spacing - spacing around chart container
 */
function getWsjSpacing({
  id,
  hasAnnotation,
  isLegendTop,
  chartType,
  horizontalBarType,
  hasRecessions,
}) {
  let spacing = null;
  if (chartType === 'bar') {
    switch (horizontalBarType) {
      case 'normal':
        // set right spacing to 2 to avoid grid line cut off
        spacing = isLegendTop ? [0, 2, 8, 0] : [-20, 2, 8, 0];
        break;
      case 'noScaleAndLeft':
        spacing = isLegendTop ? [0, 0, 8, 0] : [5, 0, 10, 0];
        break;
      default:
        spacing = isLegendTop ? [0, 0, 8, 0] : [5, 0, 0, 0];
    }
  } else {
    switch (id) {
      case '4u':
        spacing = isLegendTop ? [0, 0, 6, 0] : [12, 0, 6, 0];
        break;
      case '8u':
        spacing = isLegendTop ? [0, 0, 7, 0] : [13, 0, 7, 0];
        break;
      case '12u':
        spacing = isLegendTop ? [0, 0, 10, 0] : [14, 0, 10, 0];
        break;
      case '16u':
        spacing = isLegendTop ? [0, 0, 10, 0] : [16, 0, 10, 0];
        break;
      case 'twitterVideo':
        spacing = [35, 0, 0, 0];
        break;
      case 'video':
      case 'verticalVideo':
        spacing = isLegendTop ? [20, 0, 0, 0] : [35, 0, 0, 0];
        break;
      default:
        spacing = isLegendTop ? [0, 0, 9, 0] : [15, 0, 9, 0];
    }
    if (hasAnnotation) {
      spacing[0] = isLegendTop ? 0 : 18;
      if (hasRecessions && !isLegendTop) spacing[0] += 10;
    }
    if (chartType === 'scatter') {
      spacing[1] = 6;
    }
  }
  return spacing;
}

function getBarronsSpacing({
  product,
  id,
  hasAnnotation,
  isLegendTop,
  chartType,
  horizontalBarType,
  hasRecessions,
  isPrint,
  styles,
}) {
  if (isPrint) {
    if (hasAnnotation) {
      return [isLegendTop ? 0 : 30, 0, 12, 0];
    }
    return [16, 0, 12, 0];
  }
  if (hasZT(id)) {
    if (chartType === 'bar' && horizontalBarType === 'normal') return [-20, 0, 12, 3];
    return [16, 0, 12, 3];
  }
  if (hasThumb(id)) return [0, 0, 20, 0];
  if (chartType === 'pie') return [isLegendTop ? 0 : -20, 5, 0, 5];
  let spacing = null;
  const isFN = product === 'fnlondon';
  if (chartType === 'bar') {
    switch (horizontalBarType) {
      case 'normal':
        // set right spacing to 2 to avoid grid line cut off
        spacing = isLegendTop ? [0, 2, 8, 0] : [-24, 2, 8, 0];
        break;
      case 'noScaleAndLeft':
        spacing = isLegendTop ? [0, 0, 8, 0] : [5, 0, 10, 0];
        break;
      default:
        spacing = isLegendTop ? [0, 0, 8, 0] : [0, 0, 0, 0];
    }
  } else {
    spacing = isLegendTop ? [0, 0, 0, 0] : [isFN ? styles.lineHeight : 16, 0, 0, 0];
    if (hasAnnotation) {
      spacing[0] = isLegendTop ? 0 : 25;
      if (hasRecessions && !isLegendTop) spacing[0] += 10;
    }
    if (chartType === 'scatter') {
      spacing[1] = 6;
    }
  }
  if (isFN) {
    // add left and right margin due to fn wide font
    // dont't see better way to prevent cut off
    spacing[3] = 5;
    spacing[1] += 3;
  }
  if (product === 'marketwatch') spacing[3] = 3;
  return spacing;
}

// not applicable for wsj print
function getSpacing(args) {
  switch (args.product) {
    case 'wsj':
      return getWsjSpacing(args);
    default:
      return getBarronsSpacing(args);
  }
}

function updatePrintSpacing(options, chart, spacingTop, creditsHeight) {
  if (
    (options.type === 'bar' && options.horizontalBarType === 'normal') ||
    options.type === 'column'
  ) {
    chart.update(
      {
        chart: {
          spacingTop,
          spacingBottom: creditsHeight,
        },
        // ? double check for print
        yAxis: {
          tickAmount: chart.yAxis[0].tickPositions.length,
        },
      },
      true,
      false,
      false
    );
  } else {
    // chart.update takes - obj to update, redraw, oneToOne, animation
    chart.update(
      {
        chart: {
          spacingTop,
          spacingBottom: creditsHeight,
        },
      },
      true,
      false,
      false
    );
  }
}

export { getPrintSpacing, updatePrintSpacing };
export default getSpacing;
