import { MONTHS, INTERVAL_OPTIONS, QUARTERLY_YEAR } from './constants';
import { getQuarter } from './quarterly'
const { CENTURY, DECADE, HALFDECADE, YEAR, QUARTER, MONTH, DATE, HOUR, HALF_HOUR, MINUTE } = INTERVAL_OPTIONS;

/**
 *
 * @param   {number}  hour  the hours as a number (0 to 24)
 * @return  {string}  The period in 12-hour clock, either A.M. or P.M.
 */
export const get12HourPeriod = (hour) => {
  return hour >= 12 ? 'p.m.' : 'a.m.';
};

/**
 *
 * @param   {number}  hour  the hours as a number (0 to 24)
 * @return  {string}  The formatted time, like 7 a.m.
 */
export function formatHour(hour) {
  const ampm = get12HourPeriod(hour);
  if (hour === 0) return `12 ${ampm}`;
  else return hour === 12 ? 'noon' : `${hour % 12} ${ampm}`;
}

/**
 *
 * @param   {number}  hour  the hours as a number (0 to 24)
 * @param   {number}  minute  the minutes as a number (0 to 59)
 * @return  {string}  The formatted time, like 7:30 a.m.
 */
export function formatMinute(hour, minute) {
  const ampm = get12HourPeriod(hour);
  if (!minute) return formatHour(hour);
  return `${hour % 12}:${minute} ${ampm}`;
}

function createLabel({dateObj, type, isFirstLabel, fiscalYears}) {
  const year = dateObj.year || dateObj.years;
  const month = dateObj.month === undefined ? dateObj.months : dateObj.month;
  const date = dateObj.date;
  const hour = dateObj.hour === undefined ? dateObj.hours : dateObj.hour;
  const minute = dateObj.minute || dateObj.minutes;
  let yearText = '';

  if (year) {
    const shortYear = year.toString().slice(2);
    yearText = isFirstLabel || shortYear === '00' ? year.toString() : `'${shortYear}`;
    if (isFirstLabel && fiscalYears) yearText = 'FY' + yearText;
  }
  const monthText = MONTHS[month];
  const quarterText = getQuarter(month);
  switch (type) {
    case YEAR:
    case DECADE:
    case HALFDECADE:
    case CENTURY:
      return yearText;
    case QUARTERLY_YEAR:
      return `${quarterText} ${yearText}`;
    case QUARTER:
      return month < 3 ? `${quarterText} ${yearText}` : quarterText;
    case MONTH:
      return month === 0 || isFirstLabel ? `${monthText} ${yearText}` : monthText;
    case DATE:
      return `${monthText} ${date}`;
    case HOUR:
      const formattedHour = formatHour(hour);
      return isFirstLabel ? `${monthText} ${date}` : formattedHour;
    case HALF_HOUR:
      const formattedHalfHour = formatMinute(hour, minute);
      return isFirstLabel ? `${monthText} ${date}` : formattedHalfHour;
    default:
      return '';
  }
}

export default createLabel;
