import * as element from '../util/element';

function removeEl(item) {
  if (item && item.el) {
    element.removeChild(item.el.element);
    item.el = null;
  }
}

function getNumberOfLines(tspans) {
  if (!tspans) return 0;
  const legitLines = [...tspans].filter((tspan) => tspan.getAttribute('dy') > 0);
  return legitLines.length;
}

export { removeEl, getNumberOfLines };
