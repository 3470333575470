/**
 * Adds options for AREA charts.
 */
export function preRender(data, options) {

  data.plotOptions.area = {
    enableMouseTracking: options.interactive,
    stacking: options.stacking,
 
  };
  data.plotOptions.series = {
    marker: {
      enabled: false,
    },
  };

  if (options.product === 'wsj' && options.size === 'print-wsj') {
    // to do: see if it gets applied through css
    data.plotOptions.area.lineWidth = 1.5;
  }
  return data;
}

export function renderInvalidated(rendered, options, invalidated) {
  if (__ALL__) {
    renderInvalidatedColors(rendered, invalidated);
  }
}
