import { fullYear } from './dateutils';


/**
 * For quarterly data, fills in missing quarters, years, etc.
 */
export function normalize(items) {
  // Quarters, halves, years must be declared/set in first item of a year.
  let hasQ = items[0].quarter !== undefined;
  let hasH = items[0].half !== undefined;
  let hasY = items[0].year !== undefined;
  let FY = hasY && items[0].fiscalYear;

  // Dates are neither quarterly, halves, or fiscal years, so just return.
  if (!(hasQ || hasH || FY)) {
    return items;
  }

  let quarter = undefined;
  let half = undefined;
  let year = undefined;
  let currYear = undefined;
  items.forEach(item => {
    let prevQ = hasQ ? quarter : (hasH ? half : null);
    item.fiscalYear = FY;

    if (hasQ) {
      if (!item.quarter) {
        if (item.hasOwnProperty('month')) {
          // - 1 because getQuarter starts months from 0:
          item.quarter = getQuarter(item.month - 1);
        } else {
          quarter += 1;
          quarter %= 4;
          quarter = (quarter === 0) ? 4 : quarter;
          item.quarter = quarter + 'Q';
        }
      }
      quarter = +item.quarter.substring(0, 1);
    } else {
      item.quarter = undefined;
    }

    if (hasH) {
      if (!item.half) {
        half += 1;
        half %= 2;
        half = (half === 0) ? 2 : half;
        item.half = half + 'H';
      }
      half = +item.half.substring(0, 1)
    } else {
      item.half = undefined;
    }

    if (hasY) {
      if (item.year === undefined) {
        item.year = year;
        if ((hasQ && quarter <= prevQ) || (hasH && half <= prevQ)) {
          item.year += 1;
        }
      }
      item.year = year = fullYear(item.year);

      if (currYear !== year) {
        currYear = year;
        hasQ = item.quarter !== undefined;
        hasH = item.half !== undefined;
      }
    }
  });

  return items;
}


/**
 * Transforms items into a fiscal years by declaring fiscalYear to be true for
 * first item. Assumes normalize() will be called subsequently.
 */
export function makeFiscalYears(items) {
  items[0].fiscalYear = items[0].year !== undefined;
  return items;
}

export function removeFiscalYears(items) {
  items.forEach(item => {
    item.fiscalYear = false;
  })
  return items;
}


export function makeQuarterly(items) {
  items.forEach(item => {
    if (item.quarter === undefined && item.half === undefined &&
        item.month !== undefined) {
      item.quarter = getQuarter(item.month);
    }
  });
  return makeHalves(items);
}


function makeHalves(items) {
  let isHalf = false;
  let yearBlocks = {};

  // Separate dates into blocks of years.
  items.forEach(item => {
    if (item.quarter && item.year !== undefined) {
      yearBlocks[item.year] = yearBlocks[item.year] || [];
      if (item.month !== undefined) {
        yearBlocks[item.year].push(item);
      }
    }
  });

  // Transform any year blocks with 2 dates 6 months or more apart into halves.
  Object.keys(yearBlocks).forEach(key => {
    const block = yearBlocks[key];
    if (block.length === 2) {
      if (block[1].month - block[0].month >= 6) {
        isHalf = true;
        block[0].quarter = block[1].quarter = undefined;
        block[0].half = '1H';
        block[1].half = '2H';
      }
    }
  });

  if (isHalf) {
    // Data if halves, not quarters, so convert quarters in years with only
    // one quarter to a half.
    Object.keys(yearBlocks).forEach(key => {
      block = yearBlocks[key];
      if (block.length === 1) {
        block[0].quarter = undefined;
        block[0].half = getHalf(block[0].month);
      }
    });
  }
  return items;
}


export function removeQuarterly(items) {
  items.forEach(item => {
    item.quarter = item.half = undefined;
  })
  return items;
}


/**
 * Converts a month index to a quarterly string value.
 * @private
 */
export function getQuarter(month) {
  if (month < 3) {
    return '1Q';
  } else if (month < 6) {
    return '2Q';
  } else if (month < 9) {
    return '3Q';
  } else {
    return '4Q';
  }
}

/**
 * Converts a month index to a half year string value.
 * @private
 */
export function getHalf(month) {
  return (month < 6) ? '1H' : '2H';
}

export const isSameQuarter = ({date, nextDate}) => {
  if(!nextDate) return false;
  const { year, quarter, month } = date;
  const { year: nextYear, quarter: nextQuarter, month: nextMonth} = nextDate;
  const sameQuarter = quarter === nextQuarter || month === nextMonth || getQuarter(month) === nextQuarter;
  return year !== nextYear && sameQuarter;
}