import { getBestRule, isCompactThumb } from './text-rules';
import { removeEl, getNumberOfLines } from './util';
import { MAX_4U_WIDTH } from '../data/constants';
import { getNotesStyles } from '../style/outer';

export function postRender(highchart, options) {
  const R = getRules(options);
  const { renderer } = highchart;
  let result = {
    source: null,
    note: null,
    date: null,
    x: highchart.spacing[3],
    y: getStartY(highchart, options),
    width: `${highchart.chartWidth - highchart.spacing[3] - highchart.spacing[1]}px`,
  };
  const sText = R.headings.source.text;
  const nText = R.headings.note.text;
  const spText = R.headings.sourcePromo.text;
  const npText = R.headings.notePromo.text;

  const sourceText = getText(options.id, sText);
  const noteText = getText(options.id, nText);
  const sourcePromoText = getText(options.id, spText);
  const notePromoText = getText(options.id, npText);
  const marketwatchThumb = isCompactThumb(options);

  if (!marketwatchThumb) {
    if (R.source && !R.rule.sourcePromo) {
      result = writeSection(
        R.headings,
        options,
        'source',
        renderer,
        sourceText,
        result,
        getSourceAlign(options)
      );
    }
    if (R.note && !R.rule.notePromo) {
      result = writeSection(
        R.headings,
        options,
        'note',
        renderer,
        noteText,
        result,
        getNoteAlign(options)
      );
    }
    if (R.sourcePromo) {
      result = writeSection(
        R.headings,
        options,
        'source',
        renderer,
        sourcePromoText,
        result,
        getSourceAlign(options)
      );
    }
    if (R.notePromo) {
      result = writeSection(
        R.headings,
        options,
        'note',
        renderer,
        notePromoText,
        result,
        getNoteAlign(options)
      );
    }
    if (R.date) {
      result = writeSection(
        R.headings,
        options,
        'date',
        renderer,
        R.headings.date.text,
        result,
        'right',
        'charts-date'
      );
    }
  }

  return result;
}

export function renderInvalidated(rendered, options, invalidated) {
  if (__ALL__) {
    const invalidSections = {};
    ['source', 'note', 'date'].forEach(
      (section) => (invalidSections[section] = invalidated.includes(`headings/${section}`))
    );
    // const invalidSource = invalidated.indexOf('headings/source') !== -1;
    // const invalidNote = invalidated.indexOf('headings/note') !== -1;
    if (Object.values(invalidSections).includes(true)) {
      let { info } = rendered.outerInfo;
      if (!info) return true;
      info = info.notes;
      info.y = getStartY(rendered.outer, options);

      ['source', 'note', 'date'].forEach((section) =>
        invalidSection(section, invalidSections[section])
      );
      return true;
    }
    return false;
  }
}

function invalidSection(type, isInvalid) {
  if (isInvalid) {
    removeEl(info[type]);
    if (R[type]) {
      info = writeSection(R.headings, options, type, renderer, R.headings[type].text, info);
    } else {
      info[type] = null;
    }
  } else if (info[type]) {
    if (info.y !== info[type].y) {
      const delta = info.y - info[type].y;
      if (info[type].el) {
        const nowY = +info[type].el.element.getAttribute('y');
        info[type].el.element.setAttribute('y', nowY + delta);
      }
      info[type].y += delta;
      info[type].top += delta;
    }
    info.y = info[type].top;
  }
}

function getRules(options) {
  const rule = getBestRule(options.size);
  const { headings } = options;
  const rules = { rule, headings };
  ['source', 'note', 'date', 'notePromo', 'sourcePromo'].forEach((section) => {
    const { text, enabled } = headings[section];
    rules[section] = text && enabled && rule[section];
  });
  return rules;
}

function writeSection(
  headings,
  options,
  section,
  renderer,
  text,
  result,
  align = 'left',
  className = 'charts-source'
) {
  result[section] = {
    x: result.x,
    y: result.y,
    width: result.width,
    top: result.y,
    el: null,
  };
  const obj = writeText(options, renderer, text, result, align, section, className);
  result[section].el = obj.el;
  result[section].top = obj.top;
  result.y = obj.top;
  if ((section === 'source' || section === 'note') && options.product === 'wsj') {
    if (result.note && !headings.source.text) result.y -= 3;
    if (!headings.note.text && result.source) result.y -= 3;
    if (headings.note.text && headings.source.text && section === 'source') {
      result.y -= 1;
    }
  }
  if (options.id === 'video' || options.id === 'verticalVideo') {
    result.y -= 28;
  }
  if (options.id === 'twitterVideo') {
    if (!headings.note.text) result.y -= 28;
    else result.y -= 6;
  }
  if (section === 'date') {
    const wsjOffset = 9;
    const offset = options.product === 'wsj' ? wsjOffset : 2;
    result.y += offset;
  }

  return result;
}

// Renders a generic block of text (source) and returns the element
// and its top y.
function writeText(options, renderer, text, result, align = 'left', section = null, className) {
  const offsetTagline = options.print ? 1 : 0;
  const css = getNotesStyles(section, options, result.width);
  const resultY = options.id === 'twitterVideo' ? 477 : result.y - offsetTagline;
  const resultX = getResultX(options, align, result);
  const el = renderer
    .text(text)
    .css(css)
    .attr({
      x: resultX,
      y: resultY,
      align,
      class: className,
    })
    .add();

  const lines = getNumberOfLines(el.element.querySelectorAll('tspan')) + 1;
  const lineHeight = parseFloat(css.lineHeight);

  let yPx = result.y;
  if (lines > 1) {
    yPx = +el.element.getAttribute('y');
    yPx -= (lines - 1) * lineHeight;
    el.element.setAttribute('y', yPx);
  }
  return {
    el,
    y: yPx,
    top: yPx - lineHeight,
  };
}

function getSourceAlign(options) {
  return options.product === 'marketwatch' || options.id === 'print-barrons' ? 'right' : 'left';
}

function getNoteAlign(options) {
  switch (options.id) {
    case 'print-barrons':
    case 'twitterVideo':
      return 'right';
    default:
      return 'left';
  }
}

function getStartY(highchart, options) {
  return options.height - highchart.spacing[2] - yOffset(options);
}

// Lifts text just enough so that bottom line shows tails.
function yOffset(options) {
  return Math.round((options.styles.lineHeight - options.styles.smallFontSize) * 0.5);
}

function getResultX(options, align, result) {
  if (options.id === 'twitterVideo' && align === 'right') return parseFloat(result.width) + 60;
  if (align === 'left') return result.x;
  return parseFloat(result.width);
}

function getText(id, text) {
  return (id === 'twitterVideo' || id === 'video' || id === 'verticalVideo') && text ? text.toUpperCase() : text;
}
