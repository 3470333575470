// @ts-check
import {
  spaceOutAndDrawLines,
  sortAnnotations,
  sortPaths,
  centerVideoLabels,
} from './annotations.utils';
import { getFirstGridLine, findEls, setDy } from '../utils';

function onLoad(chart, options, yAxisLabels) {
  if (!options.annotations?.length || !chart.annotations?.length) {
    return;
  }

  const { annotations } = chart;
  const { labelsGroup, shapesGroup, labels } = annotations[0];
  const annotationG = annotations[0].graphic;
  const allLabels = findEls(labelsGroup, 'g');
  const [sortedLabels, sortedShapes, sortedLabelsOptions] = sortAnnotations(
    shapesGroup,
    allLabels,
    labels
  );
  // gridLine needed for 'attachTo-category/date' selection
  const gridLine = getFirstGridLine(chart.yAxis[0].ticks);
  spaceOutAndDrawLines(
    options,
    sortedLabels,
    sortedShapes,
    sortedLabelsOptions,
    gridLine,
    annotationG,
    chart,
    yAxisLabels
  );
  // sort annotation's paths after drawing
  sortPaths(shapesGroup, annotationG);
  centerVideoLabels(options, annotationG);
}
/**
 * If annotations are present, sets proper line height
 * removes path that surrounds annotation label
 * and returns height of the annotation group.
 * @param {ChartlosOptions} options
 * @param {Highcharts.Chart} chart
 * @returns {number}
 */
function getPrintAnnotationsHeight(options, chart) {
  if (options.annotations?.length) {
    const PRINT_ANNOTATIONS_DY = 8;
    chart.annotations[0].labels.forEach((label) => {
      // remove path that surrounds label
      const path = findEls(label.graphic, 'path')[0];
      if (path) path.setAttribute('d', '');
      const tspans = findEls(label.graphic, 'tspan');
      if (tspans) setDy(tspans, PRINT_ANNOTATIONS_DY);

      // not sure what this does
      // const minNumOfDys = getMinNumOfDys(annotations);
      // // set proper label 'y' after setting a proper lineheight
      // const currentDyNum = tspans.length - 1;
      // if (currentDyNum > minNumOfDys) {
      //   // lineheight defaults to 18, our specs are 20;
      //   // calculate additional 2px per each dy
      //   label.options.y = label.options.y - (currentDyNum - minNumOfDys) * (options.print ? -1 : 2);
      // }
    });
    const annotationHeight = chart.annotations[0].labelsGroup.getBBox().height;
    return annotationHeight;
  }
  return 0;
}

export { onLoad, getPrintAnnotationsHeight };
