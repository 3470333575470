export function preRender(data, options) {
  const showOnHover = options.showTooltips;
  data.plotOptions.series = {
    stickyTracking: false,
    marker: {
      symbol: 'circle',
      radius: 4,
      enabled: true,
      states: {
        hover: {
          enabled: showOnHover
        }
      }
    }
  };
  return data;
}