import chartSpacing from './spacing/index';
import * as chart from './chart';
import * as legend from './legend';
import * as tooltips from './tooltips/tooltips.prerender';
import * as axes from './axes';
import * as annotations from './annotations';
import * as plotBands from './plot-bands';
import { loadDigital } from './loadDigital';
import { loadPrint } from './loadPrint';
import { hasSmallMW, hasThumb, hasWsjPrint, hasZT } from './utils';

function getClassName(product, id, headings) {
  if (/print/i.test(id) || /video/i.test(id)) return id;
  return `${product}-digital chart-lang-${headings?.chartFont}`;
}

export function preRender(options) {
  // const className = "custom " + (options.id.includes('print') ? options.id : `${options.product}-digital`);
  const className = `${getClassName(options.product, options.id, options.headings)}`;
  const isWsjPrint = hasWsjPrint(options.size);
  const renderTo = isWsjPrint ? options.el : options.el.querySelector('.dj-chart-inner');
  // const hasAccessibility = options.headings.altText.text;
  // const  defaultDescription = hasAccessibility ? hasAccessibility : options.headings.subdek.text || options.headings.title.text;
  const loadFn = isWsjPrint && __ALL__ ? loadPrint : loadDigital;
  let data = {
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
      // enabled: false
    },
    // accessibility: {
    //   description: options.headings.altText.text,
    //   enabled: !!options.headings.altText.text,
    // },
    series: options.series,
    chart: {
      className,
      reflow: false,
      type: options.type,
      width: options.width,
      height: options.height,
      renderTo,
      backgroundColor: 'transparent',
      panning: false,
      styledMode: true,
      caption: {},
      events: {
        load() {
          loadFn(this, options);
        },
      },
    },

    credits: {
      text: '',
    },
    title: {
      text: '',
    },

    legend: { enabled: false },
    annotations: [],
    defs: {},
    plotOptions: {},
    xAxis: {},
    yAxis: {},
  };

  data.chart.spacing = chartSpacing(options);
  data.plotOptions[options.type] = {};
  data = chart.preRender(data, options);
  const isZT = hasZT(options.size);
  const applySmallCropSettings = hasSmallMW(options.size) || isZT || hasThumb(options.size);
  if (applySmallCropSettings) {
    // remove x/y-axis labels
    data.xAxis.labels = data.xAxis.labels || {};
    data.xAxis.labels.enabled = false;
    data.xAxis.tickLength = 0;
    data.yAxis.labels = data.yAxis.labels || {};
    data.yAxis.labels.enabled = isZT;
    data.yAxis.opposite = false;
    data.xAxis.className = `${data.xAxis.className} thumb`;
    data.yAxis.className = `${data.yAxis.className} ${isZT ? '' : 'thumb'}`;
    data.yAxis.title = data.yAxis.title || {};
    data.yAxis.title.text = '';
    if (isZT) {
      data = axes.preRender(data, options);
      data.xAxis.plotBands = options.plotBandPresets || [];
    }
  } else {
    data = legend.preRender(data, options);
    data = tooltips.preRender(data, options);
    data = axes.preRender(data, options);
    data = plotBands.preRender(data, options);

    if (options.annotations?.length) {
      annotations.defs(data, options);
      annotations.preRender(data, options);
    }
  }

  if (options.irregularDates) {
    data.scrollbar = { enabled: false };
    data.rangeSelector = { enabled: false };
    data.navigator = { enabled: false };
    data.xAxis.crosshair = { width: 0 };
    data.yAxis.showLastLabel = true;
  }
  return data;
}

export function renderInvalidated(rendered, options, invalidated) {
  if (__ALL__) {
    chart.renderInvalidated(rendered, options, invalidated);
  }
}
