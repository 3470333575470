/**
 *To do: this file is a huge bucket that contains 'old digital' logic.
 *And needs to be cleaned up
 *  */
import { getHeadingMargin } from '../util/constants';
import {
  handleHeadings,
  extendGridLine,
  findRects,
  findTexts,
  getValue,
  getPxPerPt,
  getTranslateY,
  rectsHeightCalculation,
  rectsWidthCalculation,
  getPercentValues,
  alignTicks,
} from '../util/utils';

import { setZeroLine, alignLabels, appendSuffix, alignBarLabels } from './axes/axes.utils';
import { getSuffixLabel } from './axes/axes.utils';
import { sortAnnotations, spaceOutAndDrawLines, sortPaths } from './annotations/annotations.utils';
import { findYLabels, getFirstGridLine, findEls } from './utils';

import { getBestRule } from '../outer/text-rules';
// new imports
import { getPrintAnnotationsHeight } from './annotations';
import * as legend from './legend';
import { updatePrintSpacing } from './spacing/spacing.utils';

export const loadPrint = (chart, options) => {
  const R = getBestRule(options.id);

  // draw invisible svg rectangle on top of printCharts
  chart.renderer.rect(0, 0, options.width, 3.2).add();

  // ticks are not sorted for some reason
  if (options.type !== 'scatter') {
    chart.xAxis[0].tickPositions.sort((a, b) => a - b);
    chart.yAxis[0].tickPositions.sort((a, b) => a - b);
  }
  /**
   * TO DO: remove non-print code
   */
  // draw headings group separately and then adjust spacingTop.
  const { headingsHeight, creditsHeight } = handleHeadings(options, chart, R);
  // for spacing top - 10 is default + 20 gives needed wsj digital margin
  const headingsMargin = getHeadingMargin(options);
  // To Do: check comment below
  // this fixes highcharts bug when annotations labels don't show up
  // if(options.annotations.length) removeClipPath(options);
  // annotations require additional 5px for spacingTop and legendMargin when legend set to top
  const distance = options.annotations?.length ? 15 : 10;
  const isAboveBar = options.type === 'bar' && options.horizontalBarType === 'aboveBar';
  // eg spacingTop + heading container height + desired margin(no logic here just visual comparison to match specs)
  let spacingTop = headingsHeight + (isAboveBar ? 0 : headingsMargin + distance);
  // get increased if annotations are present
  const annotationsHeight = getPrintAnnotationsHeight(options, chart);
  // if legend top, space b/w legend and annotation indirectly controlled by legendMargin.
  const noTopLegend = !options.legend.enabled || options.legend.layout !== 'top';
  spacingTop += noTopLegend ? annotationsHeight : 0;

  legend.onPrintLoad(chart, options, annotationsHeight);

  updatePrintSpacing(options, chart, spacingTop, creditsHeight);

  // in some cases when horiz bar chart has a negative value, zero label is not rendered
  // this seem to fix that
  // if(options.type === 'bar' && chart.yAxis[0].paddedTicks.length!==chart.yAxis[0].tickPositions.length){
  //   chart.update({
  //     yAxis: {
  //       tickPositions: chart.yAxis[0].paddedTicks.slice(),
  //     }
  //   }, true, false, false);

  const { suffix } = options.axes.units;

  const yAxisLabels =
    options.type !== 'bar' || (options.type === 'bar' && options.horizontalBarType === 'normal')
      ? findYLabels(chart.yAxis[0].ticks)
      : null;

  if (options.type === 'scatter') {
    setZeroLine(chart.yAxis[0].ticks, false);
    setZeroLine(chart.xAxis[0].ticks, false);
    if (suffix) {
      appendSuffix(options, chart);
    }
    return;
  }

  if (isAboveBar) {
    const newPlotHeight = chart.plotHeight * 0.95;
    const defaultHeight = 140;

    let scaleFrom400 =
      newPlotHeight / defaultHeight < 1
        ? newPlotHeight / defaultHeight
        : defaultHeight / newPlotHeight;
    scaleFrom400 = newPlotHeight === defaultHeight ? 0.8 : scaleFrom400;

    const numerator = 1;
    let denominator = 5;
    const seriesLength = options.series[0].data.length;
    const getNewDemoninator = (seriesLength - 1) * 2.5;
    denominator += getNewDemoninator;

    chart.update(
      {
        plotOptions: {
          bar: {
            pointPadding: 0.2 / scaleFrom400,
            groupPadding:
              options.series.length > 1 && options.stacking === null ? 0.2 / scaleFrom400 : 0,
          },
        },
        xAxis: {
          labels: {
            y: -((newPlotHeight * numerator) / denominator),
          },
        },
      },
      true,
      false,
      false
    );
    const yLabels = findTexts(chart.xAxis[0].labelGroup);
    const firstY = yLabels[0].getAttribute('y');
    const lineHeight = options.print ? 7 : 12;
    // const newY = +firstY - lineHeight;
    // const margin = chart.plotTop - newY;
    // const marginTopNeeded = (options.print ? 10 : 20) + margin;
    // commented out code for margin bottom calculations, so far looks good without.
    // const rects = findRects(seriesGroup);
    // const transform = seriesGroup.element.querySelector('g').getAttribute('transform');
    // const transformX = +transform.split(',')[1].split(')')[0];
    // const lastY = +rects[rects.length-1].getAttribute('x')-rects[rects.length-1].getAttribute('width')+transformX;
    // const plotBottom = chart.plotTop + chart.plotHeight;
    // const marginBottom = plotBottom-lastY;

    // const marginBottomNeeded = 14-marginBottom;
    // const creditsY = chart.credits.element.getBBox().y;
    // const bottomMargin = creditsY-plotBottom;
    // const creditsTexts = chart.credits.element.querySelectorAll('text');
    // creditsTexts.forEach(text=>{
    //   text.setAttribute('y', +text.getAttribute('y')+marginBottomNeeded)
    // })
    // chart.update({
    //   chart: {
    //     spacingTop: spacingTop+marginTopNeeded,
    //     // spacingBottom: creditsHeight+marginBottomNeeded
    //   },
    // })

    // still trying to figure out best scenario for print, to better control headings/credits margin
    // we might give a constant label offset and set serie.pointPlacement to 'on'.
    // const rects = findRects(seriesGroup);
    // const labels = findTexts(xLabels);
    // const seriesTransform =  seriesGroup.element.querySelector('g').getAttribute('transform');
    // const transformY = +seriesTransform.split(',')[1].split(')')[0];

    //   [...rects].forEach((rect, i)=>{
    //     const label = labels[i];
    //     const rectX = rect.getAttribute('x');
    //     const rectWidth = rect.getAttribute('width');
    //     const labelOffset = rectWidth*1/7;
    //     if(label){
    //       label.setAttribute('y', transformY-rectX-rectWidth-labelOffset);
    //     }
    //   })
  }

  if (options.type === 'area') {
    // const plotBackground = this.plotBackground.element;
    // const h = +plotBackground.getAttribute('height');
    // const w = +plotBackground.getAttribute('width');
    // const plotY = +plotBackground.getAttribute('y');
    // const plotX = +plotBackground.getAttribute('x');
    // plotBackground.setAttribute('height', h + 1);
    // plotBackground.setAttribute('y', plotY + 1);
    const areaPaths = findEls(chart.seriesGroup, '.highcharts-area');
    // const series2 = seriesGroup.element.querySelector('.highcharts-series-2.highcharts-series');
    // console.log(seriesGroup.element.childNodes[4]);
    // renderer.path(['M', plotX, h + plotY, 'L', plotX + w / 2, h + plotY])
    //   .addClass('line')
    //   .add(seriesGroup);
    [...areaPaths].forEach((path) => {
      const areaD = path.getAttribute('d');
      const dArr = areaD.split(' ');
      // dArr.push('z');
      const lastIdx = dArr.length - 1;
      const lastY = +dArr[lastIdx];
      const newY = lastY + 0.15;
      dArr[lastIdx] = newY;
      for (let i = lastIdx - 3; i >= 0; i -= 3) {
        if (+dArr[i] === lastY) dArr[i] = newY;
        else break;
      }
      const newD = dArr.join(' ');
      path.setAttribute('d', newD);
    });
  }

  const seriesGs = findEls(chart.seriesGroup, 'g');
  // filter out markers g
  // seriesItems - group with paths, rects
  const seriesItems = [...seriesGs].filter(
    (g) => !g.getAttribute('class').includes('highcharts-markers')
  );

  const gridLines = chart.yAxis[0]?.tickPositions?.map(
    (tick) => chart.yAxis[0]?.ticks[tick]?.gridLine?.element
  );
  // todo: align data labels, x-axis labels, suffix/prefix
  if (options.horizontalBarType === 'aboveBar' || options.horizontalBarType === 'noScaleAndLeft') {
    if (chart.yAxis[0].gridGroup) {
      const { top, bottom } = getTopAndBottom(chart, options.type);
      const yAxisLabels = findYLabels(chart.yAxis[0].ticks);
      if (yAxisLabels[0]) {
        const pxPerPt = getPxPerPt(top.y, bottom.y, top.pos, bottom.pos, options.type);
        const rects = findRects(chart.seriesGroup);
        [...rects].forEach((rect) => {
          const value = getValue(rect);
          const h = pxPerPt * Math.abs(value);
          rect.setAttribute('height', h);
        });
        // todo: align data labels for tight charts
        // const {dataLabelsGroup} = yAxis[0].series[0];
        // const dataLabelGs = findEls(dataLabelsGroup, 'g');

        rectsWidthCalculation(seriesItems, chart.xAxis, options, chart.series);
      }
      // disable yAxis labels after rounding calculations
      if (options.horizontalBarType === 'aboveBar') {
        chart.yAxis[0].update(
          {
            visible: false,
          },
          true,
          false,
          false
        );

        // still trying to figure out best scenario for print, to better control headings/credits margin
        // we might give a constant label offset and set serie.pointPlacement to 'on'.
        // const rects = findRects(chart.seriesGroup);
        // const labels = findTexts(xAxis[0].labelGroup);
        // const seriesTransform =  chart.seriesGroup.element.querySelector('g').getAttribute('transform');
        // const transformY = +seriesTransform.split(',')[1].split(')')[0];

        // [...rects].forEach((rect, i)=>{
        //   const label = labels[i];
        //   const rectX = rect.getAttribute('x');
        //   const rectWidth = rect.getAttribute('width');
        //   const labelOffset = rectWidth*1/7;
        //   if(label){
        //     label.setAttribute('y', transformY-rectX-rectWidth-labelOffset);
        //   }
        // })
      }
      if (options.horizontalBarType === 'noScaleAndLeft') {
        chart.yAxis[0].update(
          {
            labels: {
              enabled: false,
            },
          },
          true,
          false,
          false
        );
      }
    }
    return;
  }

  // ensure all y-axis labels are 2px above grid line and add suffix if needed for left aligned charts and

  const numGaps = gridLines.length - 1;
  const { top, bottom } = getTopAndBottom(chart, options.type);

  const delta = (bottom.y - top.y) / numGaps;
  let gridLineY = bottom.y;
  let y;
  const pxPerPt = getPxPerPt(top.y, bottom.y, top.pos, bottom.pos, options.type);
  // recalculate yAxis gridlines position, labels position, attach suffix
  if (!options.logScale) {
    gridLines.forEach((gridLine, i) => {
      const gridLineDArr = gridLine.getAttribute('d').split(' ');
      if (options.type === 'bar') {
        gridLineDArr[1] = gridLineY;
        gridLineDArr[4] = gridLineY;
      } else {
        gridLineDArr[2] = gridLineY;
        gridLineDArr[5] = gridLineY;
      }
      gridLine.setAttribute('d', gridLineDArr.join(' '));
      y = gridLineY;
      gridLineY -= delta;
    });
  }
  const zeroGridLine = chart.yAxis[0].ticks[0]?.gridLine?.element;

  if (!(options.type === 'bar' && options.horizontalBarType === 'normal')) {
    chart.yAxis[0].tickPositions.forEach((tick) => {
      const tickObject = chart.yAxis[0].ticks[tick];
      const gridLineD = tickObject.gridLine.element.getAttribute('d');
      const newY = +gridLineD.split(' ')[2] - 2;
      const label = tickObject.label.element;
      label.setAttribute('y', newY);
    });

    appendSuffix(options, chart);
  }

  let zeroD;
  let zeroY;
  if (zeroGridLine && !options.logScale) {
    zeroD = zeroGridLine.getAttribute('d');
    if (options.type === 'bar') zeroY = +zeroD.split(' ')[1];
    else zeroY = +zeroD.split(' ')[2];
    zeroGridLine.setAttribute('class', 'zero-line');
  }

  if (options.type !== 'bar') {
    // extend gridline after drawing zero line
    [...gridLines].forEach((line) => extendGridLine(line, 0));
  }

  // move 0 y-axis label back so that it lines up properly with numbers with decimals
  const decimal = [...yAxisLabels].find((label) => /^(?!-)\d*\.\d*$/.test(label.textContent));
  const zero = [...yAxisLabels].find((label) => label.textContent === '0');
  if (decimal && zero) alignYAxisLabelsWithDecimals(decimal, zero);

  // recalculate rounded values
  // todo: y-axis labels align for bar charts, suffix/prefix
  if (options.type === 'bar' || options.type === 'column') {
    // const columnsG = findEls(seriesGroup, 'g');
    // //filter out markers g
    // const seriesRects = [...columnsG].filter(g => !g.getAttribute('class').includes('highcharts-markers'));
    // recalculate x-axis column/bar distance
    // set crisp to false - works fine for now
    // calculate percent value and apply pxPerPt to that value in rectsHeightCalculation function
    let percentValues = null;
    if (options.stacking === 'percent') {
      percentValues = getPercentValues(seriesItems);
    }
    if (options.type === 'column') {
      // rounding yAxis. Should start from the bottom beacause of stacked charts
      for (let i = seriesItems.length - 1; i >= 0; i--) {
        rectsHeightCalculation(i, zeroY, pxPerPt, seriesItems, options, percentValues);
      }
      // check if after column charts xAxis rounding fix - tspan innerText wasn't generated, but title inner text did.
      // ex: <text><tspan></tspan><title>some text</title></text>
      // if it happened text inside <title> will not show up in exported svg, only text in tspan will.
      [...chart.xAxis[0].labelGroup.element.childNodes].forEach((textNode) => {
        // check if title node was generated
        const children = textNode.childNodes;
        if (children.length === 2 && children[1].tagName === 'title') {
          textNode.textContent = textNode.childNodes[1].textContent;
          // textNode.childNodes[0].textContent = textNode.childNodes[1].textContent;
        }
      });
    } else {
      // bar normal charts
      for (let i = 0; i < seriesItems.length; i++) {
        rectsHeightCalculation(i, zeroY, pxPerPt, seriesItems, options, percentValues);
      }
      // return data;
    }
  } else {
    // line, area, step
    const zeroIdxs = chart.series.map((serie) => serie.data.findIndex((point) => point.y === 0));
    if (zeroIdxs.length && zeroY) {
      seriesItems.forEach((g, i) => {
        // group represents one serie
        const translateY = getTranslateY(g);
        // usually each group has one path, but if empty data points are present - multiple paths generated
        const paths = g.querySelectorAll('path');
        paths.forEach((path) => {
          const zeroIdx = zeroIdxs[i];
          if (zeroIdx > -1) {
            const pathD = path.getAttribute('d');
            const pathDArr = pathD.split(' ');
            const yValues = pathDArr.filter((value, i) => (i + 1) % 3 === 0);
            const zeroValue = +yValues[zeroIdx];
            const diff = zeroY - translateY - zeroValue;
            const { newPathDArr, needsUpdate } = pathDArr.reduce(
              (obj, value, i) => {
                if ((i + 1) % 3 !== 0 || diff > 1 || diff < -1) {
                  obj.newPathDArr.push(value);
                  return obj;
                }
                obj.newPathDArr.push(+value + diff);
                obj.needsUpdate = true;
                return obj;
              },
              { newPathDArr: [], needsUpdate: false }
            );
            if (needsUpdate) path.setAttribute('d', newPathDArr.join(' '));
          }
        });
      });
    }
  }
  // aligns ticks and shortens minor ticks
  if (options.type !== 'bar' && options.categoriesAreDates)
    alignTicks(chart.xAxis[0].tickPositions, chart.xAxis[0].ticks, options);

  // aligns plot band labels with the top y-axis label
  if (options.plotBands.length || options.plotBandPresets.length) {
    const plotBandsLabels = chart.xAxis[0]?.plotLinesAndBands?.map((band) => band.label.element);
    if (plotBandsLabels) {
      const topLabel = getSuffixLabel(chart, options);
      const topY = topLabel.getAttribute('y');
      plotBandsLabels.forEach((label) => {
        label.setAttribute('y', topY);
      });
    }
  }

  // annotation postrender print&digital
  if (options.annotations && options.annotations.length) {
    const { labelsGroup, shapesGroup, labels } = chart.annotations[0];
    const annotationG = chart.annotations[0].graphic;
    const allLabels = findEls(labelsGroup, 'g');
    const [sortedLabels, sortedShapes, sortedLabelsOptions] = sortAnnotations(
      shapesGroup,
      allLabels,
      labels
    );
    // gridLine needed for 'attachTo-category/date' selection
    const gridLine = getFirstGridLine(chart.yAxis[0].ticks);

    spaceOutAndDrawLines(
      options,
      sortedLabels,
      sortedShapes,
      sortedLabelsOptions,
      gridLine,
      annotationG,
      chart,
      yAxisLabels
    );
    // sort annotation's paths after drawing
    sortPaths(shapesGroup, annotationG);
  }

  if (options.type !== 'scatter') {
    if (options.type === 'bar') {
      alignBarLabels(options, chart);
      return;
    }
    alignLabels(options, chart);
  }

  // set data.xAxis.offset instead in axes-prerender line 382
  // if(options.print){
  // // prevent x-axis ticks from overlapping bottom grid line
  //   shiftTicksDown(ticks);
  // }
};

function alignYAxisLabelsWithDecimals(decimal, zero) {
  const decWidth = +decimal.getBBox().width;
  const zeroX = +zero.getAttribute('x');
  zero.setAttribute('x', zeroX - decWidth);
  zero.setAttribute('text-anchor', 'start');
}

function getValues(chart, tick, chartType) {
  const dIndex = chartType === 'bar' ? 1 : 2;
  return {
    pos: chart.yAxis[0].ticks[tick].pos,
    y: +chart.yAxis[0].ticks[tick].gridLine.element.getAttribute('d').split(' ')[dIndex],
  };
}

function getTopAndBottom(chart, chartType) {
  return chart.yAxis[0].tickPositions.reduce(
    (acc, tick) => {
      if (chart.yAxis[0].ticks[tick].isFirst) {
        acc.bottom = getValues(chart, tick, chartType);
      }
      if (chart.yAxis[0].ticks[tick].isLast) {
        acc.top = getValues(chart, tick, chartType);
      }
      return acc;
    },
    { top: {}, bottom: {} }
  );
}
