/* eslint-disable func-names */
import Highcharts from 'highcharts';
import datetime from './data/datetime';
import processOptions from './data/processOptions';
import * as preload from './style/preload';
import * as InnerChart from './inner';
import * as OuterChart from './outer';
import * as element from './util/element';
import { getSize } from './data/filterOptionsForSize';
import { fetchData, createDataParser } from '../../server/shared/marketDataHelpers';
import { getLastValue, parseDataColumn } from '../ui/util/marketdata';
import * as highchartsWrappers from './wrappers';

import { hasThumb, hasZT, hasWsjPrint } from './inner/utils';

// Store charts so they can be destroyed as needed.
const charts = [];

highchartsWrappers.applyModules(Highcharts);
highchartsWrappers.addExtensions(Highcharts);
highchartsWrappers.replaceMethods(Highcharts);

/**
 * Public API functions.
 */

function render(options, uploadFonts = true, done) {
  // for published auto-updating market data charts, fetch the latest data
  const { liveMarketData, isRolling, skipUpdate } = options;
  const mustUpdate = liveMarketData && isRolling && !skipUpdate;
  const renderAction = mustUpdate ? renderRolling : startRender;
  return renderAction(options, done, uploadFonts);
}

function renderRolling(options, done, uploadFonts) {
  const { tickers, frequency, start, end, isPercent, product, dateRange, singleIsPercent } =
    options;
  const instruments = tickers.map((ticker) => ticker.chartingSymbol);
  const isCustom = dateRange === 'Custom';
  const usePercent = (tickers.length > 1 && isPercent) || (tickers.length === 1 && singleIsPercent);
  const cb = createCallback(
    options,
    done,
    usePercent,
    isCustom,
    frequency,
    dateRange,
    end,
    uploadFonts
  );
  fetchData(instruments, start, end, frequency, isCustom, dateRange, true, product, cb);
}

function createCallback(
  options,
  done,
  isPercent,
  isCustom,
  frequency,
  dateRange,
  end,
  uploadFonts
) {
  return function (data) {
    const parseData = createDataParser(data, isPercent, isCustom, frequency, dateRange, end);
    const size = getSize(options);
    const sizeObj = options.sizes[size.id];
    const headings = options.headings || sizeObj.headings;
    const barData = [];
    const prevData = options.series[0].data;
    data.forEach((series, index) => {
      const [seriesArr, categories, date] = parseData(series, index);
      if (options.type === 'bar') {
        const lastValue = getLastValue(seriesArr);
        const { color } = prevData[index];
        if (color) {
          lastValue.color = color;
        }
        barData.push(lastValue);
      } else {
        const [updatedCategories, updatedData] =
          options.type === 'line'
            ? [categories, seriesArr]
            : [categories.slice(1), parseDataColumn(seriesArr, prevData)];
        options.categories = updatedCategories;
        const newIdx =
          options.legend.itemsInOrder && options.series.length > 1
            ? options.series.findIndex((s) => s.symbol === series.ticker)
            : index;
        options.series[newIdx].data = updatedData;
      }
      headings.date.text = `As of ${date}`;
    });
    if (options.type === 'bar') {
      options.series[0].data = barData;
    }
    options.annotations.filter((annotation) => options.categories.includes(annotation.category));
    startRender(options, done, uploadFonts);
  };
}

function startRender(options, done, uploadFonts) {
  done = typeof done === 'function' ? done : () => {};
  options = processOptions(options, Highcharts);
  const invalidated = options.invalidated && options.invalidated[options.size];
  if (invalidated) {
    const existChart = getExistChart(options.el).chart;
    // is it ever true?
    if (existChart && existChart.outer && existChart.inner) {
      renderInvalidated(existChart, options, done, uploadFonts);
      return;
    }
  }
  resetRender(options, done, uploadFonts);
}

function resetRender(options, done, uploadFonts) {
  destroy(options.el);
  // console.log(`CHART.${options.id}.fonts.START`);
  if (uploadFonts || (options.product === 'wsj' && options?.headings?.chartFont === 'ja')) {
    preload.fonts(options, () => {
      // also need to add a data attribute to element
      // when chart is done loading, for testing purposes
      const doneWithFlag = (params, x, y, height) => {
        done(params, x, y, height);
        params.container.dataset.rendered = true;
      };
      renderChart(options, doneWithFlag);
    });
  } else {
    renderChart(options, done);
  }
}

// Instead of re-rendering the entirety of both inner and outer charts, only
// the invalidate parts of re-rendered.
// TODO: Create preview/editing views and get this working again.
function renderInvalidated(rendered, options, done, uploadFonts) {
  if (__ALL__) {
    let invalidated = options.invalidated[options.size] || [];

    const invalidAll = [
      'chart/targets',
      'chart/height',
      'chart/annotations',
      // ];
      // const invalidChart = [
      'chart/data',
      'chart/type',
      'chart/series-names',
      'chart/hide-legend',
      'chart/axis',
      'chart/legend',
      'chart/stacking',
      'chart/height',
      'chart/colors',
      'headings/title',
    ];
    const invalidChart = [];

    // Some changes require a full chart re-render while others can force a
    // more limited re-render.
    const mustRender = function (invalidated, renderItems) {
      return true;
    };

    if (mustRender(invalidated, invalidAll)) {
      window.__charts_fonts_loaded__ = undefined;
      resetRender(options, done, uploadFonts);
      return;
    }

    // Some items only force a re-render under specific conditions. For example,
    // changes to series name should only trigger a re-render if the legend is
    // enabled since that is the only place where series names appear.
    invalidated = invalidated.filter((item) => {
      switch (item) {
        case 'chart/series-names':
          return options.legend.enabled;
        default:
          return true;
      }
    });

    const outerHeight = rendered.outerInfo.height;

    if (outerHeight !== rendered.outerInfo.height || mustRender(invalidated, invalidChart)) {
      // mustRenderColor(invalidated)) {
      destroyInner(rendered.inner);
      setTimeout(() => {
        renderInnerChart(options, rendered, rendered.outerInfo, done);
      }, 1);
    } else {
      InnerChart.renderInvalidated(rendered, options, invalidated);
      done(rendered.inner);
    }
  }
}

// The outer chart containers headlines and notes.
function renderChart(options, done) {
  // special print workfloaw for wsj charts
  if (hasWsjPrint(options.size)) renderPrintChart(options, done);
  else {
    const renderObj = { el: options.el, outer: null, inner: null, options };
    const chartObj = OuterChart.preRender(options);
    charts.push(renderObj);
    renderObj.outer = renderHighchart(options, chartObj, (highchart) => {
      const outer = OuterChart.postRender(highchart, options);
      renderObj.outerInfo = outer;
      if (outer.innerEl) {
        renderInnerChart(options, renderObj, outer, done);
      } else {
        done(null);
      }
    });
  }
}

function renderPrintChart(options, done) {
  const chartObj = InnerChart.preRender(options);
  getHighchart(options, chartObj).then((highchart) => {
    done(highchart);
  });
}

// The inner charts contains the actual chart.
function renderInnerChart(options, renderObj, outer, done) {
  const outerY = outer.y;
  options.width = outer.width;
  const fontSize = options.styles.normalFontSize;
  const shouldAdjustHeight = !hasThumb(options.size) && !hasZT(options.size);
  // TODO: simplify
  if (shouldAdjustHeight) {
    options.height = outer.height - 16;
  }
  const offset = 8;
  options.height += offset;
  options.spacing = createInnerSpacing(options, outer.spacing);
  const chartObj = InnerChart.preRender(options);
  getHighchart(options, chartObj).then((highchart) => {
    // postrender code rund on load
    // InnerChart.postRender(highchart, options);
    const hc = chartObj.chart.renderTo.querySelector('.highcharts-container');
    if (hc) {
      hc.style.position = 'absolute';
      hc.style.left = `${outer.x}px`;
      hc.style.top = `${outerY}px`;
      hc.style.overflow = 'visible';
      // this fixes tootips issue
      hc.style.width = `${options.width}px`;
      hc.style.height = `${options.height}px`;
      const svg = hc.querySelector('svg');
      svg.setAttribute('y', outerY);
      svg.setAttribute('x', outer.x);
      svg.setAttribute('height', Math.max(0, options.height + fontSize));
      // Todo: see if this causes issues
      // if (options.size.indexOf('print') === 0) {
      //   // This causes tooltip issues, but needs to be done (at the time) for
      //   // image export and svg download:
      //   outer.innerEl.appendChild(svg);
      //   element.removeChild(hc);
      // }
    }
    done(highchart, outer.x, outerY, Math.max(0, options.height + fontSize));
  });
}

function getHighchart(options, data) {
  return new Promise((resolve, reject) => resolve(renderHighchart(options, data)));
}

function createInnerSpacing(options, spacing) {
  const inner = spacing ? spacing.map((n) => n) : [0, 0, 0, 0];
  const { annotations, axes, product, styles, id } = options;
  const { units, showRecessions, showUkRecessions } = axes;
  if (
    annotations &&
    annotations.length &&
    (units.suffix.length > 2 || showRecessions || showUkRecessions)
  ) {
    const currentLineHeight = product === 'wsj' ? styles.annotationsLineHeight : styles.lineHeight;
    inner[0] = Math.max(0, inner[0] + currentLineHeight);
  }
  return inner;
}

// Render a Highcharts chart via either the standalone Highcharts adapter or
// jQuery, depending on the environment.
function renderHighchart(options, data, postRender) {
  if (options.product === 'fnlondon') {
    [Highcharts].forEach((H) => {
      H.seriesTypes.line.prototype.drawLegendSymbol = H.seriesTypes.area.prototype.drawLegendSymbol;
    });
  }
  const H = options.irregularDates ? Highcharts.stockChart : Highcharts.chart;
  return new H(data, postRender);
}

function destroy(el) {
  if (el) {
    const obj = getExistChart(el);
    if (obj.chart) {
      destroyOuter(obj.chart.outer, obj.chart.inner);
    }
    if (obj.index !== -1) {
      charts.splice(obj.index, 1);
    }
  } else {
    // charts.forEach(c => {
    //   if (c.el) {
    //     destroy(c.el);
    //   }
    // });
    while (charts.length) {
      destroy(charts[0].el);
    }
  }
}

function destroyOuter(outer, inner) {
  destroyInner(inner);
  if (outer) {
    try {
      destroyCommon(outer);
      outer.destroy();
    } catch (e) {
      // Highcharts error.... because older version?
      // console.warn(e);
    }
  }
}

function destroyInner(inner) {
  if (inner) {
    try {
      destroyCommon(inner);
      inner.destroy();
    } catch (e) {
      // Usually exception caused by no innerEl !?!
      // console.warn(e);
    }
  }
}

// The version of Highcharts used at DJ does not do this automatically.
function destroyCommon(chart) {
  if (chart.series) {
    chart.series.forEach((s) => {
      try {
        s.update({
          enableMouseTracking: false,
        });
      } catch (e) {
        //
      }
    });
  }
}

function getExistChart(el) {
  const existChartIndex = chartIndexOf(el);
  return {
    index: existChartIndex,
    chart: existChartIndex !== -1 ? charts[existChartIndex] : null,
  };
}

function chartIndexOf(el) {
  for (let i = 0; i < charts.length; i++) {
    if (charts[i].el === el) {
      return i;
    }
  }
  return -1;
}

export default { render, destroy, datetime, chart: getExistChart };
