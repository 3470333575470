import { INTERVAL_OPTIONS, Q_INTERVALS, INTERVALS } from './constants';

const { CENTURY, DECADE, HALFDECADE, YEAR, QUARTER, MONTH, DATE, DAY, HOUR, HALF_HOUR, MINUTE} = INTERVAL_OPTIONS;

export const findInterval = (majorValue, quarterlyResults) => {
  const intervals = quarterlyResults ? Q_INTERVALS : INTERVALS;
  const interval = intervals.find(({ name }) => {
    name === majorValue});
  if(interval) return interval.name;
  if(quarterlyResults) return QUARTER;
}

export const getMinor = (minorValue) => {
  switch(minorValue){
    case DECADE:
      return YEAR;
    case HALF_HOUR:
      return MINUTE;
    default:
      return minorValue
  }
}