// @ts-check
import { hasVideoGroup, hasPrint } from '../utils';

export const getLegendTopMargin = (options) => {
  const { id, type, horizontalBarType } = options;
  if (type === 'pie') return 0;

  const isVideo = hasVideoGroup(id);
  if (isVideo) {
    if (type === 'bar') {
      switch (horizontalBarType) {
        case 'aboveBar':
        case 'noScaleAndLeft':
          return 90;
        default:
          return 70;
      }
    }
    return 70;
  }
  if (type === 'bar') {
    switch (horizontalBarType) {
      case 'aboveBar':
      case 'noScaleAndLeft':
        return 20;
      default:
        return hasPrint(options.id) ? 10 : -10;
    }
  }

  return 30;
};

export const getLegendRightMargin = (options) => {
  if (options.type === 'pie') return -15;
  return options.id === 'print-wsj' ? 2 : 10;
};

export const getLegendPadding = ({ rightPositioned, isPrint }) => {
  if (rightPositioned) return 5;
  return isPrint ? 0 : 2;
};

// not used rn
export const getItemMarginTop = (id) => {
  switch (id) {
    case 'print-wsj':
      return 3;
    case 'b4u':
    case 'marketwatch-4u-inset':
      return 0;
    default:
      return -2;
  }
};

export const getItemDistance = (id) => {
  switch (id) {
    case 'print-wsj':
      return 6;
    case 'video':
      return 70;
    default:
      return 12;
  }
};

export const getItemMarginBottom = (options) => {
  const { id, type, product } = options;
  if (product === 'fnlondon') return 0;
  switch (id) {
    case 'print-wsj':
      return 0;
    case 'video':
      return type === 'bar' ? -70 : 5;
    default:
      return 5;
  }
};

export const getSymbolPadding = (id, colorChipsDisabled) => {
  if (colorChipsDisabled) return 0;
  switch (id) {
    case 'print-wsj':
      return 2;
    case 'video':
      return 25;
    default:
      return 7;
  }
};

export const getSymbolWidth = ({ product, isPrint, id, colorChipsDisabled }) => {
  if (colorChipsDisabled) return 0;
  if (isPrint) return 6;
  if (product === 'wsj') {
    switch (id) {
      case 'video':
        return 30;
      default:
        return 10;
    }
  } else return 9;
};

export const getSymbolHeight = ({ chartType, isPrint, id }) => {
  if (id === 'video') return 30;
  if (isPrint) return 6;
  if (chartType === 'scatter') return 11;
  return 12;
};

export const getSymbolRadius = (chartType, colorChipsDisabled, product) => {
  if (colorChipsDisabled) return 0;
  const hasRoundSymbol = ['scatter', 'pie'].includes(chartType) || product === 'fnlondon';
  return hasRoundSymbol ? 5.5 : 0;
};
