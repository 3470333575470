const VIDEO_GROUP_REGEX = /video/i;
const VIDEO_REGEX = /video/;
const TWITTER_VIDEO_REGEX = /twitterVideo/;
const ARTICLE_CROPS_REGEX = /u$|inset|video/i;
const WSJ_PROMO_CROPS_REGEX = /appfeed|homepage|soc|ipadFeed2305/i;
const BARRONS_PROMO_CROPS_REGEX =
  /barrons-4col-image|barrons-8col-image|barrons-10col-image|barrons-fullscreen-image/i;
const MARKETWATCH_PROMO_CROPS_REGEX =
  /marketwatch-MG|marketwatch-ZT|marketwatch-ZH|marketwatch-ZG|marketwatch-ZR|marketwatch-ZQ/i;
const MANSION_GLOBAL_PROMO_CROPS_REGEX =
  /mansionglobal-818|mansionglobal-720|mansionglobal-308|mansionglobal-480/i;
const FNLONDON_PROMO_CROPS_REGEX = /fn-small-image|fn-medium-image|fn-large-image/i;

const PRINT_CROPS_REGEX = /print/i;
const WSJ_PRINT_CROPS_REGEX = /print-wsj/i;
const SMALL_MW_REGEX = /thumb-marketwatch-MA|marketwatch-ZR/i;
const ZT_MW_REGEX = /marketwatch-ZT/i;
const THUMB_REGEX = /^thumb/i;

export {
  VIDEO_GROUP_REGEX,
  VIDEO_REGEX,
  TWITTER_VIDEO_REGEX,
  ARTICLE_CROPS_REGEX,
  WSJ_PROMO_CROPS_REGEX,
  BARRONS_PROMO_CROPS_REGEX,
  MARKETWATCH_PROMO_CROPS_REGEX,
  MANSION_GLOBAL_PROMO_CROPS_REGEX,
  FNLONDON_PROMO_CROPS_REGEX,
  PRINT_CROPS_REGEX,
  WSJ_PRINT_CROPS_REGEX,
  SMALL_MW_REGEX,
  ZT_MW_REGEX,
  THUMB_REGEX,
};
