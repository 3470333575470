/**
 * wraps an existing Highcharts prototype function
 * and allows to add custom code before or after it.
 * @see https://www.highcharts.com/docs/extending-highcharts/extending-highcharts#wrapping-prototype-functions
 * @param {Highcharts} H
 * @param {String} method
 * @param {String} funcToWrap
 * @param {Function} cb
 * @return {Void}
 */
const cbWrapper = (H, method, funcToWrap, cb) => {
  H.wrap(H[method].prototype, funcToWrap, cb);
};

/**
 * Utility to apply cb for Highcharts
 * @param {Highcharts} H
 * @param {Function} cb
 */
const wrapHighcharts = (H, cb) => {
  [H].forEach(cb);
};

export { cbWrapper, wrapHighcharts };
