// @ts-check
import { hasVideoGroup, hasWSJVideoGroup } from '../utils';

const getPlotLineLabelY = (topPositioned, isVideoGroup, styles) => {
  switch (topPositioned) {
    case false:
      // TODO: Create constant for value 32
      return isVideoGroup ? 32 : styles.normalFontSize + 2;
    default:
      return -7;
  }
};

const getPlotLineLabelX = (rightAligned, isVideoGroup, styles) => {
  switch (rightAligned) {
    case false:
      return isVideoGroup ? styles.videoNormalFontSize : -(styles.normalFontSize + 2);
    default:
      return styles.normalFontSize - 10;
  }
};

/**
 * @param  {string} option The option selected in the form
 * @param  {string} text The text that is going to be rendered
 * @param  {boolean} isVideoGroup
 * @param  {object} styles
 * @returns {object} Returns the props necessary to render the plotline
 */

const getLabelPropsVertical = (option, text, isVideoGroup, styles) => {
  const [position, align] = option.split(' ');
  const y = getPlotLineLabelY(position === 'top', isVideoGroup, styles);

  return {
    text,
    align,
    verticalAlign: position,
    x: align === 'right' ? -10 : 0,
    y,
  };
};

/**
 * @param  {string} option The option selected in the form
 * @param  {string} text The text that is going to be rendered
 * @param  {boolean} isVideoGroup
 * @param  {object} styles
 * @returns {object} Returns the props necessary to render the plotline
 */

const getLabelPropsHorizontal = (option, text, isVideoGroup, styles) => {
  const [position, align] = option.split(' ');

  const x = getPlotLineLabelX(align === 'right', isVideoGroup, styles);
  return {
    text,
    verticalAlign: position,
    align: position === 'top' ? 'end' : 'right',
    x,
    y: 0,
  };
};

const getPlotLineLabelProps = (plotLine, chartType, isVideoGroup, styles, isWSJVideoGroup) =>
  chartType === 'bar'
    ? getLabelPropsHorizontal(
        plotLine.plotLineLabelPosition,
        isWSJVideoGroup && typeof plotLine.plotLineLabel === 'string'
          ? plotLine.plotLineLabel.toUpperCase?.()
          : plotLine.plotLineLabel,
        isVideoGroup,
        styles
      )
    : getLabelPropsVertical(
        plotLine.plotLineLabelPosition,
        isWSJVideoGroup && typeof plotLine.plotLineLabel === 'string'
          ? plotLine.plotLineLabel.toUpperCase?.()
          : plotLine.plotLineLabel,
        isVideoGroup,
        styles
      );

const getAnnotation = (plotLine, chartType, isVideoGroup, styles, isWSJVideoGroup) => {
  const label = getPlotLineLabelProps(plotLine, chartType, isVideoGroup, styles, isWSJVideoGroup);
  // TODO: see if color index can be passed from ui
  const colorIdx = styles.annotationsPalette.indexOf(plotLine.plotLineColor);
  const horizAnnotClassName = `horizontal-annotation-${colorIdx} ${
    plotLine.plotLineDashStyle === 'dot' ? 'dotted' : ''
  }`;
  return {
    className: horizAnnotClassName,
    value: plotLine.plotLineValue,
    zIndex: 5,
    label,
  };
};

/**
 * Vertical annotations are part of yaxis plot lines
 * and run inside axes.prerender
 * @see https://api.highcharts.com/highcharts/yAxis.plotLines
 * @param {ChartlosOptions} options - chartlos options
 * @returns {[]}
 */
export const setVerticalAnnotations = (options) => {
  const { id: cropSize, type: chartType, styles } = options;
  const isVideoGroup = hasVideoGroup(cropSize);
  return options.plotLines[0].plotLineValue
    ? options.plotLines.reduce((accum, plotLine) => {
        if (plotLine.plotLineValue) {
          accum.push(
            getAnnotation(plotLine, chartType, isVideoGroup, styles, hasWSJVideoGroup(options))
          );
        }
        return accum;
      }, [])
    : [];
};
