/* eslint-disable no-param-reassign */
// @ts-check
import * as element from '../../util/element';
import { checkForceLegendTop } from '../../data/processSeries';
import {
  getLegendPadding,
  getItemDistance,
  getItemMarginBottom,
  getSymbolPadding,
  getSymbolWidth,
  getSymbolHeight,
  getSymbolRadius,
  getLegendTopMargin,
  getLegendRightMargin,
} from './legend.constants';

import { isLegendEnabled, getLegendLayout, compileText } from './legend.utils';
import { hasWSJVideoGroup } from '../utils';

/**
 * Translate chartlos legend options into
 * format highcharts can understand
 * based on options.legend.layout.
 * Sets layout, align, verticalAlign, margin
 * and width for right positioned legend
 * @see https://api.highcharts.com/highcharts/legend
 * @param {*} data
 * @param {ChartlosOptions} options - chartlos options
 * @return {*} modified data object
 */
const positionLegend = (data, options) => {
  options.legend.layout = getLegendLayout(options);

  switch (options.legend.layout) {
    case 'top':
      data.legend.layout = 'horizontal';
      data.legend.align = 'left';
      data.legend.verticalAlign = 'top';
      data.legend.margin = getLegendTopMargin(options);
      data.legend.showColorChips = true;
      break;
    case 'bottom':
      data.legend.layout = 'vertical';
      data.legend.align = 'center';
      data.legend.verticalAlign = 'bottom';
      data.legend.showColorChips = true;
      break;
    default:
      // 'right':
      data.legend.layout = ['line', 'area'].includes(options.type) ? 'proximate' : 'vertical';
      data.legend.align = 'right';
      data.legend.verticalAlign = 'middle';
      data.legend.margin = getLegendRightMargin(options);
      data.legend.width = '30%';
      data.legend.showColorChips = options.legend.showColorChips;
      break;
  }
  return data;
};

/**
 * Translate chartlos legend options into
 * format highcharts can understand.
 * These options cannot be addressed through css
 * Sets padding, itemDistance, itemMarginBottom,
 * symbolPadding, symbolWidth, symbolHeight,
 * symbolRadius, alignColumns
 * @see https://api.highcharts.com/highcharts/legend
 * @param {*} data
 * @param {ChartlosOptions} options - chartlos options
 * @return {*} modified data object
 */
const styleLegend = (data, options) => {
  const { print: isPrint, id, product, legend, type: chartType } = options;
  const rightPositioned = data.legend.align === 'right';
  const colorChipsDisabled = rightPositioned && !legend.showColorChips;

  data.legend.padding = getLegendPadding({ rightPositioned, isPrint });
  data.legend.itemDistance = getItemDistance(id);
  data.legend.itemMarginBottom = getItemMarginBottom(options);
  data.legend.symbolPadding = getSymbolPadding(id, colorChipsDisabled);
  data.legend.symbolWidth = getSymbolWidth({
    product,
    isPrint,
    id,
    colorChipsDisabled,
  });
  data.legend.symbolHeight = getSymbolHeight({ chartType, isPrint, id });
  data.legend.symbolRadius = getSymbolRadius(chartType, colorChipsDisabled, product);
  data.legend.alignColumns = true;

  return data;
};

/**
 * Legend support markdown with following rules:
 * <span style="font-weight:500">word</span>: Span around a word makes it bold
 * {category}: Last data point's category or date
 * {series}: Corresponding series name
 * {symbol}: Ticker symbol for live market data
 * {value}: Last data point's unformatted value
 * {point}: Last data point's formatted value
 * <br>: line break
 * @see https://api.highcharts.com/highcharts/legend.labelFormatter
 * @param {*} data
 * @param {*} options
 */
const compileLegendText = (data, options) => {
  // eslint-disable-next-line func-names
  data.legend.labelFormatter = function () {
    const openTag = '<b>';
    const closeTag = '</b>';
    const series = options.series.find((s) => s.name === this.name);
    // TO DO: compileText function seems to be broken
    const text = compileText(series, this, options);
    const textBr = element.replaceLineBreaks(hasWSJVideoGroup(options) ? text.toUpperCase() : text);
    const lines = textBr.split('<br>');
    const boldLegend = element.boldText();
    const bolded = lines.map((line) => boldLegend(line, openTag, closeTag));
    return bolded.join('<br>');
  };
};

/**
 * Translate legend settings made by chartlos users
 * into highcharts langugage.
 * @see https://api.highcharts.com/highcharts/legend
 * @param {Object} data - config options to pass to highcharts
 * @param {ChartlosOptions} options - chartlos options
 * @returns {Object} mutable data object
 */
export const preRender = (data, options) => {
  data.legend = {
    enabled: isLegendEnabled(options),
    navigation: { enabled: false },
  };
  if (data.legend.enabled) {
    options.forceLegendTop = checkForceLegendTop(options);
    positionLegend(data, options);
    styleLegend(data, options);
    /** For pie charts, legend labels come from the name of each data point of the first series.
     * Since chartlos logic setup to read series names rather than name of each data point, disabling the feature for v2
     **/
    if (data.chart.type !== 'pie') compileLegendText(data, options);
  }
  return data;
};
