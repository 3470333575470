/**
 * The distance between the outer edge of the chart and the content.
 * The numbers in the array designate top, right, bottom and left respectively.
 * By default crops do not have padding
 * except social promo crop (20px around) and video crops
 * @param {ChartlosOptions} options
 * @return {number[]}
 */
function spacing(options) {
  switch (options.size) {
    case 'soc':
      return [20, 20, 20, 20];
    case 'video':
      return [120, 220, 140, 120];
    case 'verticalVideo':
      return [100, 100, 400, 100];
    case 'twitterVideo':
      return [40, 60, 60, 60];
    default:
      return [0, 0, 0, 0];
  }
}

export default spacing;
