import { MAX_4U_WIDTH, MAX_NARROW_WIDTH_PRINT } from './constants';

/**
 * Processes and build the chart series data.
 */

export default function processSeries(options) {
  // Prepare series for Highcharts.
  options.originalSeries = options.series.map((currentSeries) =>
    currentSeries.data.map((data) => ({ ...data }))
  );
  const { liveMarketData, colorMode, type, step } = options;
  let data = options.series.map((item, index) => {
    const {
      name,
      symbol,
      country,
      decimals,
      prefix,
      suffix,
      markdown,
      showInLegend,
      tickerType,
      colorIndex,
      negativeColorIdx,
      dashStyle,
      opacity,
    } = item;

    const isThreshold = colorMode === 'THRESHOLD' && (type === 'column' || type === 'bar');
    const isDotted = !step && dashStyle === 'dotted';
    const itemData = isThreshold ? setCustomNegativeClass(item.data) : item.data;

    const series = {
      className: createClassName(isThreshold, negativeColorIdx, options, opacity, isDotted, index),
      zoneAxis: type === 'line' ? 'x' : 'y',
      zones: getZones(options, item.data, item.color),
      zIndex: getZIndex(liveMarketData, tickerType),
      data: itemData,
      name,
      symbol,
      country,
      decimals,
      prefix,
      suffix,
      step: step ? 'left' : null,
      markdown,
      showInLegend,
      animation: false,
      colorIndex,
      dashStyle: step || dashStyle !== 'dotted' ? 'solid' : 'dot',
      negativeColor: isThreshold,
      events: {
        legendItemClick() {
          return false;
        },
      },
      turboThreshold: 0,
      states: {
        hover: {
          enabled: type === 'line',
        },
      },
    };
    return series;
  });
  if (options.categoriesAreMonthly && type === 'column') {
    startOfMonth(data);
  }
  // Customize the data for the particular chart type.
  switch (type) {
    case 'bar':
      // Save data to do this later, when options.styles.lineHeight is known.
      // return truncateData(options, data);
      options.series = data; // sortData(data, options);
      return options;
    case 'column':
      const rightLegend = options.legend.layout === 'right';
      const reverseLegend = needsReverseLegend(rightLegend, data);
      data = options.stacking ? reverseDataOrder(data, reverseLegend) : data;
      break;
    case 'line':
      data = reverseDataOrder(data);
      break;
    case 'pie':
      data = sortData(data, options);
      break;
  }

  options.series = data; // sortData(data, options);
  for (let i = 0; i < options.series.length; i++) {
    const seriesData = options.series[i].data;
    for (let z = 0; z < seriesData.length; z++) {
      seriesData[z].index = z;
    }
  }
  return options;
}

export function checkForceLegendTop(options) {
  return (
    options.id === 'video' ||
    options.id === 'verticalVideo' ||
    options.id === 'twitterVideo' ||
    (options.legend.layout === 'default' &&
      (options.type === 'column' ||
        options.type === 'bar' ||
        (options.print && options.width <= MAX_NARROW_WIDTH_PRINT) ||
        (!options.print && options.width <= MAX_4U_WIDTH)))
  );
}

export function convertToRGBA(color, opacity = 100) {
  let rgb = '';
  for (let i = 1; i <= 5; i += 2) {
    rgb += `${parseInt(color.slice(i, i + 2), 16)},`;
  }
  const percent = opacity / 100;
  return `rgba(${rgb}${percent})`;
}

function createClassName(isThreshold, negativeColorIdx, options, opacity, isDotted, index) {
  const classes = [];
  const { type, stacking } = options;
  const stacked =
    (stacking && ['column', 'bar'].includes(type)) || (type === 'area' && index !== 0);
  if (stacked) {
    classes.push('stacked');
  }
  if (isThreshold) {
    classes.push(`threshold negative-color-${negativeColorIdx}`);
  }
  if (isDotted) {
    classes.push('dotted');
  }
  if (type === 'scatter') classes.push(`opacity-${opacity || 100}`);
  if (classes.length) return classes.join(' ');
}

function addClassToIndividualSeries(individualSeries, className) {
  if (individualSeries.className)
    individualSeries.className = `${individualSeries.className} ${className}`;
  else individualSeries.className = `${className}`;
  return individualSeries;
}

function setCustomNegativeClass(data) {
  return data.map((dataPoint) => {
    if (dataPoint.colorIndex === undefined) return dataPoint;
    return addClassToIndividualSeries(dataPoint, 'custom-negative');
  });
}

function needsReverseLegend(rightLegend, data) {
  const lastSeriesFirstValue = data[data.length - 1]?.data[0]?.y;
  const isNegative = lastSeriesFirstValue < 0;
  return rightLegend && !isNegative;
}

function getZIndex(liveMarketData, tickerType) {
  if (liveMarketData) {
    return tickerType === 'Index' ? 1 : 2;
  }
}

function getZones(options, data, seriesColor) {
  if (options.type === 'line' && options.connectEmptyDataPoints === 'dotted') {
    const isVideoGroup =
      options.id === 'video' || options.id === 'twitterVideo' || options.id === 'verticalVideo';
    let hasData = data[0].y !== null;
    return data
      .map((point, index) => {
        if ((hasData && point.y === null) || (!hasData && point.y !== null)) {
          const [offset, className] = hasData
            ? [1, 'solid']
            : noDataOptions(isVideoGroup, seriesColor);
          const value = data[index - offset].x;
          hasData = !hasData;
          return { value, className };
        }
      })
      .filter((zone) => zone);
  }
}

function noDataOptions(isVideoGroup, seriesColor) {
  const attr = isVideoGroup ? 'opacity-50' : 'dotted';
  return [0, attr];
}

// For line charts, first column should be top layer. This also reverses the
// legend items, so that must be fixed separately.
function reverseDataOrder(data, reverseLegend = false) {
  const dataLen = data.length;
  data.forEach((item, index) => {
    item.index = dataLen - index - 1;
    item.legendIndex = reverseLegend ? dataLen - index - 1 : index;
  });
  return data;
}

function startOfMonth(data) {
  // for column charts with one date per month, shift x value to first of month
  data.forEach((series) => {
    series.data.forEach((position) => {
      const date = new Date(position.x);
      date.setUTCDate(1);
      position.x = date.valueOf();
    });
  });
}

/**
 * Sorts data in descending order in place for donut charts.
 * Adds "name" property to ensure proper data label rendering and
 * "colorIndex" to ensure highcharts render correct class names.
 * @param {[{data: [{y: number}]}]} data
 * @return {[{data: [{y: number}]}]} modified data
 */
function sortData(data, options) {
  data.forEach((series) => {
    // this ensures correct data label is attached to each data point
    series.data.forEach((s, i) => (s.name = options.categories[0][i]));
    // sort data in descending order for donut charts
    series.data.sort((a, b) => {
      if (a.y === null && b.y === null) return 0;
      if (a.y === null) return 1;
      if (b.y === null) return -1;
      return b.y - a.y;
    });
    // apply color index to sorted data points
    series.data.forEach((s, i) => (s.colorIndex = series.colorIndex + i));
  });
  return data;
}

// TO DO: remove code below not used i think
// For horizontal bar charts, removes data to improve visual appearance.
// export function truncateData(options, data) {
//   // Guestimate the maximum data rows that will fit.
//   const maxRows = Math.floor(options.height /
//     (parseFloat(options.styles.lineHeight.large) * 1.5));
//   // Remove data so rows does not exceed maxRows.
//   const overflow = Math.ceil(data[0].data.length / maxRows);
//   const originalCategories = options.categories ?
//     options.categories.slice() : null;
//   let categories = [];
//   if (overflow > 1) {
//     data.forEach((item, index) => {
//       let items = [];
//       let n = 0;
//       while (n < item.data.length) {
//         if (index === 0 && options.categories) {
//           categories.push(originalCategories[n]);
//           options.categories = categories;
//         }
//         items.push(item.data[n]);
//         n += overflow;
//       }
//       item.data = items;
//     });
//   }
//   options.series = data;
//   return options;
// }
