// @ts-check
// in progress
import '../../typedefs';
import getSpacing, { getPrintSpacing } from './spacing.utils';
import { checkForceLegendTop } from '../../data/processSeries';
import { hasWsjPrint } from '../utils';
// this will be expanded

/**
 * The distance between inner and outer charts.
 * For print - distance between chart container and headlines/credits
 * The numbers in the array designate top, right, bottom and left respectively.
 * @see https://api.highcharts.com/highcharts/chart.spacing
 * @export
 * @param {ChartlosOptions} options - chartos options
 * @returns {number[]} - Spacing around inner chart
 */
const chartSpacing = (options) => {
  if (hasWsjPrint(options.size)) {
    const spacing = getPrintSpacing(options.type);
    return spacing;
  }
  // eslint-disable-next-line no-undef

  const hasAnnotation = options.annotations && !!options.annotations.length;
  const hasRecessions = options.axes.showRecessions || options.axes.showUkRecessions;
  const legendLabelEnabled = options.series.some((s) => s.showInLegend);
  const enabled = options.id === 'twitterVideo' ? false : options.legend.enabled;
  const isLegendTop =
    enabled &&
    legendLabelEnabled &&
    (checkForceLegendTop(options) || options.legend.layout === 'top');
  const spacing = getSpacing({
    product: options.product,
    id: options.id,
    hasAnnotation,
    isLegendTop,
    chartType: options.type,
    horizontalBarType: options.horizontalBarType,
    hasRecessions,
    isPrint: options.print,
    styles: options.styles,
  });
  return spacing;
};

export default chartSpacing;
