import * as line from './line';
import * as area from './area';
import * as bar from './bar';
import * as scatter from './scatter';
import * as pie from './pie';
/**
 * Updates options for specific chart types.
 */
export function preRender(data, options) {
  switch (options.type) {
    case 'line':
      return line.preRender(data, options);
    case 'area':
      return area.preRender(data, options);
    case 'scatter':
      return scatter.preRender(data, options);
    case 'bar':
    case 'column':
      return bar.preRender(data, options);
    case 'pie':
      return pie.preRender(data, options);
    default:
      return data;
  }
}

export function renderInvalidated(rendered, options, invalidated) {
  if (__ALL__) {
    switch (options.type) {
      case 'line':
        return line.renderInvalidated(rendered, options, invalidated);
      case 'area':
        return area.renderInvalidated(rendered, options, invalidated);
      case 'bar':
      case 'column':
        return bar.renderInvalidated(rendered, options, invalidated);
    }
    return false;
  }
}
