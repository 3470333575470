import { MONTHS, MONTHS_SHORT } from './constants';
import datetime from './datetime';
import { addZeroes } from './zero';

/**
 * Processes and builds the chart categories.
 */
export default function processCategories(options) {
  if (Array.isArray(options.categories)) {
    options.maxDataLength = getMaxDataLength(options);
    options.originalCategories = options.categories.slice();
    if (options.categoriesAreDates && options.categories.length > 1) {
      return formatAsDates(options);
    } else {
      return formatAsCategories(options);
    }
  } else {
    options = writeDefaultCategories(options);
  }
  return options;
}


function getMaxDataLength(options) {
  let maxLen = 0;
  options.series.forEach(series => {
    maxLen = Math.max(maxLen, series.data.length);
  });
  return maxLen;
}


// Makes the index of each row into a default category value.
function writeDefaultCategories(options) {
  options.categories = [[]];
  for (let i = 0; i < options.maxDataLength; i++) {
    options.categories[0][i] = i;
  }
  options.originalCategories = options.categories[0].slice();
  return options;
}


// Returns the categories as is, but wrapped in an array as the axes module needs.
function formatAsCategories(options) {
  options.longCategories = options.originalCategories.slice(0);
  options.categories = [options.categories];
  return options;
}


// Casts the categories from strings into dates.
function formatAsDates(options) {
  const { dates } = options;
  options.fiscalYears = !!options.datesAll.fiscalYear;
  options.quarterlyResults = datetime.allQuarterly(dates);
  options.longCategories = createLongCategories(options, dates);
  options.categories = [options.quarterlyResults ? formatQ(dates) :
    options.longCategories];
  return options;
}

export function createLongCategories(options, dates) {
  const { quarterlyResults, fiscalYears } = options;
  const action = quarterlyResults || fiscalYears ?
    formatQLong : formatLong;
  return action(dates);
}

// For tooltips: standard dates/times.
function formatLong(dates) {
  return dates.map(date => {
    let str = formatDateLong(date);
    return formatTimeLong(date, str);
  });
}


function formatDateLong(date) {
  if (date.month !== undefined && date.date && date.year) {
    return [
      MONTHS[date.month],
      date.date + ',',
      date.year
    ].join(' ');
  } else if (date.month !== undefined && date.date) {
    return [
      MONTHS[date.month],
      date.date
    ].join(' ');
  } else if (date.month !== undefined && date.year) {
    return [
      MONTHS[date.month],
      date.year
    ].join(' ');
  } else if (date.month !== undefined) {
    return MONTHS[date.month];
  } else if (date.year) {
    return date.year.toString();
  }
  return null;
}


function formatTimeLong(date, str) {
  if (date.hour !== undefined) {
    let h = '';
    let a = '';
    str = str ? (str + ' ') : '';
    if (date.a === 'a' || date.a === 'p') {
      h = (date.hour % 12) + '';
      h = (h === '0') ? '12' : h;
      a = date.a;
    } else {
      if (date.hour === 0) {
        h = '12';
        a = 'a';
      } else if (date.hour === 12) {
        h = '12';
        a = 'p';
      } else if (date.hour > 12) {
        h = (date.hour % 12) + '';
        a = 'p';
      } else {
        // same as: if (date.hour < 12) {
        h = date.hour + '';
        a = 'a'
      }
    }
    str += h;
    if (date.minute) {
      str += ':' + addZeroes(date.minute, 2);
    }
    if (date.second) {
      str += ':' + addZeroes(date.second, 2);
    }
    if (a) {
      str += ' ' + ((date.a === 'a') ? 'a.m.' : 'p.m.');
    }
    if (isNoon(str, date)) {
      str = 'noon';
    }
    if (date.z) {
      str += ' ' + date.z;
    }
  }
  return str;
}


function isNoon(str, date) {
  return (date.minute === undefined && /^12/.test(str)) ||
    (date.second === undefined && /^12:00/.test(str)) ||
    /^12:00:00/.test(str);
}


// Returns array of quarterly/half year categories.
function formatQ(dates, long) {
  return dates.map((item, index) => formatQText(item, index, long));
}

export function formatQText(item, index, long) {
  let str = '';
  str += item.quarter || '';
  str += item.half || '';
  str += (item.year !== undefined) ? ' ' : '';
  str += ((index === 0 || long) && item.fiscalYear) ? 'FY' : ''
  str += item.year || '';
  return str;
}

// For tooltips: quarterly results.
function formatQLong(dates) {
  return formatQ(dates, true);
}