import {
  getStyles,
  getHedDekSpacing,
  getHedOrDeKSubdekSpacing,
  getDy,
  getStartY,
  getCreditsHeight,
  getCreditYAddition,
} from './constants';
import { checkJapanese, japaneseFont } from '../../ui/util/japaneseCharFinder';

function findEls(group, type) {
  return group.element.querySelectorAll(type);
}
//text fields (headings, credits)
export function handleHeadings(options, chart, R) {
  const { renderer } = chart;
  //@returns obj with title, dek, subdek, subdekPromo, note, source with text value and <br>'s inserted or with false if heading doesn't exist
  const headlines = getFormattedHeadlines(options, R);
  //two wrapper groups
  chart.headings = createGroup(renderer, 'headings');
  chart.credits = createGroup(renderer, 'credits');

  const { headings: headingsGroup, credits: creditsGroup } = chart;

  //create individual group for each heading if exist
  const groups = {};
  const groupHeights = {};
  for (let heading in headlines) {
    const credits = ['date', 'note', 'source', 'notePromo', 'sourcePromo'].includes(heading);
    if (headlines[heading]) {
      if (heading === 'date' && options.print) continue;
      else
        groups[`${heading}Group`] = createGroup(
          renderer,
          `${heading}Group`,
          credits ? creditsGroup : headingsGroup
        );
    }
  }

  const {
    titleGroup,
    dekGroup,
    subdekGroup,
    subdekPromoGroup,
    dateGroup,
    noteGroup,
    sourceGroup,
    notePromoGroup,
    sourcePromoGroup,
  } = groups;
  const padding = options.id === 'soc' ? 20 : 0;
  //because font sizes are diffrent as well as starting 'y'
  const startY = getStartY(!!titleGroup, options);

  const hedDekSpacing = titleGroup && dekGroup ? getHedDekSpacing(options) : 0;
  //digital for 15px font 7 break is default adding 1 to get 20

  const hedOrDekSubdekSpacing = getHedOrDeKSubdekSpacing(titleGroup, dekGroup, options);
  //create textElement for each group, apply proper css, calculate height of the group
  for (let group in groups) {
    const type = ['titleGroup', 'dekGroup', 'subdekGroup', 'subdekPromoGroup'].includes(group)
      ? 'headings'
      : 'credits';
    const currGroup = groups[group];
    const fieldName = group.slice(0, -5);
    let x = padding;
    let y = startY;
    const attributes = {
      class: fieldName,
    };
    //because dek for print always starts at 24 if hed exist
    if (fieldName === 'dek')
      y = options.print && titleGroup ? 24 : startY + (groupHeights.title || 0) + hedDekSpacing;
    if (fieldName === 'subdek' || fieldName === 'subdekPromo') {
      y =
        startY +
        (groupHeights.title || 0) +
        hedDekSpacing +
        (groupHeights.dek || 0) +
        hedOrDekSubdekSpacing;
      attributes['class'] = `${
        titleGroup && subdekGroup && !dekGroup ? '' : 'subdek--bold '
      }subdek`;
    }
    if (fieldName === 'date') {
      attributes['class'] = 'rolling-date';
      attributes['text-anchor'] = 'end';
      x = options.width - padding;
    }
    if (fieldName === 'notePromo' || fieldName === 'sourcePromo') {
      attributes['class'] = fieldName.slice(0, -5);
    }
    createTextElement(renderer, headlines[fieldName], x, y, attributes, currGroup);
    applyDy(currGroup, getDy(options, type));
    groupHeights[fieldName] = currGroup.getBBox().height;
  }

  //set credits <text> 'y' after all text elements were created
  Object.keys(groups).forEach((group) => {
    const creditGroup = [
      'dateGroup',
      'noteGroup',
      'sourceGroup',
      'notePromoGroup',
      'sourcePromoGroup',
    ].includes(group);
    if (creditGroup) {
      const currGroup = groups[group];
      setCreditsY(currGroup, group, groups, groupHeights, options);
    }
  });

  const creditsHeight = getCreditsHeight(groups, creditsGroup, options);
  const headingsHeight = headingsGroup.getBBox().height + padding;
  return {
    headingsHeight,
    creditsHeight,
  };
}

//helper function for handleHeadings func
function getFormattedHeadlines(options, R) {
  //@returns text with right breaks or false

  const formattedObj = {
    title: getHeadingBreak(R, 'title', options),
    dek: getHeadingBreak(R, 'dek', options),
    subdek: getHeadingBreak(R, 'subdek', options),
    date: getHeadingBreak(R, 'date', options),
    note: getHeadingBreak(R, 'note', options),
    source: getHeadingBreak(R, 'source', options),
  };
  if (options.print || options.product !== 'wsj') return formattedObj;
  else {
    formattedObj.subdekPromo = getHeadingBreak(R, 'subdekPromo', options);
    formattedObj.notePromo = getHeadingBreak(R, 'notePromo', options);
    formattedObj.sourcePromo = getHeadingBreak(R, 'sourcePromo', options);
    return formattedObj;
  }
}

function createGroup(renderer, className, parentGroup = '') {
  return renderer.g().attr({ class: className }).add(parentGroup);
}

function getHeadingBreak(R, name, options) {
  const text = checkHeading(options.headings[name], R, name);
  if (!text) return false;
  const hasJapanese = checkJapanese(text);
  const font = hasJapanese ? japaneseFont : getStyles(options, name);
  // soc padding 10 + 10
  const width = options.width - (options.id === 'soc' ? 20 : 0);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const splitted = text.split(' ');
  let result = [];
  let textResult = '';
  splitted.forEach((word) => {
    result = [...result, word];
    if (context.measureText(result.join(' ')).width >= width) {
      const res = result.slice(0, -1);
      textResult += [...res, '<br>'].join(' ');
      result = [word];
    }
  });
  textResult += result.join(' ');
  return textResult;
}

function createTextElement(renderer, text, x, y, attributes, parentGroup) {
  renderer.text(text, x, y).attr(attributes).add(parentGroup);
}

export function applyDy(group, dy) {
  const titleTSpans = findEls(group, 'tspan');
  //skip first line start from second
  for (let i = 0; i < titleTSpans.length; i++) {
    const hasLineBreak = titleTSpans[i].getAttribute('dy');
    if (hasLineBreak) titleTSpans[i].setAttribute('dy', dy);
  }
}

function setCreditsY(currGroup, group, groups, groupHeights, options) {
  //remove 'Group' at the end
  const name = group.slice(0, -5);
  const creditType = getCreditType(groups);
  const y = getCreditY(name, creditType, options, groupHeights);
  currGroup.element
    .querySelector('text')
    .setAttribute('y', y + getCreditYAddition(name, creditType, options));
}

function getCreditType(groups) {
  const { noteGroup, notePromoGroup, sourceGroup, sourcePromoGroup, dateGroup } = groups;
  if (dateGroup && (noteGroup || notePromoGroup) && (sourceGroup || sourcePromoGroup))
    return 'dateNoteSource';
  if ((noteGroup || notePromoGroup) && (sourceGroup || sourcePromoGroup)) return 'noteSource';
  if (dateGroup && (noteGroup || notePromoGroup)) return 'dateNote';
  if (dateGroup && (sourceGroup || sourcePromoGroup)) return 'dateSource';
  if (noteGroup || notePromoGroup) return 'note';
  if (sourceGroup || sourcePromoGroup) return 'source';
  if (dateGroup) return 'date';
}

function getCreditY(name, creditType, options, groupHeights) {
  const { note, notePromo, source, sourcePromo, date } = groupHeights;
  // ensures bottom padding around soc crop
  const height = options.height - (options.id === 'soc' ? 20 : 0);
  switch (creditType) {
    case 'dateNoteSource':
      switch (name) {
        case 'date':
          return height - date - (note || notePromo) - (source || sourcePromo);
        case 'note':
        case 'notePromo':
          return height - (note || notePromo) - (source || sourcePromo) + 1.5;
        case 'source':
        case 'sourcePromo':
          return height - (source || sourcePromo) + 1.5;
      }
    case 'noteSource':
      switch (name) {
        case 'note':
        case 'notePromo':
          return height - (note || notePromo) - (source || sourcePromo) + 1.5;
        case 'source':
        case 'sourcePromo':
          return height - (source || sourcePromo) + 1.5;
      }
    case 'dateNote':
      switch (name) {
        case 'date':
          return height - date - (note || notePromo);
        case 'note':
        case 'notePromo':
          return height - (note || notePromo) + 4.5;
      }
    case 'dateSource':
      switch (name) {
        case 'date':
          return height - date - (source || sourcePromo);
        case 'source':
        case 'sourcePromo':
          return height - (source || sourcePromo) + 1.5;
      }
    case 'note':
    case 'notePromo':
      return height - (note || notePromo) + 4.5;
    case 'source':
    case 'sourcePromo':
      return height - (source || sourcePromo) + 1.5;
    case 'date':
      return height - date + (options.id === 'soc' ? -4 : 9.5);
  }
}

function checkHeading(heading, R, name) {
  if (heading.enabled && heading.text && R[name]) return heading.text;
  return false;
}

//extends y gridlines
export function extendGridLine(path, start = 0) {
  const d = path.getAttribute('d');
  if (d) {
    const dArr = d.split(' ');
    dArr[1] = start;
    const newD = dArr.join(' ');
    path.setAttribute('d', newD);
  }
}

// utils

export function findRects(group) {
  return findEls(group, 'rect');
}

export function findTexts(group) {
  return findEls(group, 'text');
}

export function getValue(rect) {
  const valueClass = rect
    .getAttribute('class')
    .split(' ')
    .find((className) => className.includes('value'));
  const value = +valueClass.slice(valueClass.indexOf('-') + 1);
  return value;
}

export function getPxPerPt(topY, bottomY, topValue, bottomValue, chartType) {
  const numerator = chartType === 'bar' ? topY - bottomY : bottomY - topY;
  return Math.abs(numerator / (topValue - bottomValue));
}

export function getTranslateY(group) {
  const transform = group.getAttribute('transform');
  const i1 = transform.indexOf(',');
  const i2 = transform.indexOf(')');
  const translateY = +transform.slice(i1 + 1, i2);
  return translateY;
}
export function getTranslateX(group) {
  const transform = group.getAttribute('transform');
  const i1 = transform.indexOf('(');
  const i2 = transform.indexOf(',');
  const translateX = +transform.slice(i1 + 1, i2);
  return translateX;
}

export function getSeriesRects(group) {
  return group.querySelectorAll('rect');
}

export function getSeriesAttribute(group, idx, attribute) {
  return +group.querySelectorAll('rect')[idx].getAttribute(attribute);
}

export function rectsHeightCalculation(i, zeroY, pxPerPt, seriesRects, options, percentValues) {
  const g = seriesRects[i];
  const translateXOrY = options.type === 'column' ? getTranslateY(g) : getTranslateX(g);
  const rects = getSeriesRects(g);
  const isFirstG = options.type === 'column' ? i === seriesRects.length - 1 : i === 0;
  for (let j = 0; j < rects.length; j++) {
    const rect = rects[j];
    let value;
    if (percentValues) {
      value = percentValues[j][i];
    } else {
      value = getValue(rect);
    }
    let colY = Math.abs(zeroY - translateXOrY);
    //gets right value - solves rounding
    const h = pxPerPt * Math.abs(value);
    const isNegative = checkNegative(rect);

    // first g always starts from zero-line
    // regular charts or first stacked group
    if ((isFirstG && options.stacking) || !options.stacking) {
      if ((!isNegative && !options.axes.reversed) || (isNegative && options.axes.reversed))
        colY -= h;
      rect.setAttribute('y', colY);
      rect.setAttribute('height', h);
    } else {
      const checkPrev = options.type === 'column' ? checkPrevColumn : checkPrevBar;
      const prevRect = checkPrev(i, j, seriesRects, isNegative);
      let newY;
      if (prevRect) {
        newY = +prevRect.getAttribute('y') + (isNegative ? +prevRect.getAttribute('height') : -h);
      } else {
        newY = isNegative ? colY : colY - h;
      }
      rect.setAttribute('y', newY);
      rect.setAttribute('height', h);
    }

    if (options.stacking) {
      const { width, height } = rect.getBBox();
      let dashArray = isNegative
        ? `0,${width + height},${width},${height}`
        : `${width},${width + height * 2}`;
      dashArray = `${width},${width + height * 2}`;
      rect.setAttribute('stroke-dasharray', dashArray);
    }
  }
}
//helper funtion recursivly looks for prev positive/negative rect. This needed to get proper y for stacked charts
function checkPrevColumn(i, j, seriesRects, isNegative) {
  if (i > seriesRects.length - 2) return false;
  const prevG = seriesRects[i + 1];
  const prevRect = getSeriesRects(prevG)[j];
  const isPrevRectNegative = checkNegative(prevRect);
  if (isPrevRectNegative) return isNegative ? prevRect : checkPrevColumn(i + 1, j, seriesRects);
  else return isNegative ? checkPrevColumn(i + 1, j, seriesRects) : prevRect;
}

function checkPrevBar(i, j, seriesRects, isNegative) {
  if (i === 0) return false;
  const prevG = seriesRects[i - 1];
  const prevRect = getSeriesRects(prevG)[j];
  const isPrevRectNegative = checkNegative(prevRect);
  if (isPrevRectNegative) return isNegative ? prevRect : checkPrevBar(i - 1, j, seriesRects);
  else return isNegative ? checkPrevBar(i - 1, j, seriesRects) : prevRect;
}

function checkNegative(column) {
  const classes = column.getAttribute('class');
  return classes.includes('highcharts-negative');
}

export function rectsWidthCalculation(seriesRects, xAxis, options, series) {
  //don't calculate distance bw rects for large datasets
  let largeDataSet = false;
  seriesRects.forEach((g) => {
    const rects = g.querySelectorAll('rect');
  });
  if (largeDataSet) return;
  let delta;
  const gLength = seriesRects.length;
  const rectLength = seriesRects[0].querySelectorAll('rect').length;
  const firstX = getSeriesAttribute(seriesRects[0], 0, 'x');
  const width = getSeriesAttribute(seriesRects[0], 0, 'width');
  let adjustedWidth;
  //find last rect x attribute and based on that calculate distance bw rects
  const lastXArr = seriesRects.map((g) => {
    const rects = g.querySelectorAll('rect');
    const last = rects.length - 1;
    return getSeriesAttribute(g, last, 'x');
  });

  const lastRectX = Math.max(...lastXArr) + width;
  if (rectLength * width > lastRectX) {
    adjustedWidth = lastRectX / rectLength;
  }

  let columnGroup = width * gLength;
  //if chart has multiple series calculate space between 'grouped' rects and width of the 'group'.
  if (seriesRects.length > 1) {
    const secondX = getSeriesAttribute(seriesRects[1], 0, 'x');
    delta = secondX - firstX - width;
    delta = delta < 0 && !options.stacking ? 0 : delta;
    columnGroup = width * gLength + delta * (gLength - 1);
  }
  //distance between 'groups'
  const spaceBetween = (lastRectX - firstX - rectLength * columnGroup) / (rectLength - 1);
  //distance between very first rect to the first rect of the next group
  const spaceBetweenRect = spaceBetween + columnGroup;
  //rounding xAxis
  for (let i = 0; i < seriesRects.length; i++) {
    const seriesOptions = series[i];
    const g = seriesRects[i];
    const translateX = getTranslateX(g);
    const rects = g.querySelectorAll('rect');
    const isZeroG = i === 0;
    //position first g properly
    if (isZeroG)
      setFirstRects(
        rects,
        xAxis,
        translateX,
        spaceBetweenRect,
        columnGroup,
        options,
        seriesOptions,
        adjustedWidth
      );
    else {
      const prevRects = seriesRects[i - 1].querySelectorAll('rect');
      //set rect position based on prev and add proper gap
      rects.forEach((rect, i) => {
        const width = +prevRects[i].getAttribute('width');
        const x = +prevRects[i].getAttribute('x') + width + delta;
        rect.setAttribute('x', x);
      });
    }
  }
}

//todo: center align ticks
function setFirstRects(
  rects,
  xAxis,
  translateX,
  spaceBetweenRect,
  columnGroup,
  options,
  seriesOptions,
  adjustedWidth
) {
  const firstX = +rects[0].getAttribute('x');
  let x = firstX;
  let tickX = translateX + firstX + columnGroup / 2;

  //no ticks for categories that are not datetime/bar charts
  if (options.categoriesAreDates && options.type === 'column') {
    const ticksArr = Object.keys(xAxis[0].ticks);
    rects.forEach((rect, idx) => {
      if (ticksArr.includes(seriesOptions.xData[idx])) {
        const tick = xAxis[0].ticks[seriesOptions.xData[idx]];
        const tickEl = tick.mark.element;
        const tickD = tickEl.getAttribute('d');
        let tickDArr = tickD.split(' ');
        tickDArr[1] = tickX;
        tickDArr[4] = tickX;
        const newD = tickDArr.join(' ');
        tickEl.setAttribute('d', newD);
      }
      tickX += spaceBetweenRect;
    });
  }

  rects.forEach((rect, i) => {
    if (i !== 0) {
      x += spaceBetweenRect;
    }
    rect.setAttribute('x', x);
    if (adjustedWidth && options.type === 'column') rect.setAttribute('width', adjustedWidth);
  });
}

export function getPercentValues(seriesItems) {
  const numOfRectsInOneSeries = seriesItems[0].querySelectorAll('rect').length;
  const valuesObj = {};
  const rects = seriesItems.reduce((obj, g, idx) => {
    obj[idx] = getSeriesRects(g);
    return obj;
  }, {});
  for (let i = 0; i < numOfRectsInOneSeries; i++) {
    valuesObj[i] = [];
    for (let j = 0; j < seriesItems.length; j++) {
      const rect = [...rects[j]][i];
      const value = getValue(rect);
      valuesObj[i].push(value);
    }
  }
  for (let value in valuesObj) {
    const valuesArr = valuesObj[value];
    const total = valuesArr.reduce((a, value) => a + value);
    const percentArr = valuesArr.map((value) => (value * 100) / total);
    valuesObj[value] = percentArr;
  }
  return valuesObj;
}

const getLastTick = (ticks, tickPositions) => {
  let maxX = -Infinity;
  let maxPosition = '';
  tickPositions.forEach((position) => {
    const x = +ticks[position].mark.d.split(' ')[1];
    if (x > maxX) {
      maxX = x;
      maxPosition = position;
    }
  });
  return ticks[maxPosition];
};
const getTickX = (d) => +d.split(' ')[1];

function getIntervals(tickPositions, width) {
  const firstIntMs = tickPositions[1] - tickPositions[0];
  const lastIntMs =
    tickPositions[tickPositions.length - 1] - tickPositions[tickPositions.length - 2];
  const fullIntMs = tickPositions[tickPositions.length - 1] - tickPositions[0];

  const msPerTick = width / fullIntMs;
  const firstInterval = msPerTick * firstIntMs;
  const lastInterval = msPerTick * lastIntMs;
  return [firstInterval, lastInterval];
}
// TO DO: revisit this func
export const alignTicks = (tickPositions, ticks, options) => {
  tickPositions.sort((a, b) => a - b);
  const numOfIntervals = tickPositions.length - 1;
  const isIrregularDates = options.irregularDates;

  const [firstTickX, lastTickX] = [
    getTickX(ticks[tickPositions[0]].mark.d),
    getTickX(getLastTick(ticks, tickPositions).mark.d),
  ];

  const width = lastTickX - firstTickX;
  const irrDatesDelta = width / numOfIntervals;

  const [firstInterval, lastInterval] = isIrregularDates
    ? [irrDatesDelta, irrDatesDelta]
    : getIntervals(tickPositions, width);

  const delta = isIrregularDates
    ? irrDatesDelta
    : (width - firstInterval - lastInterval) / (numOfIntervals - 2);

  let currentTickX = firstTickX;
  tickPositions.forEach((tick, i) => {
    const isMinor = options.xAxisTicks[tick].type === 'minor';
    const { mark } = ticks[tick];
    const currentD = mark.d.split(' ');
    if (isMinor) {
      currentD[5] = +currentD[5] - 1;
    }
    if (i === 0 || i === numOfIntervals) {
      if (isMinor) {
        mark.element.setAttribute('d', currentD.join(' '));
      }
      currentTickX += i === 0 ? firstInterval : lastInterval;
    } else {
      currentD[1] = currentTickX;
      currentD[4] = currentTickX;
      mark.element.setAttribute('d', currentD.join(' '));
      currentTickX += delta;
    }
  });
};
