/**
 * The Outer Chart renders headlines, footnotes, and credits.
 */
import spacing from './spacing';
import * as headlines from './headlines';
import * as notes from './notes';
import * as element from '../util/element';

const MARGIN = 12;
const BARRONS_PRINT_MARGIN = 0;

export function preRender(options) {
  // const hasAccessibility = options.headings.altText.text;
  // const  defaultDescription = hasAccessibility ? hasAccessibility : options.headings.subdek.text || options.headings.title.text;
  let data = {
    exporting: {
      enabled: false,
    },
    // accessibility: {
    //   description: defaultDescription
    // },
    chart: {
      renderTo: options.el.querySelector('.dj-chart-container'),
      width: options.width,
      height: options.height,
      reflow: false,
      backgroundColor: 'transparent',
      // do not convert outer chart into styled mode, breaks svg and pngs
      styledMode: false,
    },
    title: { text: null },
    subtitle: { text: null },
    credits: { enabled: false },
    navigator: { enabled: false },
  };
  if (options.size.indexOf('thumb') !== 0) {
    data.chart.spacing = spacing(options);
  }
  if (options.irregularDates) {
    data.scrollbar = { enabled: false };
    data.rangeSelector = { enabled: false };
    data.navigator = { enabled: false };
  }
  return data;
}

export function postRender(highchart, options) {
  if (options.size.indexOf('thumb') === 0) {
    return {
      innerEl: createInnerEl(highchart, options),
      x: 0,
      y: 0,
      width: options.width,
      height: options.height,
      info: null,
    };
  }
  const info = {
    headlines: headlines.postRender(highchart, options),
    notes: notes.postRender(highchart, options),
  };
  let margin;
  if (info.headlines.y <= 0) {
    margin = 0;
  } else if (options.id === 'print-barrons') {
    margin = BARRONS_PRINT_MARGIN;
  } else {
    margin = MARGIN;
  }
  const heightOffset = options.id === 'print-barrons' ? 12 : 0;
  return {
    innerEl: createInnerEl(highchart, options),
    x: highchart.spacing[3],
    y: info.headlines.y + margin,
    width: highchart.chartWidth - highchart.spacing[1] - highchart.spacing[3],
    height: info.notes.y - info.headlines.y - margin + heightOffset,
    spacing: [0, 0, 0, 0],
    info,
  };
}

export function renderInvalidated(rendered, options, invalidated) {
  if (__ALL__) {
    const updateH = headlines.renderInvalidated(rendered, options, invalidated);
    const updateN = notes.renderInvalidated(rendered, options, invalidated);
    let margin = rendered.outerInfo.info && rendered.outerInfo.info.headlines.y > 0 ? MARGIN : 0;
    if (__WSJ__) {
      margin = options.size === 'print-wsj' ? 0 : margin;
    }
    if (updateH) {
      rendered.outerInfo.y += margin;
    }
    if (updateH || updateN) {
      rendered.outerInfo.height = 0;
      if (rendered.outerInfo.info) {
        rendered.outerInfo.height =
          rendered.outerInfo.info.notes.y - rendered.outerInfo.info.headlines.y - margin;
      }
    }
  }
}

// Cleans up the outer SVG, removing unnecessary `g` elements, and creates a
// `g` element specifically for the inner chart SVG.
function createInnerEl(highchart, options) {
  const svg = options.el.querySelector('svg');
  if (svg) {
    const gEls = svg.querySelectorAll('g');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < gEls.length; i++) {
      element.removeChild(gEls[i]);
    }
    return highchart.renderer.g('djchart').add().element;
  }
  return null;
}
