/**
 * Adds a lineHeight property to the label style object.
 * Highcharts doesn't support it by default in styled mode.
 * Wrapper function is required, see setPlotBandLabelLineHeight in src/chart/wrappers/constants.js
 * @param {ChartlosOptions} options
 * @param {*} [arr=[]]
 * @return {[]} an array of plotBands with added lineHeight
 */

function addLineHeight(options, arr = []) {
  return arr.reduce((res, band) => {
    if (!band.label.style) {
      band.label.style = {};
    }
    band.label.style.lineHeight = options.styles.lineHeight;
    res = [...res, band];
    return res;
  }, []);
}
/**
 * Adds a lineHeight property to custom and preset bands
 * @param {ChartlosOptions} options
 * @return {[]} an array of custom and preset plotBands
 */
function applyLineHeight(options) {
  return [options.plotBandPresets, options.plotBands]
    .map((arr) => addLineHeight(options, arr))
    .flat();
}

export { applyLineHeight };
